import * as angular from 'angular';
import * as accessesKeyChainsByProtectedEntityTemplate from './accesses-key-chains-by-protected-entity.html';

angular.module('virtual-try-on-manager')
    .directive('accessesKeyChainsByProtectedEntity', function ($location, AccessesKeyChainResource) {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                tryOnFilterChildResource: '=',
                filteredEntityId: '='
            },
            controller: function ($scope) {
                let tryOnFilterChildResource = $scope.tryOnFilterChildResource;
                let protectedEntity = $scope.filteredEntityId;


                tryOnFilterChildResource.findKeychainsForScenario({scenario: {id: protectedEntity}}).$promise.then(keychains => {
                    $scope.selectedKeychains = keychains;
                    $scope.initialKeychains = [...keychains];
                });
                $scope.accessKeyChains = AccessesKeyChainResource.query();

                let getIndexInSelectedAccessesKeyChains = function (accessesKeyChain) {
                    if (!$scope.selectedKeychains) {
                        return false;
                    }
                    return $scope.selectedKeychains
                        .map(keychain => keychain.id)
                        .indexOf(accessesKeyChain.id);
                };

                $scope.isSelected = function (accessesKeyChain) {
                    return getIndexInSelectedAccessesKeyChains(accessesKeyChain) !== -1;
                };

                $scope.toggleSelect = function (accessesKeyChain) {
                    let index = getIndexInSelectedAccessesKeyChains(accessesKeyChain);
                    if (index === -1) {
                        $scope.selectedKeychains.push(accessesKeyChain);
                    } else {
                        $scope.selectedKeychains.splice(index, 1);
                    }
                };

                $scope.save = function () {
                    let keyChainsToAdd = $scope.selectedKeychains.filter(selectedKeychain => $scope.initialKeychains.map(initialKeychain => initialKeychain.id).indexOf(selectedKeychain.id) === -1);
                    let keyChainsToRemove = $scope.initialKeychains.filter(initialKeychain => $scope.selectedKeychains.map(selectedKeychain => selectedKeychain.id).indexOf(initialKeychain.id) === -1);

                    tryOnFilterChildResource
                        .configureAccessesKeyChains({
                            keyChainsToAdd: keyChainsToAdd,
                            keyChainsToRemove: keyChainsToRemove,
                            scenarios: [{id: protectedEntity}]
                        })
                        .$promise
                        .then(function () {
                            $scope.initialKeychains = [...$scope.selectedKeychains];
                        });
                };
            },
            template: accessesKeyChainsByProtectedEntityTemplate.default
        };
    });
