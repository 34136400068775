import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('BrandCtrl', function ($scope, $routeParams, $location, $window,
                                       BrandResource, NavbarService) {
        let brandId = $routeParams.id;
        $scope.isNew = brandId === 'new';

        if ($scope.isNew) {
            $scope.brand = BrandResource._default();
        } else {
            $scope.brand = BrandResource.get(parseInt(brandId));
        }

        NavbarService.setAppTitleFromObjectName('brand', $scope, 'brand.name');

        $scope.saveBrand = function () {
            BrandResource.save($scope.brand)
                .$promise
                .then(function (brand) {
                    if ($scope.isNew) {
                        $location.path('/admin/brand/' + brand.id);
                    } else {
                        $scope.brand = brand;
                    }
                });
        };

    });
