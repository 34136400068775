import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('BrandListCtrl', function ($scope, BrandResource) {
        $scope.sortInfo = {fields: ['name'], columns: [], directions: ['asc']};
        $scope.columns = [
            {field: 'name', labelCode: 'name', linkBaseHref: '#/admin/brand'},
            {field: 'ediCode', labelCode: 'edi.code'}
        ];
        $scope.findPage = function () {
            return BrandResource.findPage.apply(BrandResource, arguments);
        };
    });
