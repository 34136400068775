import {CosGridServerPageable} from "./cos-grid-server-pageable";

export type CosGridSort = {
    fields: string[],
    directions: string[]
}

export class CosGridSortBuilder {
    static fromPageable(pageable: CosGridServerPageable): CosGridSort {
        if (!pageable || !pageable.sort || !pageable.sort.orders) {
            return {
                directions: [],
                fields: []
            };
        }

        return {
            directions: pageable.sort.orders.map(sort => sort.direction),
            fields: pageable.sort.orders.map(sort => sort.property)
        };
    }
}
