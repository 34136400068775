import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ExportScenarioListCtrl', function ($scope, ExportScenarioResource) {
        $scope.sortInfo = {fields: ['name'], columns: [], directions: ['asc']};
        $scope.findPage = function () {
            return ExportScenarioResource.findPage.apply(ExportScenarioResource, arguments);
        };

        $scope.columns = [
            {field: 'name', labelCode: 'name'},
            {field: 'filename', labelCode: 'filename'},
            {field: 'activateFiles360', labelCode: 'export.scenario.files.360.short', cellTemplate: '<div class="text-center" style="margin-top: 9px">' +
            '<input tabindex="-1" type="checkbox" class="ngSelectionCheckbox" ng-disabled="true" ng-model="row.entity.activateFiles360">' +
            '</div>'},
            {field: 'activateFilesFacePrint', labelCode: 'export.scenario.files.print.face.short', cellTemplate: '<div class="text-center" style="margin-top: 9px">' +
            '<input tabindex="-1" type="checkbox" class="ngSelectionCheckbox" ng-disabled="true" ng-model="row.entity.activateFilesFacePrint">' +
            '</div>'},
            {field: 'activateFiles34Print', labelCode: 'export.scenario.files.print.34.short', cellTemplate: '<div class="text-center" style="margin-top: 9px">' +
            '<input tabindex="-1" type="checkbox" class="ngSelectionCheckbox" ng-disabled="true" ng-model="row.entity.activateFiles34Print">' +
            '</div>'},
            {field: 'activateFilesColorModeFace', labelCode: 'export.scenario.files.color.mode.face.short', cellTemplate: '<div class="text-center" style="margin-top: 9px">' +
            '<input tabindex="-1" type="checkbox" class="ngSelectionCheckbox" ng-disabled="true" ng-model="row.entity.activateFilesColorModeFace">' +
            '</div>'},
            {field: 'activateFilesFptqFace', labelCode: 'export.scenario.files.fptq.face.short', cellTemplate: '<div class="text-center" style="margin-top: 9px">' +
            '<input tabindex="-1" type="checkbox" class="ngSelectionCheckbox" ng-disabled="true" ng-model="row.entity.activateFilesFptqThreeQuarter">' +
            '</div>'},
            {field: 'activateFilesFptqClipFace', labelCode: 'export.scenario.files.fptq.clip.face.short', cellTemplate: '<div class="text-center" style="margin-top: 9px">' +
                    '<input tabindex="-1" type="checkbox" class="ngSelectionCheckbox" ng-disabled="true" ng-model="row.entity.activateFilesFptqClipThreeQuarter">' +
                    '</div>'}
        ];
    });
