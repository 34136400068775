import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('AccessesKeyChainListCtrl', function ($scope, AccessesKeyChainResource) {
        $scope.accessKeyChains = AccessesKeyChainResource.query();
        $scope.columns = [{
            name: 'name', labelCode: 'name', link: function (globalFilter) {
                return '/admin/accesses-key-chain/' + globalFilter.id;
            }
        }];
    });
