import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ValidationCtrl', function ($scope, $location, $translate, NavbarService) {
        NavbarService.setAppTitle($scope, $translate('job.waiting.validation'));

        $scope.onJobSelect = function (job) {
            $location.path('/validation/job/' + job.id);
        };
    });
