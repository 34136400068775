import * as angular from "angular";
import * as loggerFactory from "loglevel";
import {mainModule} from "../../app";

function HttpInterceptorConfigurator($provide, $httpProvider) {
    $provide.factory('CosHttpInterceptor', ['$q', '$location', '$rootScope', '$injector', 'HttpConfig', 'AuthenticationConfig',
        function ($q, $location, $rootScope, $injector, HttpConfig, AuthenticationConfig) {

            const log = loggerFactory.getLogger('CosHttpInterceptor');

            let isCrossDomain = function (config) {
                try {
                    /* Because IE 11 ... */
                    const targetUrl = new URL(new URL(config.url).href);
                    const localUrl = new URL(location.href);
                    return targetUrl.port !== localUrl.port || targetUrl.hostname !== localUrl.hostname;
                } catch (err) {
                    return false;
                }
            };

            return {
                'request': function (config) {
                    if (isCrossDomain(config) === false) {
                        config.headers['X-ctr-type'] = 'ajax';
                        config.headers['COS-LANG'] = $injector.get('TranslationService').getLanguage();
                    }

                    config.custom = config.custom || {};
                    if (!angular.isDefined(config.custom.displayModalOnError)) {
                        config.custom.displayModalOnError = isCrossDomain(config) !== true;
                    }

                    return config;
                },

                'response': function (response) {
                    if (response.headers('authentication-ok') === 'true') {
                        log.debug('Firing authentication success event');
                        $rootScope.$emit(AuthenticationConfig.AUTHENTICATION_SUCCESS);
                    }
                    return response;
                },

                'responseError': function (rejection) {
                    if (rejection.status === 401) {
                        $rootScope.$emit(HttpConfig.HTTP_UNAUTHORIZED_EVENT, rejection);
                    } else {
                        $rootScope.$emit(HttpConfig.HTTP_ERROR_EVENT, rejection);
                    }
                    return $q.reject(rejection);
                }
            };
        }]);
    $httpProvider.interceptors.push('CosHttpInterceptor');
}

HttpInterceptorConfigurator.$inject = ['$provide', '$httpProvider'];

export default HttpInterceptorConfigurator;
mainModule.config(HttpInterceptorConfigurator);
