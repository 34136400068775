import * as angular from "angular";
import {mainModule} from "../../app";
/**
 * Transform an array of enums or an enum into an array of enum values or a value
 */
function enumToStringFilter() {
    return function (enumObject) {
        if (!enumObject || enumObject === null || angular.isString(enumObject)) {
            return enumObject;
        }
        if (angular.isArray(enumObject)) {
            var valueArray = [];
            angular.forEach(enumObject, function (enumSingleObj) {
                valueArray.push(enumSingleObj.value);
            });
            return valueArray;
        } else {
            return enumObject.value;
        }
    };
}

mainModule.filter('enumToString', enumToStringFilter);
