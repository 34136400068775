import * as angular from "angular";
import * as loggerFactory from "loglevel";
import {mainModule} from "../../app";

function HttpErrorHandler($rootScope, $q, DialogService, $translate, InterpolableMessageService, HttpConfig) {

    const log = loggerFactory.getLogger('HttpErrorHandler');

    /**
     * Displays an error modal containing main user message and secondary messages if appropriate
     * @param errorResource The error resource probably provided by the server
     */
    const displayErrorModal = function (errorResource) {
        if (!errorResource || errorResource === null) {
            return;
        }

        const mainUserMessage = errorResource.mainUserMessage;
        const userMessages = errorResource.userMessages;
        const deferred = $q.defer();
        if (mainUserMessage) {
            InterpolableMessageService.translate(mainUserMessage)
                .then(function (translated) {
                    deferred.resolve(translated);
                });
        } else {
            deferred.resolve(null);
        }

        deferred
            .promise
            .then(function (translatedMainMessage) {
                const promises = [];
                angular.forEach(userMessages, function (userMessage) {
                    promises.push(InterpolableMessageService.translate(userMessage));
                });
                return $q.all(promises)
                    .then(function (translatedMessages) {
                        const htmlMsg = [];
                        angular.forEach(translatedMessages, function (translatedMsg) {
                            htmlMsg.push('<li>' + translatedMsg + '</li>');
                        });
                        return {
                            translatedMainMessage: translatedMainMessage,
                            translatedMessages: htmlMsg
                        };
                    });
            })
            .then(function (payload) {
                if (!payload.translatedMainMessage && payload.translatedMessages.length === 0) {
                    let msgCode = errorResource.msgCode;
                    if (!msgCode || msgCode === '') {
                        msgCode = 'errorpage.erreurcontacteradmin';
                    }
                    $translate(msgCode).then(function (msg) {
                        DialogService.error(msg);
                    });
                } else if (payload.translatedMainMessage && payload.translatedMessages.length > 0) {
                    DialogService.error('<ul>' + payload.translatedMessages.join('') + '</ul>', payload.translatedMainMessage);
                } else if (!payload.translatedMainMessage && payload.translatedMessages.length > 0) {
                    DialogService.error('<ul>' + payload.translatedMessages.join('') + '</ul>');
                } else {
                    DialogService.error(payload.translatedMainMessage);
                }
            });

    };

    $rootScope.$on(HttpConfig.HTTP_ERROR_EVENT, function (evt, rejection) {
        if (rejection.config && rejection.config.custom.displayModalOnError === true) {
            const failure = {
                url: rejection.config.url,
                method: rejection.config.method,
                status: rejection.status
            };
            log.error('Http request failed with ' + JSON.stringify(failure));
            displayErrorModal(rejection.data);
        }
    });

}

HttpErrorHandler.$inject = ['$rootScope', '$q', 'DialogService', '$translate', 'InterpolableMessageService', 'HttpConfig'];

export default HttpErrorHandler;
mainModule.service('HttpErrorHandler', HttpErrorHandler);
