import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('CustomerGroupCtrl', function($scope, $routeParams, $location, $window, $translate,
                                              CustomerGroupResource, AccessesKeyChainResource, NavbarService){
        $scope.isNew = $routeParams.id === 'new';

        $scope.accessKeyChains = AccessesKeyChainResource.query();

        if($scope.isNew){
            $scope.customerGroup = CustomerGroupResource._default();
        } else{
            $scope.customerGroup = CustomerGroupResource.get(parseInt($routeParams.id));
        }

        NavbarService.setAppTitleFromObjectName('customers.group', $scope, 'customerGroup.name');

        $scope.save = function(){
            $scope.customerGroup = CustomerGroupResource.save($scope.customerGroup);
        };

    });
