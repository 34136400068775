import * as angular from 'angular';
import {ILocationService, IPromise, IQService, IScope} from 'angular';
import {UserResource} from "../../../http/target/classes/openapi/code/api/UserResource";
import {mainModule} from "../app";

export class UserContextService {
    constructor(
        private readonly $rootScope: IScope,
        private readonly $location: ILocationService,
        private readonly $translate,
        private readonly $log,
        private readonly $route,
        private readonly AuthenticationManager,
        private readonly $q: IQService,
        private readonly UserResource: UserResource) {}

    private authToken: string;
    private protectedUserPromise: IPromise<any>;

    getAuthToken(): string {
        return this.$rootScope.authToken;
    };

    clearCache(): void {
        this.protectedUserPromise = null;
    };

    getContext() : IPromise<UserContext> {
        if (!this.protectedUserPromise) {
            this.protectedUserPromise = this.UserResource.getCurrentUser({}).$promise;
        }
        return this.protectedUserPromise.then(protectedUser => new UserContext(angular.copy(protectedUser)));
    };

    logout(): void {
        this.AuthenticationManager.deauthenticate();
        this.authToken = null;
        this.protectedUserPromise = null;
        this.$location.path('/login');
    }
}

export class UserContext {
    user?;

    constructor(user) {
        this.user = user;
    }

    hasRole(role: string): boolean {
        if (!this.user || !this.user.roles) {
            return false;
        }

        return this.user.roles.some(function (userRole) {
            return userRole.value.value === role;
        });
    }
}

mainModule.service('UserContextService', UserContextService);
