export type CosGridServerPageable = {
    // Starts at 0
    pageNumber: number,
    pageSize: number,
    sort: CosGridServerSort
}


export type CosGridServerSort = {
    orders: CosGridServerOrder[]
}

export type CosGridServerOrder = {
    property: string,
    direction: CosGridServerDirection
}

export enum CosGridServerDirection {
    asc = 'asc',
    desc = 'desc'
}
