import * as angular from 'angular';
import * as simpleListLayoutTemplate from './simple-list-layout.html';
import {UrlContextService} from "../navigation/url-context-service";

angular.module('virtual-try-on-manager')
    .directive('simpleListLayout', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                titleLabelCode: '=',
                newEntityLabelCode: '=',
                entities: '=',
                columns: '=',
                disableSearch: '='
            },
            controller: function ($scope, $location, $translate, NavbarService, UrlContextService: UrlContextService) {
                if ($scope.titleLabelCode) {
                    NavbarService.setAppTitle($scope, $translate($scope.titleLabelCode));
                }

                if (!$scope.disableSearch) {
                    const ctx = UrlContextService.bind($scope, {appFilter: ''}, 'simpleList');
                    NavbarService.setAppFilter(ctx.appFilter);
                    NavbarService.watchAppFilter($scope, function (appFilter) {
                        $scope.searchText = appFilter;
                        ctx.appFilter = appFilter;
                    });
                }
                $scope.currentPath = $location.path();

                $scope.getPropertyValue = function (object, property) {
                    if (!object || object === null || !property) {
                        return;
                    }
                    property = property.replace(/\[(\w+)]/g, '.$1'); // convert indexes to properties
                    property = property.replace(/^\./, '');           // strip a leading dot
                    let propertyArray = property.split('.');
                    while (propertyArray.length) {
                        let n = propertyArray.shift();
                        if (object && object !== null && n in object) {
                            object = object[n];
                        } else {
                            return;
                        }
                    }
                    return object;
                };
            },
            template: simpleListLayoutTemplate.default
        };
    });
