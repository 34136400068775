import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('JobValidatorCtrl', function ($scope, $routeParams, $location, $window, $filter,
                                              NavbarService,
                                              JobResource, CustomerResource, JobValidatorResource) {
        NavbarService.setAppTitleFromObjectName('job.validator', $scope);
        $scope.jobValidator = {id: 0, customer: {}};
        $scope.customerJobs = [];
        $scope.params = {
            jobs: [],
            gtinListToChecked: ''
        };
        $scope.canReturnToCustomer = false;

        let computeCanReturnToCustomer = function () {
            $scope.canReturnToCustomer = $scope.jobValidator.id &&
                $scope.jobValidator.id !== 0;
            $scope.displayReturnToCustomer = $scope.params.jobs.length === 1 &&
                !$scope.params.jobs[0].returnToCustomer;
        };

        let loadJobValidator = function (jobValidator) {
            $scope.jobValidator = jobValidator;

            $scope.jobValidator.displayDate = $filter('date')(jobValidator.date, 'yyyy/MM/dd HH:mm:ss');
            let jobsId = [];
            $scope.params.jobs.length = 0;
            angular.forEach($scope.jobValidator.jobValidatorResults, function (item) {
                if (item.job && jobsId.indexOf(item.job.id) < 0) {
                    jobsId.push(item.job.id);
                    $scope.params.jobs.push({
                            id: item.job.id,
                            name: item.job.name,
                            returnToCustomer: item.job.returnToCustomer
                        }
                    );
                }
            });
            $scope.jobValidator.jobValidatorResults = $filter('orderBy')($scope.jobValidator.jobValidatorResults,
                ['job.name', 'askedGtin', 'foundGtin']);

            computeCanReturnToCustomer();
        };

        if ($routeParams.id === 'new') {
            $scope.customers = [];

            $scope.canSearch = true;
            $scope.$watch('jobValidator.customer', function (newValue, oldValue) {
                if (angular.isUndefined(newValue) || (oldValue && newValue.id === oldValue.id ) || !$scope.jobValidator.customer) {
                    return;
                }
                let jobQuery = {
                    customer: $scope.jobValidator.customer
                };
                JobResource.findAll({query: jobQuery})
                    .$promise
                    .then(function (results) {
                        $scope.params.jobs.length = 0;
                        $scope.customerJobs = results;
                    });
            }, true);

            CustomerResource.findAll({query: {}})
                .$promise
                .then(function (results) {
                    $scope.customers = results;
                    if ($scope.customers.length > 0) {
                        $scope.jobValidator.customer = $scope.customers[0];
                    }
                });

            $scope.checkGtinsExistInJobs = function () {
                if ($scope.params.jobs.length < 1) {
                    return;
                }
                let customer = $scope.jobValidator.customer;
                JobValidatorResource.checkGtinsExistInJobs({
                    gtins: $scope.params.gtinListToChecked.split('\n'),
                    jobs: $scope.params.jobs
                })
                    .$promise
                    .then(function (result) {
                        result.customer = customer;
                        $scope.jobValidator = result;
                        $scope.jobValidator.displayDate = $filter('date')(result.date, 'yyyy/MM/dd HH:mm:ss');
                    });
            };
        } else {
            $scope.canSearch = false;
            JobValidatorResource.findById({
                identifier: $routeParams.id,
                entityGraphName: 'JobValidator.full'
            })
                .$promise
                .then(function (result) {
                    loadJobValidator(result);
                });
        }

        $scope.columns = [
            {name: 'askedGtin', labelCode: 'asked.gtin'},
            {name: 'foundGtin', labelCode: 'product.gtin'},
            {name: 'job.name', labelCode: 'job'}
        ];

        $scope.saveJobValidator = function () {
            JobValidatorResource.save($scope.jobValidator)
                .$promise
                .then(function (jobValidator) {
                    $location.path('/admin/job/validator/' + jobValidator.id);
                });
        };
        $scope.returnJobToCustomer = function () {
            JobValidatorResource.returnJobToCustomer({jobValidator: $scope.jobValidator})
                .$promise
                .then(function (result) {
                    loadJobValidator(result);
                });
        };
    });
