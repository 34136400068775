import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('BoothListCtrl', function($scope, BoothResource){
        $scope.sortInfo = {fields: ['setting.name'], columns: [], directions: ['asc']};
        $scope.columns = [
            {field: 'setting.name', labelCode: 'setting.booths', linkBaseHref: '#/booth/booth'},
            {field: 'externalId.value', labelCode: 'externalId'},
            {field: 'storeName', labelCode: 'store.name'},
            {field: 'address', labelCode: 'address'},
            {field: 'businessRegistrationNumber', labelCode: 'store.businessRegistrationNumber'},
            {field: 'storePhoneNumber', labelCode: 'store.phonenumber'}
        ];
        $scope.findPage = function () {
            return BoothResource.findPage.apply(BoothResource, arguments);
        };
        $scope.exportToCsv = function () {
            return BoothResource.toCsv.apply(BoothResource, arguments);
        };
    });
