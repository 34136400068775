import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ScenarioAccessesBulkConfigurationCtrl', function ($scope, $modalInstance, $translate, toastr,
                                                                      AccessesKeyChainResource, ScenarioAccessResource, scenarios) {
        $scope.titleParam = {productCount: scenarios.length};
        $scope.keyChainModifications = [];

        AccessesKeyChainResource.query()
            .$promise
            .then(function (keyChains) {
                keyChains.forEach(function (keyChain) {
                    $scope.keyChainModifications.push({
                        keyChain: keyChain,
                        action: null
                    })
                });
            });

        $scope.canValidate = function () {
            return $scope.keyChainModifications.some(function (filterModification) {
                return filterModification.action !== null;
            });
        };

        $scope.validate = function () {
            let keyChainsToAdd = [];
            let keyChainsToRemove = [];
            $scope.keyChainModifications.forEach(function (filterModification) {
                if (filterModification.action === 'CHECK') {
                    keyChainsToAdd.push(filterModification.keyChain);
                } else if (filterModification.action === 'UNCHECK') {
                    keyChainsToRemove.push(filterModification.keyChain);
                }
            });

            ScenarioAccessResource
                .configureAccessesKeyChains({
                    keyChainsToAdd: keyChainsToAdd,
                    keyChainsToRemove: keyChainsToRemove,
                    scenarios: scenarios
                })
                .$promise
                .then(function () {
                    $translate('general.save.success')
                        .then(function(message){
                            toastr.success(message);
                        });
                    $modalInstance.close();
                });
        };

        $scope.cancel = function () {
            $modalInstance.close();
        };
    });
