import * as angular from 'angular';
import {IComponentOptions, IController, IPromise} from 'angular';
import * as template from './add-all-products-to-accesses-key-chain.html';
import {AccessesKeyChainsResource} from "./accesses-key-chains-resource";

class Controller implements IController {

    constructor(private readonly $translate,
                private readonly DialogService,
                private readonly AccessesKeyChainsResource: AccessesKeyChainsResource,
                private accessesKeyChainId) {
        this.accessesKeyChainId = accessesKeyChainId;
    }

    private firstConfirmation(): IPromise<any> {
        return this.$translate('confirm.add.all.products.to.keychain')
            .then(message => this.DialogService.confirm(message).result);
    };

    private secondConfirmation(): IPromise<any> {
        return this.$translate('confirm.add.all.products.to.keychain2')
            .then(message => this.DialogService.confirm(message).result);
    };

    private doIt(): void {
        this.DialogService.showPleaseWait();
        this.AccessesKeyChainsResource.addAllProducts(this.accessesKeyChainId)
            .then(() => this.$translate('accesses.keychain.add.all.products.done'))
            .then(message => {
                    this.DialogService.hidePleaseWait();
                    this.DialogService.alert(message);
                }
            );
    };

    public perform(): void {
        this.firstConfirmation()
            .then(() => this.secondConfirmation())
            .then(() => this.doIt());
    };

    public $onInit(): void {
    }
}

const addAllProductsToAccessesKeyChainComponent: IComponentOptions = {
    bindings: {
        accessesKeyChainId: '='
    },
    controller: Controller,
    template: template.default
};

angular.module('virtual-try-on-manager').component('addAllProductsToAccessesKeyChain', addAllProductsToAccessesKeyChainComponent);
