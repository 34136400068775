import * as angular from 'angular';
import {IComponentOptions, IController, IPromise} from 'angular';
import * as template from "./customer-stats-top-filter.component.html";
import {mainModule} from "../app";
import {CustomerResource} from "../../../http/target/classes/openapi/code/api/CustomerResource";
import {UserContextService} from "../user/user-context-service";


class Controller implements IController {
    isInternalUser: boolean = false;

    dateSelectionMode: DateSelectionMode = DateSelectionMode.DAY;

    minDate: Date;
    maxDate: Date;

    selectedMonth: Date;
    selectedMinDay: Date;
    selectedMaxDay: Date;

    customer;
    customers = [];

    onChange: (TopFilterChange) => void;

    initPromise: IPromise<void>;

    constructor(
        private readonly UserContextService: UserContextService,
        private readonly CustomerResource: CustomerResource) {
    }


    $onInit(): void {
        this.customers = this.CustomerResource.findAll({query: {}});
        this.initPromise = this.UserContextService.getContext().then(context => {
            this.customer = context.user.customer;
            this.isInternalUser = this.customer == null;
        });
    }

    setValidRange(min: Date, max: Date) {
        this.minDate = min;
        this.maxDate = max;
        this.selectedMonth = max;
        this.selectedMinDay = min;
        this.selectedMaxDay = max;
        this.onFilterChange();
    }

    setDateSelectionMode(mode: DateSelectionMode): void {
        this.dateSelectionMode = mode;
        this.onFilterChange();
    }

    isNextDayAvailable() {
        return this.maxDate > this.selectedMaxDay;
    }

    nextDay() {
        if (this.isNextDayAvailable()) {
            let next = new Date(this.selectedMaxDay);
            next.setDate(this.selectedMaxDay.getDate() + 1);
            this.selectedMaxDay = next;
            this.onFilterChange();
        }
    }

    isPreviousDayAvailable() {
        return this.minDate < this.selectedMaxDay;
    }

    previousDay() {
        if (this.isPreviousDayAvailable()) {
            let previous = new Date(this.selectedMaxDay);
            previous.setDate(this.selectedMaxDay.getDate() - 1);
            this.selectedMaxDay = previous;
            this.onFilterChange();
        }
    }

    isNextMonthAvailable() {
        return this.maxDate && this.selectedMonth
            && this.maxDate.getFullYear() > this.selectedMonth.getFullYear()
            && this.maxDate.getMonth() > this.selectedMonth.getMonth();
    }

    nextMonth() {
        if (this.isNextMonthAvailable()) {
            let next = new Date(this.selectedMonth);
            next.setMonth(this.selectedMonth.getMonth() + 1);
            this.selectedMonth = next;
            this.onFilterChange();
        }
    }

    isPreviousMonthAvailable() {
        return this.minDate && this.selectedMonth
            && this.minDate.getFullYear() < this.selectedMonth.getFullYear()
            && this.minDate.getMonth() < this.selectedMonth.getMonth();
    }

    previousMonth() {
        if (this.isPreviousMonthAvailable()) {
            let previous = new Date(this.selectedMonth);
            previous.setMonth(this.selectedMonth.getMonth() - 1);
            this.selectedMonth = previous;
            this.onFilterChange();
        }
    }

    onFilterChange(): void {
        if (!this.initPromise) {
            return;
        }
        this.initPromise.then(() => {
            // Correct invalid min/max period
            if (this.selectedMinDay > this.selectedMaxDay) {
                this.selectedMaxDay = this.selectedMinDay;
            }
            if (this.selectedMinDay > this.selectedMaxDay) {
                this.selectedMinDay = this.selectedMaxDay;
            }

            let start: Date, end: Date;
            if (this.dateSelectionMode == DateSelectionMode.DAY) {
                end = this.selectedMaxDay;
            } else if (this.dateSelectionMode == DateSelectionMode.MONTH) {
                this.selectedMonth = this.selectedMonth || this.maxDate;
                start = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 0);
                if (start < this.minDate) {
                    start = this.minDate;
                }
                end = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth() + 1, 0);
                if (end > this.maxDate) {
                    end = this.maxDate;
                }
            } else {
                end = this.selectedMaxDay;
                start = this.selectedMinDay;
            }

            if (this.onChange) {
                this.onChange({
                    filter: {
                        customerId: this.customer ? this.customer.id : null,
                        start: start,
                        end: end
                    }
                });
            }
        })
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        this.setValidRange(this.minDate, this.maxDate);
    }
}

enum DateSelectionMode {
    MONTH = 'MONTH',
    DAY = 'DAY',
    PERIOD = 'PERIOD'
}

export type CustomerStatFilter = {
    customerId: number,
    start: Date,
    end: Date
}

mainModule.component('customerStatsTopFilter', {
    controller: Controller,
    template: template.default,
    bindings: {
        minDate: '<',
        maxDate: '<',
        onChange: '&'
    }
});
