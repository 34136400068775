import {mainModule} from "../../app";

function LocationProviderConfigurator($locationProvider) {
    $locationProvider.hashPrefix('');
}

LocationProviderConfigurator.$inject = ['$locationProvider'];

export default LocationProviderConfigurator;
mainModule.config(LocationProviderConfigurator);
