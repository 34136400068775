import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('BoothSettingBaseCtrl', function ($scope, $routeParams, $location,
                                            BoothSettingResource, CustomerResource, UserContextService, NavbarService) {

        $scope.isNew = $routeParams.id === 'new';

        let init = async () => {
            const userContext = await UserContextService.getContext();
            $scope.isAdmin = userContext.hasRole('ADMIN');
            $scope.canUpdate = $scope.isAdmin || userContext.hasRole('BOOTHSETTINGATOR');
            if($scope.isAdmin){
                $scope.customers = CustomerResource.findAll({query:{}});
            }
        };
        init();

        if ($scope.isNew) {
            $scope.boothSetting = BoothSettingResource._default();
        } else {
            $scope.boothSetting = BoothSettingResource.get(parseInt($routeParams.id));
        }

        NavbarService.setAppTitleFromObjectName('setting.booths', $scope, 'boothSetting.name');

        $scope.save = function () {
            BoothSettingResource.save($scope.boothSetting)
                .$promise
                .then(function (boothSetting) {
                    if ($scope.isNew) {
                        $location.path('/admin/booth-setting/' + boothSetting.id);
                    } else {
                        $scope.boothSetting = boothSetting;
                    }
                });
        };

    });
