import * as angular from "angular";
import {mainModule} from "../../app";
/**
 * Transform an array of enums or an enum into an array of enum label codes or a label code
 */
function enumToLabelCodeFilter() {
    return function (enumObject) {
        if (!enumObject || enumObject === null || angular.isString(enumObject)) {
            return enumObject;
        }
        if (angular.isArray(enumObject)) {
            var labelCodeArray = [];
            angular.forEach(enumObject, function (enumSingleObj) {
                labelCodeArray.push(enumSingleObj.labelCode);
            });
            return labelCodeArray;
        } else {
            return enumObject.labelCode;
        }
    };
}

mainModule.filter('enumToLabelCode', enumToLabelCodeFilter);
