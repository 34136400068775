import * as angular from 'angular';
import * as customerLayoutTemplate from './customer-layout.html';

angular.module('virtual-try-on-manager')
    .directive('customerLayout', function ($routeParams, UserContextService) {
        return {
            restrict: 'E',
            replace: false,
            transclude: true,
            scope: {
                subLocation: '='
            },
            controller: function ($scope) {
                $scope.isNew = $routeParams.id === 'new';
                $scope.basePath = '#/admin/customer/' + $routeParams.id;
                UserContextService.getContext().then(userContext => $scope.isAdmin = userContext.hasRole('ADMIN'));
            },
            template: customerLayoutTemplate.default
        };
    });
