import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ShotCtrl', function ($scope, $routeParams, $http) {
        $scope.externalId = $routeParams.externalId;
        $scope.sent = false;
        $scope.locked = false;

        $scope.sendByEmail = function () {
            $scope.locked = true;
            $http.post('api/public/end-user/shot/send-by-email', {
                externalId: $scope.externalId,
                email: $scope.email
            })
                .then(
                    function () {
                        $scope.sent = true;
                    },
                    function () {
                        $scope.locked = false;
                    }
                );
        };
    });
