import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('AccessesKeyChainCtrl', function ($scope, $routeParams, $location, $window, $translate, NavbarService, AccessesKeyChainResource) {
        $scope.isNew = $routeParams.id === 'new';

        if ($scope.isNew) {
            $scope.globalFilter = AccessesKeyChainResource._default();
        } else {
            $scope.globalFilter = AccessesKeyChainResource.get(parseInt($routeParams.id));
        }

        NavbarService.setAppTitleFromObjectName('accesses.keychain', $scope, 'globalFilter.name');

        $scope.save = function () {
            AccessesKeyChainResource.save($scope.globalFilter)
                .$promise.then(function (globalFilter) {
                if ($scope.isNew) {
                    $location.path('/admin/accesses-key-chain/' + globalFilter.id);
                } else {
                    $scope.globalFilter = globalFilter;
                }
            });
        };

    });
