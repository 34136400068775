import * as api from './api/api';
import * as angular from 'angular';

const apiModule = angular.module('http', [])
.service('AccessesKeyChainResource', api.AccessesKeyChainResource)
.service('ApplicationResource', api.ApplicationResource)
.service('BoothResource', api.BoothResource)
.service('BoothSettingResource', api.BoothSettingResource)
.service('BoothSettingExcludedProductResource', api.BoothSettingExcludedProductResource)
.service('BoothSettingProductResource', api.BoothSettingProductResource)
.service('BrandResource', api.BrandResource)
.service('CustomerResource', api.CustomerResource)
.service('CustomerBillingResource', api.CustomerBillingResource)
.service('CustomerGroupResource', api.CustomerGroupResource)
.service('CustomerProductImportModeResource', api.CustomerProductImportModeResource)
.service('CustomerProductShootingModeResource', api.CustomerProductShootingModeResource)
.service('DefaultResource', api.DefaultResource)
.service('ExportScenarioResource', api.ExportScenarioResource)
.service('JobResource', api.JobResource)
.service('JobCompletionStatResource', api.JobCompletionStatResource)
.service('JobDefaultShootingModeResource', api.JobDefaultShootingModeResource)
.service('JobFileResource', api.JobFileResource)
.service('JobValidatorResource', api.JobValidatorResource)
.service('KeychainSharingStatResource', api.KeychainSharingStatResource)
.service('ProcessHistoryResource', api.ProcessHistoryResource)
.service('ProcessHistoryUnitResource', api.ProcessHistoryUnitResource)
.service('ProcessesResource', api.ProcessesResource)
.service('ProductResource', api.ProductResource)
.service('ProductColorDominanceResource', api.ProductColorDominanceResource)
.service('ProductColorLabDeltaResource', api.ProductColorLabDeltaResource)
.service('ProductFrameShapeResource', api.ProductFrameShapeResource)
.service('ProductShootingCommentResource', api.ProductShootingCommentResource)
.service('ProductStatusStatResource', api.ProductStatusStatResource)
.service('RegisteredDomainResource', api.RegisteredDomainResource)
.service('ScenarioResource', api.ScenarioResource)
.service('ScenarioAccessResource', api.ScenarioAccessResource)
.service('ScenarioStatusHistoryResource', api.ScenarioStatusHistoryResource)
.service('ScenarioValidationResource', api.ScenarioValidationResource)
.service('SunGlassResource', api.SunGlassResource)
.service('SupplierResource', api.SupplierResource)
.service('TryOnBrowserStatResource', api.TryOnBrowserStatResource)
.service('TryOnDeviceStatResource', api.TryOnDeviceStatResource)
.service('TryOnLibVersionStatResource', api.TryOnLibVersionStatResource)
.service('TryOnSessionStatResource', api.TryOnSessionStatResource)
.service('UniversalProductResource', api.UniversalProductResource)
.service('UserResource', api.UserResource)
.service('UserPasswordResource', api.UserPasswordResource)
.service('UserRoleResource', api.UserRoleResource)
.service('UserValidationStatResource', api.UserValidationStatResource)

export default apiModule;
