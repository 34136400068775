import {CosGridServerPageable} from "../layout/cos-grid-server-pageable";

export class ApiPageableHelper {
    public static addApiPageable(pageable: CosGridServerPageable, target) {
        if (target == null || pageable == null) {
            return;
        }
        target.pageNumber = pageable.pageNumber || 0;
        target.pageSize = pageable.pageSize;
        if (pageable.sort && pageable.sort.orders) {
            target.sortProperties = pageable.sort.orders.map(order => order.property);
            target.sortDirections = pageable.sort.orders.map(order => order.direction);
        }
    }
}
