import * as angular from "angular";
import {CsvGridConfiguration} from "./csv/csv-grid-configuration";
import {CsvColumn} from "./csv/csv-column";
import * as loggerFactory from "loglevel";
import {CsvGridOptions} from "./csv/csv-grid-options";
import {mainModule} from "../../app";

class CosGridService {

    private readonly log: Log;
    // AngularJS constructor injection definition
    static $inject: ReadonlyArray<string> = ['$log'];

    constructor() {
        this.log = loggerFactory.getLogger('CosGridService');
    }

    /**
     * Convert the grid sort object to a Spring sort object
     * @return {*}
     */
    public buildServerSort(gridOptions) {
        if (gridOptions.sortInfo.fields.length === 0) {
            return null;
        }

        let sort = {
            orders: []
        };
        for (let i in gridOptions.sortInfo.fields) {
            let property = null;
            if (gridOptions.sortInfo.columns && gridOptions.sortInfo.columns[i]) {
                property = gridOptions.sortInfo.columns[i].colDef.sortField;
            }

            if (property == null) {
                property = gridOptions.sortInfo.fields[i].replace(/\[\d+\]/g, '');
            }

            sort.orders.push({
                property: property,
                direction: gridOptions.sortInfo.directions[i]
            });
        }

        return sort;
    };

    /**
     * Convert the grid paging object to a Spring pageable object
     * @param gridOptions
     * @return {{pageNumber: number, pageSize: (*|number)}}
     */
    public buildServerPageable(gridOptions) {
        let pageable = {
            pageNumber: Number(gridOptions.pagingOptions.currentPage) - 1,
            pageSize: Number(gridOptions.pagingOptions.pageSize),
            sort: undefined
        };

        let sort = this.buildServerSort(gridOptions);
        if (sort !== null) {
            pageable.sort = sort;
        }

        return pageable;
    };

    /**
     * Build a CSV grid configuration
     * @param gridOptions
     * @return {{columns: Array}}
     */
    public buildCsvConfiguration(gridOptions: any & CsvGridOptions): CsvGridConfiguration {
        const csvConfig: CsvGridConfiguration = {
            columns: []
        };

        for (let i in gridOptions.$gridScope.columns) {
            const column = gridOptions.$gridScope.columns[i];
            if ((column.visible === true || column.colDef.csvForceVisible === true)
                && column.field != '✔'
                && column.isAggCol !== true
                && column.colDef.csvIncluded !== false) {

                let field = column.field;
                if (angular.isString(column.colDef.csvField)) {
                    field = column.colDef.csvField;
                }

                const csvColumn:CsvColumn = {
                    field: field,
                    displayName: column.displayName,
                    index: column.index
                };

                csvConfig.columns.push(csvColumn);
            }
        }

        return csvConfig;
    };

    /**
     * Build a storable grid configuration
     * @param gridOptions
     * @return {{columns: Array}}
     */
    public buildGridConfiguration(gridOptions) {
        let gridConfig = {
            'columns': []
        };

        angular.forEach(gridOptions.$gridScope.columns, (column, index) => {
            let storedColumnDef = gridOptions.columnDefs[index];

            let localColumn = {
                // Primary Key
                field: column.field,
                labelCode: column.colDef.labelCode,
                cellFilter: column.cellFilter,

                // Changing attributes
                index: column.index,
                visible: column.visible
            };

            gridConfig.columns.push(localColumn);
        });

        return JSON.stringify(gridConfig);
    };

    private _columnEquals(column1, column2): boolean {
        return column1.field === column2.field &&
            (column1.labelCode || column1.colDef) &&
            (column2.labelCode || column2.colDef) &&
            (column1.labelCode || column1.colDef.labelCode) === (column2.labelCode || column2.colDef.labelCode) &&
            (column1.cellFilter || '') === (column2.cellFilter || '');
    };

    /**
     * Find column by name in an array
     * @param column
     * @param columns
     * @return {*}
     * @private
     */
    private _findColumn(column, columns) {
        for (let i in columns) {
            let aColumn = columns[i];
            if (this._columnEquals(aColumn, column) === true) {
                aColumn.index = i;
                return aColumn;
            }
        }
    };

    /**
     * Apply the remote gridConf to the gridOptions
     * @param gridOptions
     * @param gridConf
     */
    private applyGridConfiguration(gridOptions, gridConf) {
        if (typeof gridConf === 'string') {
            gridConf = JSON.parse(gridConf);
        }

        // Check remote grid configuration compatibility
        if (gridConf.columns.length !== gridOptions.columnDefs.length) {
            this.log.info('The remote grid configuration is not compatible with the current.');
            return;
        }

        let remoteColumn;
        for (let i in gridConf.columns) {
            remoteColumn = gridConf.columns[i];
            if (!this._findColumn(remoteColumn, gridOptions.columnDefs)) {
                this.log.info('The remote grid configuration is not compatible with the current.');
                return;
            }
        }

        let filterUndefined = function (value, key) {
            if (!angular.isDefined(value)) {
                delete this[key];
            }
        };

        // Compatibility is ok, let's proceed
        for (let j in gridConf.columns) {
            remoteColumn = gridConf.columns[j];
            let localColumn = this._findColumn(remoteColumn, gridOptions.columnDefs);
            let remoteColumnConfig = {
                visible: remoteColumn.visible
            };

            angular.forEach(remoteColumnConfig, filterUndefined, remoteColumnConfig);

            angular.extend(localColumn, remoteColumnConfig);

            gridOptions.columnDefs.splice(localColumn.index, 1);
            gridOptions.columnDefs.splice(remoteColumn.index, 0, localColumn);
        }

    };
}

export default CosGridService;
mainModule.service('CosGridService', CosGridService);
