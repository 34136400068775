export * from './AccessesKeyChainResource';
import { AccessesKeyChainResource } from './AccessesKeyChainResource';
export * from './ApplicationResource';
import { ApplicationResource } from './ApplicationResource';
export * from './BoothResource';
import { BoothResource } from './BoothResource';
export * from './BoothSettingResource';
import { BoothSettingResource } from './BoothSettingResource';
export * from './BoothSettingExcludedProductResource';
import { BoothSettingExcludedProductResource } from './BoothSettingExcludedProductResource';
export * from './BoothSettingProductResource';
import { BoothSettingProductResource } from './BoothSettingProductResource';
export * from './BrandResource';
import { BrandResource } from './BrandResource';
export * from './CustomerResource';
import { CustomerResource } from './CustomerResource';
export * from './CustomerBillingResource';
import { CustomerBillingResource } from './CustomerBillingResource';
export * from './CustomerGroupResource';
import { CustomerGroupResource } from './CustomerGroupResource';
export * from './CustomerProductImportModeResource';
import { CustomerProductImportModeResource } from './CustomerProductImportModeResource';
export * from './CustomerProductShootingModeResource';
import { CustomerProductShootingModeResource } from './CustomerProductShootingModeResource';
export * from './DefaultResource';
import { DefaultResource } from './DefaultResource';
export * from './ExportScenarioResource';
import { ExportScenarioResource } from './ExportScenarioResource';
export * from './JobResource';
import { JobResource } from './JobResource';
export * from './JobCompletionStatResource';
import { JobCompletionStatResource } from './JobCompletionStatResource';
export * from './JobDefaultShootingModeResource';
import { JobDefaultShootingModeResource } from './JobDefaultShootingModeResource';
export * from './JobFileResource';
import { JobFileResource } from './JobFileResource';
export * from './JobValidatorResource';
import { JobValidatorResource } from './JobValidatorResource';
export * from './KeychainSharingStatResource';
import { KeychainSharingStatResource } from './KeychainSharingStatResource';
export * from './ProcessHistoryResource';
import { ProcessHistoryResource } from './ProcessHistoryResource';
export * from './ProcessHistoryUnitResource';
import { ProcessHistoryUnitResource } from './ProcessHistoryUnitResource';
export * from './ProcessesResource';
import { ProcessesResource } from './ProcessesResource';
export * from './ProductResource';
import { ProductResource } from './ProductResource';
export * from './ProductColorDominanceResource';
import { ProductColorDominanceResource } from './ProductColorDominanceResource';
export * from './ProductColorLabDeltaResource';
import { ProductColorLabDeltaResource } from './ProductColorLabDeltaResource';
export * from './ProductFrameShapeResource';
import { ProductFrameShapeResource } from './ProductFrameShapeResource';
export * from './ProductShootingCommentResource';
import { ProductShootingCommentResource } from './ProductShootingCommentResource';
export * from './ProductStatusStatResource';
import { ProductStatusStatResource } from './ProductStatusStatResource';
export * from './RegisteredDomainResource';
import { RegisteredDomainResource } from './RegisteredDomainResource';
export * from './ScenarioResource';
import { ScenarioResource } from './ScenarioResource';
export * from './ScenarioAccessResource';
import { ScenarioAccessResource } from './ScenarioAccessResource';
export * from './ScenarioStatusHistoryResource';
import { ScenarioStatusHistoryResource } from './ScenarioStatusHistoryResource';
export * from './ScenarioValidationResource';
import { ScenarioValidationResource } from './ScenarioValidationResource';
export * from './SunGlassResource';
import { SunGlassResource } from './SunGlassResource';
export * from './SupplierResource';
import { SupplierResource } from './SupplierResource';
export * from './TryOnBrowserStatResource';
import { TryOnBrowserStatResource } from './TryOnBrowserStatResource';
export * from './TryOnDeviceStatResource';
import { TryOnDeviceStatResource } from './TryOnDeviceStatResource';
export * from './TryOnLibVersionStatResource';
import { TryOnLibVersionStatResource } from './TryOnLibVersionStatResource';
export * from './TryOnSessionStatResource';
import { TryOnSessionStatResource } from './TryOnSessionStatResource';
export * from './UniversalProductResource';
import { UniversalProductResource } from './UniversalProductResource';
export * from './UserResource';
import { UserResource } from './UserResource';
export * from './UserPasswordResource';
import { UserPasswordResource } from './UserPasswordResource';
export * from './UserRoleResource';
import { UserRoleResource } from './UserRoleResource';
export * from './UserValidationStatResource';
import { UserValidationStatResource } from './UserValidationStatResource';
export const APIS = [AccessesKeyChainResource, ApplicationResource, BoothResource, BoothSettingResource, BoothSettingExcludedProductResource, BoothSettingProductResource, BrandResource, CustomerResource, CustomerBillingResource, CustomerGroupResource, CustomerProductImportModeResource, CustomerProductShootingModeResource, DefaultResource, ExportScenarioResource, JobResource, JobCompletionStatResource, JobDefaultShootingModeResource, JobFileResource, JobValidatorResource, KeychainSharingStatResource, ProcessHistoryResource, ProcessHistoryUnitResource, ProcessesResource, ProductResource, ProductColorDominanceResource, ProductColorLabDeltaResource, ProductFrameShapeResource, ProductShootingCommentResource, ProductStatusStatResource, RegisteredDomainResource, ScenarioResource, ScenarioAccessResource, ScenarioStatusHistoryResource, ScenarioValidationResource, SunGlassResource, SupplierResource, TryOnBrowserStatResource, TryOnDeviceStatResource, TryOnLibVersionStatResource, TryOnSessionStatResource, UniversalProductResource, UserResource, UserPasswordResource, UserRoleResource, UserValidationStatResource];
