import {mainModule} from "../../app";

function WaitDialogCtrl($scope, $modalInstance, progress, message) {
    $scope.progress = progress;
    $scope.message = message;

    $scope.close = function () {
        $modalInstance.close(true);
    };
}

WaitDialogCtrl.$inject = ['$scope', '$modalInstance', 'progress', 'message'];

export default WaitDialogCtrl;
mainModule.controller('WaitDialogCtrl', WaitDialogCtrl);
