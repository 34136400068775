import * as welcomeTemplate from './app/welcome.html';
import * as brandListTemplate from './brand/brand-list.html';
import * as brandTemplate from './brand/brand.html';
import * as customerGroupListTemplate from './customer/customer-group-list.html';
import * as customerGroupTemplate from './customer/customer-group.html';
import * as customerListTemplate from './customer/customer-list.html';
import * as customerProductListTemplate from './customer/customer-product-list.html';
import * as customerTemplate from './customer/customer.html';
import * as registeredDomainListTemplate from './customer/registered-domain-list.html';
import * as registeredDomainTemplate from './customer/registered-domain.html';
import * as shotTemplate from './enduser/shot.html';
import * as exportScenarioListTemplate from './export_scenario/export-scenario-list.html';
import * as exportScenarioTemplate from './export_scenario/export-scenario.html';
import * as jobListTemplate from './job/job-list.html';
import * as jobProductListTemplate from './job/job-product-list.html';
import * as vtoEditorTemplate from './vto_editor/vto-editor.html';
import * as jobTemplate from './job/job.html';
import * as jobValidatorListTemplate from './job/validator/job-validator-list.html';
import * as jobValidatorTemplate from './job/validator/job-validator.html';
import * as accessesKeyChainListTemplate from './keychain/accesses-key-chain-list.html';
import * as accessesKeyChainPreviewTemplate from './keychain/accesses-key-chain-preview.html';
import * as accessesKeyChainTemplate from './keychain/accesses-key-chain.html';
import * as processesTemplate from './processes/processes.html';
import * as processesHistoryListTemplate from './processes/history/processes-history-list.html';
import * as processesHistoryTemplate from './processes/history/processes-history.html';
import * as processesHistoryUnitTemplate from './processes/history/processes-history-unit.html';
import * as productImportTemplate from './product/import/product-import.html';
import * as productListTemplate from './product/product-list.html';
import * as supplierListTemplate from './supplier/supplier-list.html';
import * as supplierTemplate from './supplier/supplier.html';
import * as boothListTemplate from './tryon_booth/booth-list.html';
import * as boothSettingBaseTemplate from './tryon_booth/booth-setting-base.html';
import * as boothSettingBoothTemplate from './tryon_booth/booth-setting-booth.html';
import * as boothSettingListTemplate from './tryon_booth/booth-setting-list.html';
import * as boothSettingProductTemplate from './tryon_booth/booth-setting-product.html';
import * as boothTemplate from './tryon_booth/booth.html';
import * as loginTemplate from './user/login.html';
import * as userListTemplate from './user/user-list.html';
import * as userTemplate from './user/user.html';
import * as jobValidationTemplate from './validation/job-validation.html';
import * as validationTemplate from './validation/validation.html';
import {mainModule} from './app';

mainModule
    .config(['$routeProvider', function ($routeProvider) {
        // Change reloadOnSearch route option default value to false
        const setReloadOnSearchDefaultToFalse = function($routeProvider) {
            const unmodifiedWhen = $routeProvider.when;
            $routeProvider.when = function(path, route) {
                if (route.reloadOnSearch === undefined) {
                    route.reloadOnSearch = false;
                }
                return unmodifiedWhen.apply($routeProvider, arguments);
            };
        };

        setReloadOnSearchDefaultToFalse($routeProvider);

        $routeProvider
            .when(
            '/login',
            {
                template: loginTemplate.default,
                controller: 'LoginCtrl',
                navbar: false,
                publicAccess: true
            })
            .when(
            '/end-user/shot/:externalId',
            {
                template: shotTemplate.default,
                controller: 'ShotCtrl',
                navbar: false
            })

            .when(
                '/admin/user',
                {template: userListTemplate.default, controller: 'UserListCtrl', navbar: true})
            .when(
                '/admin/user/:id',
                {template: userTemplate.default, controller: 'UserCtrl', navbar: true})

            .when(
                '/admin/export-scenario',
                {template: exportScenarioListTemplate.default, controller: 'ExportScenarioListCtrl', navbar: true})
            .when(
                '/admin/export-scenario/:id',
                {template: exportScenarioTemplate.default, controller: 'ExportScenarioCtrl', navbar: true})

            .when(
                '/admin/customer-group',
                {template: customerGroupListTemplate.default, controller: 'CustomerGroupListCtrl', navbar: true})
            .when(
                '/admin/customer-group/:id',
                {template: customerGroupTemplate.default, controller: 'CustomerGroupCtrl', navbar: true})

            .when(
                '/admin/customer',
                {template: customerListTemplate.default, controller: 'CustomerListCtrl', navbar: true})
            .when(
                '/product/import',
                {template: productImportTemplate.default, controller: 'ProductImportCtrl', navbar: true})
            .when(
                '/product/:display',
                {template: productListTemplate.default, controller: 'ProductListCtrl', navbar: true})
            .when(
                '/product/:display/:id',
                {template: params => '<product></product>', navbar: true})
            .when(
                '/admin/customer/:id/billing',
                {template: params => '<customer-billing></customer-billing>', navbar: true})
            .when(
                '/admin/customer/:id',
                {redirectTo: '/admin/customer/:id/base'})
            .when(
                '/admin/customer/:id/base',
                {template: customerTemplate.default, controller: 'CustomerCtrl', navbar: true})
            .when(
                '/admin/customer/:id/product-import-modes',
                {template: params => '<customer-product-import-mode></customer-product-import-mode>', navbar: true})
            .when(
                '/admin/customer/:id/product-shooting-modes',
                {template: params => '<customer-product-shooting-mode></customer-product-shooting-mode>', navbar: true})
            .when(
                '/admin/customer/:id/:display',
                {template: customerProductListTemplate.default, controller: 'ProductListCtrl', navbar: true})
            .when(
                '/admin/customer/:idCustomer/:display/:id',
                {template: params => '<product></product>', navbar: true})
            .when(
                '/admin/registered-domain',
                {template: '<registered-domain-list></registered-domain-list>', navbar: true})
            .when(
                '/admin/registered-domain/:id',
                {template: registeredDomainTemplate.default, controller: 'RegisteredDomainCtrl', navbar: true})
            .when(
                '/admin/visage-license-domain',
                {template: '<visage-license-domain-list></visage-license-domain-list>', navbar: true})
            .when(
                '/admin/visage-license-domain/:id',
                {template: params => `<visage-license-domain id="${params.id}"></visage-license-domain>`, navbar: true})
            .when(
                '/admin/accesses-key-chain',
                {template: accessesKeyChainListTemplate.default, controller: 'AccessesKeyChainListCtrl', navbar: true})
            .when(
                '/admin/accesses-key-chain/:id',
                {redirectTo: '/admin/accesses-key-chain/:id/base'})
            .when(
                '/admin/accesses-key-chain/:id/base',
                {template: accessesKeyChainTemplate.default, controller: 'AccessesKeyChainCtrl', navbar: true})
            .when(
                '/admin/accesses-key-chain/:id/:display',
                {template: accessesKeyChainPreviewTemplate.default, controller: 'ProductListCtrl', navbar: true})
            .when(
                '/admin/accesses-key-chain/:idKeychain/:display/:id',
                {template: params => '<product></product>', navbar: true})
            .when(
                '/admin/brand',
                {template: brandListTemplate.default, controller: 'BrandListCtrl', navbar: true})
            .when(
                '/admin/brand/:id',
                {template: brandTemplate.default, controller: 'BrandCtrl', navbar: true})

            .when(
                '/admin/supplier',
                {template: supplierListTemplate.default, controller: 'SupplierListCtrl', navbar: true})
            .when(
                '/admin/supplier/:id',
                {template: supplierTemplate.default, controller: 'SupplierCtrl', navbar: true})

            .when(
                '/admin/product/:display',
                {template: productListTemplate.default, controller: 'ProductListCtrl', navbar: true})
            .when(
                '/admin/product/:display/:id',
                {template: params => '<product></product>', navbar: true})
            .when(
                '/admin/scenario/:id',
                {template: params => `<scenario id="${params.id}"></scenario>`, navbar: true })
            .when(
                '/admin/job',
                {template: jobListTemplate.default, controller: 'JobListCtrl', navbar: true})
            .when(
                '/admin/job/validator',
                {template: jobValidatorListTemplate.default, controller: 'JobValidatorListCtrl', navbar: true})
            .when(
                '/admin/job/validator/:id',
                {template: jobValidatorTemplate.default, controller: 'JobValidatorCtrl', navbar: true})
            .when(
                '/admin/job/:id',
                {redirectTo: '/admin/job/:id/base'})
            .when(
                '/admin/job/:id/base',
                {template: jobTemplate.default, controller: 'JobCtrl', navbar: true})
            .when(
                '/admin/job/:id/scenarios',
                {template: '<job-scenario-list></job-scenario-list>', navbar: true})
            .when(
                '/vto-editor',
                {template: vtoEditorTemplate.default, controller: 'VtoEditorCtrl', navbar: true})
            .when(
                '/admin/job/:id/:display',
                {template: jobProductListTemplate.default, controller: 'ProductListCtrl', navbar: true})
            .when(
                '/admin/job/:idJob/:display/:id',
                {template: params => '<product></product>', navbar: true})
            .when(
                '/admin/processes/history',
                {template: processesHistoryListTemplate.default, controller: 'ProcessesHistoryListCtrl', navbar: true})
            .when(
                '/admin/processes/history/:id',
                {template: processesHistoryTemplate.default, controller: 'ProcessesHistoryCtrl', navbar: true})
            .when(
                '/admin/processes/history/:processHistoryId/:id',
                {template: processesHistoryUnitTemplate.default, controller: 'ProcessesHistoryUnitCtrl', navbar: true})
            .when(
                '/admin/processes',
                {template: processesTemplate.default, controller: 'ProcessesCtrl', navbar: true})

            .when(
                '/validation',
                {template: validationTemplate.default, controller: 'ValidationCtrl', navbar: true})
            .when(
                '/validation/job/:id',
                {template: jobValidationTemplate.default, controller: 'JobValidationCtrl', navbar: true})

            .when(
                '/booth/booth',
                {template: boothListTemplate.default, controller: 'BoothListCtrl', navbar: true})
            .when(
                '/booth/booth/:id',
                {template: boothTemplate.default, controller: 'BoothCtrl', navbar: true})

            .when(
                '/:context/booth-setting',
                {template: boothSettingListTemplate.default, controller: 'BoothSettingListCtrl', navbar: true})
            .when(
                '/:context/booth-setting/:id',
                {redirectTo: '/:context/booth-setting/:id/base'})
            .when(
                '/:context/booth-setting/:id/base',
                {template: boothSettingBaseTemplate.default, controller: 'BoothSettingBaseCtrl', navbar: true})
            .when(
                '/:context/booth-setting/:id/booth',
                {template: boothSettingBoothTemplate.default, controller: 'BoothSettingBoothCtrl', navbar: true})
            .when(
                '/:context/booth-setting/:id/product',
                {template: boothSettingProductTemplate.default, controller: 'BoothSettingProductCtrl', navbar: true})
            .when(
                '/stats/product',
                {template: '<customer-stats-product></customer-stats-product>', navbar: true })
            .when(
                '/stats/vto',
                {template: '<customer-stats-vto></customer-stats-vto>', navbar: true })
            .when(
                '/admin/shooting-release',
                {template: '<shooting-release-list></shooting-release-list>', navbar: true})
            .when(
                '/admin/shooting-release/:id',
                {template: '<shooting-release-edit></shooting-release-edit>', navbar: true})
            .when(
                '/shooting-release/:id',
                {template: '<shooting-release-view></shooting-release-view>', navbar: true})
            .when(
                '/welcome',
                {template: welcomeTemplate.default, controller: 'WelcomeCtrl', navbar: true})
            .otherwise({redirectTo: '/welcome'});
    }]);
