import {mainModule} from "../app";
import {Product,Scenario} from 'vtom-api-typescript-client';


/**
 * Map between vtom-api objects and their Gateway/Resources equivalents.
 */
export class ApiMapperService {
    static NAME = 'ApiMapperService';
    constructor() {}

    toApiProduct(product): Product  {
        if (!product) {
            return null;
        }

        return {
            id: product.id,
            gtin: product.gtin,
            model: product.model,
            colorCode: product.colorCode,
            fileId: product.fileId,
            width: product.width,
            solar: product.solar,
            brandId: product.brand ? product.brand.id : null,
            supplierId: product.supplier ? product.supplier.id : null,
            frameShapes: [],
            gender: product.gender ? product.gender.value : null,
            type: product.type
        };
    };

    toApiScenario(scenario): Scenario  {
        if (!scenario) {
            return null;
        }
        return {
            id: scenario.id,
            jobId: scenario.job.id,
            productId: scenario.product.id,
            status: scenario.status,
            type: scenario.type.value,
            nbThreeSixtyView: scenario.nbThreeSixtyView,
            alternativeShooting: scenario.alternativeShooting
        };
    };

}

mainModule.service(ApiMapperService.NAME, ApiMapperService);

