import {mainModule} from "../../app";

function ErrorDialogCtrl($scope, $modalInstance, $translate, msg, title) {
    //-- Variables -----//
    $scope.msg = msg;
    $scope.title = title;
    if (!$scope.msg || $scope.msg === '') {
        $scope.msg = $translate('errorpage.erreurcontacteradmin');
    }

    //-- Methods -----//

    $scope.close = function () {
        $modalInstance.close(true);
    };
}

ErrorDialogCtrl.$inject = ['$scope', '$modalInstance', '$translate', 'msg', 'title'];

export default ErrorDialogCtrl;
mainModule.controller('ErrorDialogCtrl', ErrorDialogCtrl);
