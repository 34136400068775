import * as angular from 'angular';
import {ManagedFileUploadControlFactory} from "../../fileupload/managed-file-upload-control";

angular.module('virtual-try-on-manager')
    .controller('ProductImportCtrl', function ($scope, $routeParams, $location, $window, $translate, $log, $sce, $q,
                                               ProductResource, JobResource, CustomerResource, ProcessHistoryUnitResource,
                                               NavbarService, InterpolableMessageService, DialogService, ProductImportResource,
                                               BrandResource, SupplierResource, ManagedFileUploadControlFactory: ManagedFileUploadControlFactory) {

        NavbarService.setAppTitle($scope, $translate('product.import.batch'));

        $scope.importRequest = {
            customerId: null,
            csvFileUuid: null,
            imageArchiveUuid: null,
            productImportMode: null,
            jobName: null,
            jobDefaultScenarioTypes: []
        };

        $scope.processHistory = null;
        $scope.processHistoryUnits = [];
        $scope.importInProgress = false;
        $scope.genders = ProductResource.getAllGenders({});
        $scope.frameShapes = ProductResource.getAllProductFrameShapes({});


        CustomerResource.findAll({query: {}}).$promise.then(customers => {
            $scope.customers = customers;
            $scope.importRequest.customerId = customers[0].id;
        });
         ProductResource.getAllShootingModes({}).$promise.then(shootingModes => {
             $scope.shootingModes = shootingModes.sort((a, b) => a.value.localeCompare(b.value));
        });

        ProductResource.getAllProductImportMode({}).$promise.then(function (values) {
            values.sort((a, b) => a.value.localeCompare(b.value));
            $scope.importModes = values;
            if (values.length > 0) {
                $scope.importRequest.productImportMode = values[0];
            }
        });

        $scope.csvUpload = ManagedFileUploadControlFactory.build();
        $scope.imageArchiveUpload = ManagedFileUploadControlFactory.build();

        /**
         * Launch import in 3 steps:
         * 1/ Upload image archive if necessary
         * 2/ Upload csv if necessary
         * 3/ Launch import
         */
        $scope.upload = async () => {
            $scope.processHistory = null;
            if ($scope.importRequest.productImportMode.allowShootingTypeChoice
                && !$scope.importRequest.jobDefaultScenarioTypes.length) {
                DialogService.error($translate('product.import.shooting.mode.required'));
                return;
            }
            if (!$scope.csvUpload.isReady()) {
                DialogService.error($translate('product.import.csv.required'));
                return;
            }
            if ($scope.importRequest.productImportMode.allowImageArchiveUpload) {
                if (!$scope.imageArchiveUpload.isReady()) {
                    DialogService.error($translate('product.import.image.archive.required'));
                    return;
                }
                $scope.importInProgress = true;
                $scope.importRequest.imageArchiveUuid = await $scope.imageArchiveUpload.uploadAndGetUuid();
            }
            $scope.importInProgress = true;
            $scope.importRequest.csvFileUuid = await $scope.csvUpload.uploadAndGetUuid();
            ProductImportResource.importProducts($scope.importRequest)
                .then(processResults)
                .finally(() => $scope.importInProgress = false);
        };


        const processResults = async function (processHistory) {
            $scope.processHistory = processHistory;
            const query = {
                query: {processHistory: {id: processHistory.id}},
                pageable: {
                    pageSize: 500
                }
            };
            const results = await ProcessHistoryUnitResource.findPage(query).$promise;
            // ProcessHistoryUnit.message is raw json, convert it to an object
            results.content.forEach(element => {
                if (element.message) {
                    element.message = JSON.parse(element.message);
                }
            });
            $scope.processHistoryUnits = results.content;
        };

        $scope.getUnitSuccesses = () => {
            return $scope.processHistoryUnits
                .filter(unit => unit.success)
                .sort((a, b) => a.value.localeCompare(b.value));
        };

        $scope.getLinesInError = () => {
            const codesWithDuplicates = $scope.processHistoryUnits
                .filter(unit => !unit.success)
                .map(unit => unit.value)
                .sort();
            return codesWithDuplicates
                .filter((code, index) => codesWithDuplicates.indexOf(code) == index);
        };

        $scope.getErrorsForLine = (line) => {
            return $scope.processHistoryUnits
                .filter(unit => !unit.success)
                .filter(unit => unit.value == line)
                .sort((a, b) => a.value.localeCompare(b.value));
        };

        /**
         * Variable value in process units are formated as:
         * {"gtin":{"value":"68808500060661","messageCode":false}}
         * To be accepted by the translate filter, we need to convert them to:
         * {"gtin":"68808500060661"}
         */
        $scope.formatVariableValues = (variableValues) => {
            const formatedValues = {};
            for (const key in variableValues) {
                formatedValues[key] = variableValues[key].value;
            }
            return formatedValues;
        };

        $scope.brandSortInfo = {fields: ['name'], columns: [], directions: ['asc']};
        $scope.brandColumns = [
            {field: 'name', labelCode: 'name'},
            {field: 'ediCode', labelCode: 'edi.code'}
        ];
        $scope.brandQuery = {};
        $scope.findBrandPage = function () {
            return BrandResource.findPage.apply(BrandResource, arguments);
        };

        $scope.supplierSortInfo = {fields: ['name'], columns: [], directions: ['asc']};
        $scope.supplierColumns = [
            {field: 'name', labelCode: 'name'},
            {field: 'ediCode', labelCode: 'edi.code'}
        ];
        $scope.supplierQuery = {};
        $scope.findSupplierPage = function () {
            return SupplierResource.findPage.apply(SupplierResource, arguments);
        };
    });


