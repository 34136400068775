import * as angular from 'angular';
import * as fileUploadTemplate from './file-upload-template.html';

/**
 * Attributes:
 * - file-handler (mandatory): object holding "files", array that will contain the uploaded files
 * - single-file: if set to true, only one single file can be uploaded
 * - target (mandatory): remote url where file(s) will be uploaded
 * - fillQuery: function called to fill query values on upload. If undefined, add no value to query.
 * - messageOnSuccess(file, message, flow): function called on success to display message
 * - onFileAdded(files, event, flow): function called when a file is added, but not yet uploaded
 * - onFileRemoved(file): function called when a non-uploaded file is removed
 * - onError($file, $message, $flow): function called when files failed to upload
 * - beforeFileUpload(flow): called before file upload is performed. May return a promise, in which case the upload will happen after the promise is resolved.
 * - hideUploadButton: if true will hide display the upload button
 */
angular.module('virtual-try-on-manager')
    .directive('fileUpload', function ($log, $translate, InterpolableMessageService, UserContextService) {
        return {
            restrict: 'E',
            scope: {
                fileHandler: '=',
                singleFile: '=',
                target: '=',
                hideUploadButton: '=',
                fillQuery: '&',
                messageOnSuccess: '&',
                beforeFileUpload: '&',
                onFileAdded: '&',
                onFileRemoved: '&',
                onError: '&'
            },
            controller: function ($scope) {
                /**
                 * Flow init object
                 * testChunks is set to false by default while controller does not support GET method
                 */
                $scope.flowInit = {
                    target: $scope.target,
                    query: {},
                    testChunks: false,
                    singleFile: $scope.singleFile,
                    simultaneousUploads: 1,
                    headers: {
                        'COS-AUTH-TOKEN': UserContextService.getAuthToken() || ''
                    }
                };

                $scope.uploader = {};

                let launchFileUpload = function() {
                    if (angular.isDefined($scope.fillQuery)) {
                        $scope.uploader.flow.opts.query = $scope.fillQuery();
                    }
                    $scope.uploader.flow.upload();
                };

                /**
                 * launches selected files upload
                 */
                $scope.uploadFiles = function () {
                    if (angular.isDefined($scope.fillQuery)) {
                        $scope.uploader.flow.opts.query = $scope.fillQuery();
                    }
                    if (!$scope.beforeFileUpload) {
                        launchFileUpload();
                        return;
                    }
                    let promise = $scope.beforeFileUpload({flow: $scope.uploader.flow});
                    if (!promise) {
                        launchFileUpload();
                        return;
                    }
                    promise.then(launchFileUpload);
                };

                $scope.onUploadSuccess = function ($file, $message, $flow) {
                    $log.info('File uploaded successfully: ', $file.name);
                    $scope.fileHandler.files.push($file);
                    $scope.messageOnSuccess({file: $file, message: $message, flow: $flow});
                };

                $scope.onUploadError = function ($file, $message, $flow) {
                    $log.error('Error uploading file: ', $file.name);
                    $log.error($message);
                    let parsedMessage = null;
                    try {
                        parsedMessage = JSON.parse($message);
                    } catch (e) {
                        $log.error(e);
                    }
                    let userMessage = 'errorpage.erreurcontacteradmin';
                    if (parsedMessage && parsedMessage.mainUserMessage) {
                        userMessage = parsedMessage.mainUserMessage;
                    }
                    InterpolableMessageService.translate(userMessage)
                        .then(function (translated) {
                            $file.successMessage = undefined;
                            $file.errorMessage = translated;
                        });
                    if ($scope.onError) {
                        $scope.onError({file: $file, message: $message, flow: $flow});
                    }
                };

                $scope.onRemoveFile = function (file) {
                    file.cancel();
                    $scope.fileHandler.files.splice($scope.fileHandler.files.indexOf(file), 1);
                    if ($scope.onFileRemoved) {
                        $scope.onFileRemoved({file: file});
                    }
                };

                $scope.onFileAddedInternal = function ($file, $event, $flow) {
                    if ($scope.onFileAdded) {
                        $scope.onFileAdded({file: $file, evt: $event, flow: $flow});
                    }
                };



            },
            template: fileUploadTemplate.default
        };
    });
