import {ILocationService, IScope} from "angular";
import {mainModule} from '../app';
import * as angular from "angular";

/**
 * Save objects in the url, which will survive when doing a 'back' browser navigation.
 * /!\ For this to work reloadOnSearch must be set to false for the current route,
 * otherwise your page will reload itself each time we change the url.
 */
export class UrlContextService {
    static readonly NAME = 'UrlContextService';

    constructor(
        private readonly $location: ILocationService,
        private readonly $rison
    ) {
    }

    private launchWatch($scope: IScope, key: string): void {
        const service = this;
        $scope.$watch('_urlContext.' + key, function (newVal, oldVal) {
            if (angular.equals(newVal, oldVal)) {
                return;
            }
            service.$location.search(key, service.$rison.stringify(newVal)).replace();
        }, true);

        /**
         * Listen for route/location change events to clear the history parameter
         */
        let routeChange = false;
        $scope.$on('$routeChangeStart', function ($event, next, current) {
            routeChange = true;
        });
        $scope.$on('$locationChangeSuccess', function ($event, next, current) {
            if (routeChange) {
                service.$location.search(key, null).replace();
            }
        });

    };

    /**
     * Extract context from current url, and bind it to the scope.
     * Further changes to the scope object will automatically update the url
     */
    bind<Type>($scope: IScope, defaultValue: Type = <Type> {}, key: string = 'ctx'): Type {
        if (/[^a-zA-Z]/.test(key)) {
            throw 'Invalid key ' + key + '. It should contain only letters.';
        }
        $scope._urlContext = $scope._urlContext || {};
        if ($scope._urlContext[key]) {
            throw 'Cannot bind the same key twice on a scope.';
        }
        let previousContextJson = this.$location.search()[key];
        $scope._urlContext[key] = previousContextJson ? this.$rison.parse(previousContextJson) : defaultValue;
        this.launchWatch($scope, key);
        return $scope._urlContext[key];
    }
}

mainModule.service(UrlContextService.NAME, UrlContextService);
