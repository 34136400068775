import * as angular from 'angular';
import {ApiMapperService} from "../api/api-mapper-service";

angular.module('virtual-try-on-manager')
    .controller('VtoEditorCtrl', function (
        $scope, $q, $timeout, $log, $location, NavbarService, JobResource, ScenarioResource,
        ApiMapperService: ApiMapperService) {
        NavbarService.setAppTitleFromObjectName('editor', $scope, 'editor');

        let scenarioStatuses = ['TrimValidated', 'Rejected', 'SetupRejected'];

        $scope.accordionStatus = {
            isJobOpen: true,
            isScenarioOpen: false,
            isScenarioDisabled: true
        };

        $scope.scenarioColumns = [
            {field: 'product.model', labelCode: 'model'},
            {field: 'product.gtin', labelCode: 'gtin.code'},
            {field: 'product.brand.name', labelCode: 'brand'},
            {field: 'product.supplier.name', labelCode: 'supplier'},
            {field: 'product.colorCode', labelCode: 'colorCode'},
            {field: 'status', labelCode: 'status'}
        ];

        $scope.jobColumns = [
            {field: 'name', labelCode: 'name'},
            {field: 'customer.name', labelCode: 'customer'},
            {
                field: 'openDate',
                labelCode: 'openDate',
                type: 'date',
                cellFilter: 'date:\'yyyy/MM/dd HH:mm:ss\''
            }
        ];

        $scope.jobSortInfo = {fields: ['name'], columns: [], directions: ['asc']};
        $scope.scenarioSortInfo = {fields: ['product.gtin'], columns: [], directions: ['asc']};


        $scope.jobQuery = {
            productQuery: {
                scenarioFilter : {
                    status: scenarioStatuses,
                    types: ['VTO', 'VTO_3D', 'FACE_CLIP']
                }
            },
            entityGraphName: 'job.with.customer'
        };

        $scope.scenarioFilter = {
            status: scenarioStatuses,
            types: ['VTO', 'VTO_3D', 'FACE_CLIP'],
            entityGraphName: 'scenario.with.customer.and.product'
        };

        $scope.findJobPage = function () {
            arguments[0].query = $scope.jobQuery;
            return JobResource.findPage.apply(JobResource, arguments);
        };

        $scope.findScenarioPage = function () {
            arguments[0].query = $scope.scenarioFilter;
            return ScenarioResource.findPage.apply(ScenarioResource, arguments);
        };

        $scope.onJobDoubleClick = function (job) {
            $scope.job = job;
            $scope.scenarioFilter.jobIds = [job.id];
            $scope.accordionStatus.isJobOpen = false;
            $scope.accordionStatus.isScenarioDisabled = false;
            $scope.accordionStatus.isScenarioOpen = true;
            return $location.path();
        };

        var getEditorIframeWindow = function() {
            let iframe = angular.element('#editor-iframe')[0] as any;
            if (iframe.contentWindow.isEditorLoaded && iframe.contentWindow.isEditorLoaded()) {
                $log.info('Editor is ready');
                return $q.resolve(iframe.contentWindow);
            }
            $log.info('Editor not loaded, trying again in 1s');

            // Editor not loaded yet, check every 1s
            return $timeout(getEditorIframeWindow, 1000);
        };


        $scope.onProductDoubleClick = function (scenario) {
            $scope.scenario = scenario;
            $scope.accordionStatus.isScenarioOpen = false;
            // Notify the editor that a new gtin was selected
            getEditorIframeWindow().then(function(iframeWindow) {
                iframeWindow.loadProduct([
                    ApiMapperService.toApiProduct(scenario.product),
                    ApiMapperService.toApiScenario(scenario)
                ]);
            });
            return $location.path();
        };


    });
