import * as angular from 'angular';
import * as processMultiResultTemplate from '../layout/process-multi-result-template.html';
import {UrlContextService} from "../navigation/url-context-service";
import {mainModule} from '../app';
import {IScope} from "angular";
import {CosGridSort} from "../layout/cos-grid-sort";
import {ProductType} from "vtom-api-typescript-client";
import {ProductTypeUtil} from "./product-enum-utils";
import * as customerSelectModalTemplate from "../customer/customer-select-modal.html";

mainModule.controller('ProductListCtrl', function ($scope: ProductListCtrlScope, $routeParams, $location, $log, $q, $httpParamSerializer, $window, $injector, $translate,
                                             ProductResource, CustomerResource, ExportScenarioResource, JobResource, AccessesKeyChainResource,
                                             UserContextService, DialogService, NavbarService, ScenarioResource, UrlContextService: UrlContextService) {

        // This Controller is use in 'product-list.html', 'customer-product-list.html' and 'job-product-list.html' and 'accesses-key-chain-preview.html'!


        $scope.initialized = false;
        const init = async () => {
            const userContext = await UserContextService.getContext();

            let previewDisplay = ['keychain', 'job', 'product'];
            $scope.sortInfo = {fields: ['model'], directions: ['asc']};
            $scope.productQuery = {};
            $scope.filterSelect = [];
            $scope.display = $routeParams.display;
            $log.debug('Display mode: ' + $scope.display);
            let isAdmin = userContext.hasRole('ADMIN');
            let isValidator = userContext.hasRole('VALIDATOR');
            let isShooter = userContext.hasRole('SHOOTER');
            let isShooterManager = userContext.hasRole('SHOOTERMANAGER');

            let initGenderFilter = async function () {
                const values = [{value: null, labelCode: 'gender.all'}];
                $scope.filterSelect.push({
                    name: 'gender',
                    initialValue: null,
                    values: values
                });
                const genders = await ProductResource.getAllGenders({}).$promise;
                angular.forEach(genders, function (item) {
                    values.push({value: item.value, labelCode: item.labelCode});
                });
            };
            if (isAdmin || isShooter) {
                if (previewDisplay.indexOf($scope.display) > -1 || $scope.display === 'all') {
                    const productStatues = await ProductResource.getAllStatuses({}).$promise;
                    let values = [null];
                    angular.forEach(productStatues, function (item) {
                        values.push(item);
                    });

                    $scope.filterSelect.push({
                        name: 'includedStatuses',
                        isMultiple: true,
                        initialValue: [],
                        placeholder: 'status',
                        style: {'min-width': '80px'},
                        values: values
                    });
                    initGenderFilter();
                } else {
                    initGenderFilter();
                }
            }

            $scope.multiSelect = true;
            $scope.enableRowDblClick = true;
            $scope.newEntityEnabled = true;

            const typeFilters = [{value: null, labelCode: 'product.type.all'}];
            Object.values(ProductType).forEach(type => {
                typeFilters.push({value: type, labelCode: ProductTypeUtil.toLabelCode(type)});
            });
            $scope.filterSelect.push({
                name: 'type',
                initialValue: null,
                values: typeFilters
            });

            const productBasePath = '#' + $location.path();
            $scope.columns = [
                {field: 'type', labelCode: 'type', width: '50px', cellTemplate: '<div class="text-center">' +
                        '<i class="product-type product-type-{{row.entity.type}}" title="{{row.entity.typeLabelCode | translate}}"></i>' +
                        '</div>'},
                {field: 'brand.name', labelCode: 'brand'},
                {field: 'supplier.name', labelCode: 'supplier'},
                {field: 'model', labelCode: 'model', linkBaseHref: productBasePath},
                {field: 'gtin', labelCode: 'gtin.code', linkBaseHref: productBasePath},
                {field: 'colorCode', labelCode: 'colorCode'},
                {field: 'barcode', labelCode: 'barcode'},
                {field: 'fileId', labelCode: 'product.fileId'}
            ];

            let statusColumn = {field: 'status', labelCode: 'status', sortable: false};

            switch ($scope.display) {
                case 'ordered':
                    $scope.gridTitleLabel = 'my.products';
                    break;
                case 'product':
                    $scope.columns.push(statusColumn);
                    $scope.customer = CustomerResource.get(parseInt($routeParams.id));
                    NavbarService.setAppTitleFromObjectName('customer.preview.title', $scope, 'customer.name');
                    break;
                case 'job':
                    $scope.columns.push(statusColumn);
                    $scope.job = JobResource.get(parseInt($routeParams.id));
                    NavbarService.setAppTitleFromObjectName('job.preview.title', $scope, 'job.name');
                    break;
                case 'keychain':
                    $scope.columns.push(statusColumn);
                    $scope.keychain = AccessesKeyChainResource.get(parseInt($routeParams.id));
                    NavbarService.setAppTitleFromObjectName('accesses.keychain.product.preview', $scope, 'keychain.name');
                    break;
                case 'all':
                    $scope.gridTitleLabel = 'products';
                    $scope.columns.push(statusColumn);
                    break;
                case 'try-on-available':
                    $scope.gridTitleLabel = 'available.products';
                    break;
            }

            $scope.columns.push({
                field: 'solar',
                labelCode: 'solar',
                cellTemplate: '<div class="text-center" style="margin-top: 9px">' +
                    '<input type="checkbox" class="ngSelectionCheckbox" ng-disabled="true" ng-model="row.entity.solar">' +
                    '</div>',
                width: '5%'
            });

            $scope.findPage = function () {
                switch ($scope.display) {
                    case 'product':
                        arguments[0].query.customer = {id: $routeParams.id};
                        break;
                    case 'job':
                        arguments[0].query.job = {id: $routeParams.id};
                        break;
                    case 'keychain':
                        arguments[0].query.scenarioFilter = arguments[0].query.scenarioFilter || {};
                        arguments[0].query.scenarioFilter.accessKeychainIds = [$routeParams.id];
                        break;
                    case 'ordered':
                        arguments[0].query.customer = userContext.user.customer;
                        arguments[0].query.includedStatuses = ['Production'];
                        break;
                    case 'try-on-available':
                        arguments[0].query.includedStatuses = ['Production'];
                        break;
                    case 'all':
                        break;
                    default:
                        $log.info('Display ' + $scope.display + ' not found, redirect to Welcome page');
                        $log.debug($routeParams);
                        $location.path('/welcome');
                }
                let page = ProductResource.findPage.apply(ProductResource, arguments);
                page.$promise.then(fetchScenarios);
                page.$promise.then(addTranslatedTypeCode);
                return page;
            };

            if (isValidator || isShooterManager || isAdmin) {

                $scope.exportEnabled = isAdmin;
                let customer;

                const setCustomer = c => {
                    customer = c;
                    return ExportScenarioResource.findByCustomer({customer: c}).$promise.then(exportScenario => {
                        $scope.exportScenario = exportScenario;
                        $scope.exportEnabled ||= exportScenario != null;
                        return exportScenario;
                    });
                }

                if ($scope.display === 'product') {
                    setCustomer({id: $routeParams.id});
                } else if ($scope.display === 'job') {
                    JobResource.get($routeParams.id)
                        .$promise
                        .then(result => setCustomer(result.customer));
                } else if (userContext.user.customer) {
                    setCustomer(userContext.user.customer);
                }

                const selectCustomer = () => {
                    return DialogService.openModal({
                        template: customerSelectModalTemplate.default,
                        controller: 'CustomerSelectModalCtrl'
                    }).result
                        .then(selection => setCustomer(selection))
                        .then(exportScenario => {
                            if (exportScenario) {
                                return exportScenario;
                            }
                            $translate('export.customer.without.scenario.error').then(message => DialogService.alert(message));
                            throw new Error('Customer has not export scenario');
                        });
                }

                $scope.downloadSelectedProductImages = function (selectedProducts) {
                    let promise;
                    if (isAdmin) {
                        promise = selectCustomer();
                    } else {
                        promise = $translate('confirm.download.selected.product.images').then(function (message) {
                            return DialogService.confirm(message).result;
                        });
                    }
                    promise.then(() => {
                        $window.open('api/product/archive?' + $httpParamSerializer({
                            customerId: customer.id,
                            productIds: selectedProducts.map((product: { id: number; }) => product.id)
                        }))
                    });
                };

                $scope.downloadAllImages = function () {
                    let promise;
                    if (isAdmin) {
                        promise = selectCustomer();
                    } else {
                        promise = $translate('confirm.download.all.images').then(function (message) {
                            return DialogService.confirm(message).result;
                        });
                    }
                    promise.then(() => $window.open('api/product/archive?' + $httpParamSerializer({
                            customerId: customer.id,
                            queryText: $scope.productQuery.queryText,
                            gender: $scope.productQuery.gender,
                            type: $scope.productQuery.type,
                            productStatus: $scope.productQuery.includedStatuses,
                            jobId: $scope.productQuery.job?.id
                        }))
                    );
                };
            }

            $scope.exportToCsv = function () {
                return ProductResource.toCsv.apply(ProductResource, arguments);
            };

            const fetchScenarios = productPage => {
                ScenarioResource.findAll({
                    filter: {
                        productIds: productPage.content.map(product => product.id)
                    }
                }).$promise.then(scenarios => {
                    productPage.content.forEach(product => {
                        product.status = scenarios
                            .filter(scenario => scenario.product.id == product.id)
                            .map(scenario => scenario.status)
                            // remove duplicates
                            .filter((status, index, self) => self.indexOf(status) == index)
                            .join();
                    })
                });
            };
            const addTranslatedTypeCode = productPage => {
                productPage.content.forEach(product => {
                    product.typeLabelCode = ProductTypeUtil.toLabelCode(product.type);
                });
            };
            $scope.initialized = true;
        };
        init();

    });

interface ProductListCtrlScope extends IScope {
    productQuery,
    sortInfo: CosGridSort,
    initialized: boolean
}
