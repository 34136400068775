import {IComponentOptions, IController, ILocationService, IPromise, IQService, IScope} from 'angular';
import * as template from './shooting-release-view.component.html';
import {mainModule} from "../app";
import {ManagedFilesApi, SoftwareRelease, SoftwareReleaseArtifact, SoftwareReleasesApi, SoftwareType} from "vtom-api-typescript-client";
import {ArtifactPlatformUtil} from "./artifact-platform";
import {SoftwareReleaseService} from "./software-release.service";

class Controller implements IController {
    readonly toPlatformLabelCode = ArtifactPlatformUtil.toLabelCode;

    release: SoftwareRelease;
    artifactWithFilenames: ArtifactWithFilename[];
    isAdmin = false;

    constructor(
        private readonly $routeParams,
        private readonly NavbarService,
        private readonly $scope: IScope,
        private readonly $q: IQService,
        private readonly $location: ILocationService,
        private readonly $translate,
        private readonly DialogService,
        private readonly SoftwareReleasesApi: SoftwareReleasesApi,
        private readonly ManagedFilesApi: ManagedFilesApi,
        private readonly SoftwareReleaseService: SoftwareReleaseService) {}


    private findRelease(id): IPromise<SoftwareRelease> {
        if (id == 'latest') {
            return this.SoftwareReleaseService.getLatestRelease(SoftwareType.SHOOTING);
        }
        return this.SoftwareReleasesApi.retrieveSoftwareRelease({id: id});
    }

    $onInit(): void {
        this.NavbarService.setAppTitle(this.$scope, this.$translate('shooting.release'));

        this.findRelease(this.$routeParams.id).then(release => {
            this.release = release;
            if (!this.release) {
                return;
            }
            this.artifactWithFilenames = [];
            for (const artifact of this.release.artifacts) {
                this.ManagedFilesApi.retrieveManagedFileMetadata({fileId: artifact.managedFileId}).then(managedFile => {
                    this.artifactWithFilenames.push({
                        artifact: artifact,
                        filename: managedFile.filename
                    });
                });
            }
        });
    }
}

type ArtifactWithFilename = {
    artifact: SoftwareReleaseArtifact,
    filename: string
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('shootingReleaseView', component);
