import {ILogCall} from "angular";
import * as loglevel from "loglevel";
import prefix from 'loglevel-plugin-prefix';
import {mainModule} from "../../app";

prefix.apply(loglevel, {
    template: '%t - %l - [%n] -'
});
loglevel.setLevel(1);

class LoggerDecorator {
    constructor(private readonly delegate: Log,
                public readonly debug: ILogCall = <ILogCall> delegate.debug,
                public readonly log: ILogCall = <ILogCall> delegate.info,
                public readonly error: ILogCall = <ILogCall> delegate.error,
                public readonly info: ILogCall = <ILogCall> delegate.info,
                public readonly warn: ILogCall = <ILogCall> delegate.warn) {

    }

    // Legacy function kept for backward compatibility and coming from AngularLogExtender
    public getInstance(name: string): LoggerDecorator {
        return new LoggerDecorator(this.delegate.getLogger(name));
    }
}

function loggerConfigurator($provide) {
    const loggerDecorator = (): any => new LoggerDecorator(loglevel);

    $provide.decorator('$log', loggerDecorator);
}

loggerConfigurator.$inject = ['$provide'];

mainModule.config(loggerConfigurator);
