import {mainModule} from "../app";
import {Platform, SoftwareRelease, SoftwareReleasesApi, SoftwareType, UserRole} from "vtom-api-typescript-client";
import {IPromise, IQService} from "angular";
import {UserContextService} from "../user/user-context-service";

export class SoftwareReleaseService {
    constructor(
        private readonly SoftwareReleasesApi: SoftwareReleasesApi,
        private readonly $q: IQService,
        private readonly UserContextService: UserContextService) {}

    getLatestRelease(software: SoftwareType): IPromise<SoftwareRelease|null> {
        return this.UserContextService.getContext().then(userContext => {
            if (![UserRole.ADMIN, UserRole.SHOOTER, UserRole.SHOOTERMANAGER, UserRole.TRIMMER, UserRole.STITCHER, UserRole.SETUPER]
                .some(role => userContext.hasRole(role))) {
                return null;
            }
            return this.SoftwareReleasesApi.listSoftwareReleases({
                types: [software],
                pageSize: 1,
                platform: this.getPlatform(),
                sortProperties: ['version'],
                sortDirections: ['DESC']
            }).then(page => {
                if (page.content.length == 0) {
                    return null;
                }
                return page.content[0];
            });
        });
    }

    private getPlatform(): Platform {
        const platformStr = navigator.platform.toLowerCase();
        if (platformStr.indexOf('win') !== -1) {
            return Platform.WINDOWSX8664;
        }
        if (platformStr.indexOf('mac') !== -1) {
            return Platform.MACOS;
        }
        if (platformStr.indexOf('linux') !== -1) {
            return Platform.LINUXX8664;
        }
        return null;
    }

}

mainModule.service('SoftwareReleaseService', SoftwareReleaseService);
