import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('BoothSettingProductCtrl', function ($log, $scope, $routeParams, $q,
                                                     UserContextService, NavbarService,
                                                     ProductResource, BoothSettingResource,
                                                     BoothSettingExcludedProductResource, BoothSettingProductResource) {
        $log = $log.getInstance('BoothSettingProductCtrl');
        $scope.sortInfo = {fields: [], columns: [], directions: []};

        let onBoothSettingProductChange = function () {
            let self = this;

            let promise = $q.when(self.boothSettingProduct);
            if (self.boothSettingProduct && self.boothSettingProduct.$promise) {
                promise = self.boothSettingProduct.$promise;
            }

            promise.then(function (boothSettingProduct) {
                if (!(boothSettingProduct.id > 0)) {
                    boothSettingProduct.product = {id: self.entity.id};
                    boothSettingProduct.boothSetting = {id: $routeParams.id};
                }
                self.boothSettingProduct = BoothSettingProductResource.save(boothSettingProduct);
            });
        };

        $scope.columns = [
            {
                field: 'included',
                labelCode: 'included',
                headerClass: 'text-center',
                sortable: false,
                cellTemplate: '<div class="text-center" style="margin-top: 9px">' +
                '<input tabindex="-1" type="checkbox" class="ngSelectionCheckbox" ng-disabled="row.entity.$checkDisabled" ng-model="row.entity.$checked" ng-change="row.entity.toggleInclusion()">' +
                '</div>',
                width: '5%'
            },
            {
                field: 'entity.boothSettingProduct.sellPrice',
                labelCode: 'price',
                cellClass: 'text-right',
                cellTemplate: '<div class="text-right" style="margin: 1px 2px 1px 1px;">' +
                '<input ng-model-options="{ debounce: 500 }" type="number" step="0.01" min="0" class="form-control" style="height: 28px; text-align: right; padding-top: 3px; padding-bottom: 3px;" ng-model="row.entity.boothSettingProduct.sellPrice" ng-change="row.entity.$onPriceChange()">' +
                '</div>',
                width: '10%',
                sortField: 'boothSettingProducts.sellPrice'
            },
            {
                field: 'entity.brand.name',
                labelCode: 'brand',
                sortField: 'brand.name'
            },
            {
                field: 'entity.supplier.name',
                labelCode: 'supplier',
                sortField: 'supplier.name'
            },
            {field: 'entity.model', labelCode: 'model', sortField: 'model'},
            {field: 'entity.gtin', labelCode: 'gtin.code', sortField: 'gtin'},
            {field: 'entity.colorCode', labelCode: 'colorCode', sortField: 'colorCode'},
            {field: 'entity.barcode', labelCode: 'barcode', sortField: 'barcode'}
        ];

        $scope.boothSetting = BoothSettingResource.get(parseInt($routeParams.id));

        const init = async () => {
            const userContext = await UserContextService.getContext();
            $scope.isAdmin = userContext.hasRole('ADMIN');
            $scope.canUpdate = $scope.isAdmin || userContext.hasRole('BOOTHSETTINGATOR');

            $scope.boothSetting
                .$promise
                .then(function (boothSetting) {
                    if ($scope.isAdmin) {
                        $log.debug('In admin context, we must filter products by the booth\'s setting customer group');
                        $scope.query = {scenarioFilter: {accessCustomerIds: [boothSetting.customer.id]}};
                    } else {
                        $scope.query = {};
                    }
                });
        }
        init();




        NavbarService.setAppTitleFromObjectName('setting.booths.products', $scope, 'boothSetting.name');



        /**
         *  After each checkbox change, impact changes on the server.
         *
         *  If it has been asked to include the product in the current setting,
         *  then it will remotely remove the product exclusion linked to the setting.
         *
         *  If it has been asked to exclude the product from the current setting,
         *  then it will remotely create a product exclusion linked to the setting.
         */
        let toggleInclusion = function () {
            let self = this;

            let boothSettingExcludedProductPromise = $q.when(self.boothSettingExcludedProduct);
            if (self.boothSettingExcludedProduct && self.boothSettingExcludedProduct.$promise) {
                boothSettingExcludedProductPromise = self.boothSettingExcludedProduct.$promise;
            }

            boothSettingExcludedProductPromise.then(function (resolvedBoothSettingExcludedProduct) {
                if (!self.$checked) {
                    $log.debug('Excluding ' + self.entity + ' from the current booth setting');
                    let boothSettingExcludedProduct = {
                        boothSetting: $scope.boothSetting,
                        product: {id: self.entity.id}
                    };
                    self.boothSettingExcludedProduct = BoothSettingExcludedProductResource.save(boothSettingExcludedProduct)
                        .$promise.catch(function () {
                            self.$checked = true;
                        });
                } else {
                    $log.debug('Including ' + self.entity + ' in the current booth setting');
                    self.boothSettingExcludedProduct = BoothSettingExcludedProductResource
                        ._delete(resolvedBoothSettingExcludedProduct.id)
                        .$promise
                        .then(function () {
                            self.boothSettingExcludedProduct = null;
                        }, function () {
                            self.$checked = false;
                        });
                }
            });
        };

        let postProcessPage = function (page) {
            page.content.forEach(function (projection) {
                projection.$onPriceChange = onBoothSettingProductChange;
            });
        };

        /**
         * Triggered on each page change or query filter change.
         * @returns {*} A promise of page result
         */
        $scope.findPage = function () {
            arguments[0].boothSetting = {id: $routeParams.id};

            return ProductResource
                .findPageByBoothSetting
                .apply(ProductResource, arguments)
                .$promise
                .then(function (page) {
                    page.content.forEach(function (filteredProduct) {
                        filteredProduct.$checked = angular.isUndefined(filteredProduct.boothSettingExcludedProduct) ||
                            filteredProduct.boothSettingExcludedProduct === null;
                        filteredProduct.toggleInclusion = toggleInclusion;
                        if (!$scope.canUpdate) {
                            filteredProduct.$checkDisabled = true;
                        }
                    });
                    (postProcessPage || angular.noop)(page);
                    return page;
                });
        };
    });
