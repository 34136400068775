import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ProcessesHistoryListCtrl', function ($scope, ProcessHistoryResource) {
        $scope.sortInfo = {fields: ['creationDate'], columns: [], directions: ['desc']};
        $scope.columns = [
            {field: 'processType', labelCode: 'process.type', cellFilter: 'enumToString'},
            {field: 'processType', labelCode: 'name', cellFilter: 'enumToLabelCode | translate'},
            {
                field: 'creationDate',
                labelCode: 'start.date',
                type: 'date',
                cellFilter: 'date:\'yyyy/MM/dd HH:mm:ss\''
            },
            {
                field: 'duration',
                labelCode: 'duration',
                type: 'date',
                cellFilter: 'date:\'HH:mm:ss.sss\':\'UTC\''
            },
            {field: 'creator.login', labelCode: 'creator'}
        ];

        $scope.findPage = function () {
            let query = arguments[0].query;
            query.entityGraphName = 'ProcessHistory.creator';
            return ProcessHistoryResource.findPage.apply(ProcessHistoryResource, arguments);
        };
    });
