import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('BoothSettingListCtrl', function($scope, $routeParams, BoothSettingResource){
        let isAdmin = $routeParams.context === 'admin';
        $scope.canCreate = isAdmin;

        $scope.columns = [
            {field: 'name', labelCode: 'name', linkBaseHref: '#/admin/booth-setting'}
        ];
        if(isAdmin){
            $scope.columns.unshift({field: 'customer.name', labelCode: 'customer'});
            $scope.sortInfo = {fields: ['customer.name'], columns: [], directions: ['asc']};
        } else {
            $scope.sortInfo = {fields: ['name'], columns: [], directions: ['asc']};
        }
        $scope.findPage = function () {
            return BoothSettingResource.findPage.apply(BoothSettingResource, arguments);
        };
    });
