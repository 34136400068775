import {IComponentOptions, IController, IPromise, IQService} from 'angular';
import {UserContext, UserContextService} from "../user/user-context-service";
import {ProductResource} from "../../../http/target/classes/openapi/code/api/ProductResource";
import {ProductFrameShapeResource} from "../../../http/target/classes/openapi/code/api/ProductFrameShapeResource";
import * as template from "./product-frame-shapes.component.html";
import {mainModule} from "../app";
import {UserRole} from "vtom-api-typescript-client";


class Controller implements IController {
    // Input
    product;

    userContext: UserContext;
    productFrameShapes;
    frameShapes;

    constructor(
        private readonly $q: IQService,
        private readonly UserContextService: UserContextService,
        private readonly ProductResource: ProductResource,
        private readonly ProductFrameShapeResource: ProductFrameShapeResource
    ) {}

    /**
     * @param shape a frame shape
     * @return the product frame shape index if found or -1
     */
    private indexOfProductFrameShape(shape): number {
        for (let i = 0; i < this.productFrameShapes.length; i++) {
            if (this.productFrameShapes[i].frameShape == shape) {
                return i;
            }
        }
        return -1;
    }

    hasFrameShape(shape): boolean {
        return this.productFrameShapes.some(productFrameShape => productFrameShape.frameShape == shape);
    };

    toggleFrameShape(shape): void {
        let index = this.indexOfProductFrameShape(shape);
        if (index < 0) {
            this.productFrameShapes.push({
                frameShape: shape,
                product: this.product
            });
        } else {
            this.productFrameShapes.splice(index, 1);
        }
        this.refreshFrameShapeOrder();
    };


    save(): void {
        this.productFrameShapes = this.ProductFrameShapeResource.saveForProduct({
            frameShapes: this.productFrameShapes,
            product: this.product
        });
    };

    private refreshFrameShapeOrder(): void {
        for (let i; i < this.productFrameShapes.length; i++) {
            this.productFrameShapes[i].rank = i;
        }
        this.frameShapes.sort((a, b) => {
            // Sort shapes so that the checked ones appear first by rank
            let indexA = this.indexOfProductFrameShape(a);
            let indexB = this.indexOfProductFrameShape(b);
            if (indexA < 0 && indexB < 0) {
                return a.localeCompare(b);
            }
            if (indexA >= 0 && indexB >= 0) {
                return this.productFrameShapes[indexB].rank - this.productFrameShapes[indexA].rank;
            }
            return indexB - indexA;
        });
    };

    isDisplayed(): boolean {
        return this.product?.id
            && (this.userContext?.hasRole(UserRole.ADMIN) || this.userContext?.hasRole(UserRole.SHOOTER));
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.product?.id) {
            return;
        }
        if (onChangesObj.product.previousValue
            && onChangesObj.product.previousValue.id == this.product.id) {
            return;
        }
        this.UserContextService.getContext().then(userContext => {
            this.userContext = userContext;

            if (!this.isDisplayed()) {
                return;
            }
            this.frameShapes = this.ProductResource.getAllProductFrameShapes({});
            this.productFrameShapes = this.ProductFrameShapeResource.findByProduct({product: this.product});
            this.$q.all([this.frameShapes.$promise, this.productFrameShapes.$promise])
                .then(() => this.refreshFrameShapeOrder());
        });
    }

}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        product: '<'
    }
};

mainModule.component('productFrameShapes', component);
