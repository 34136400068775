import {mainModule} from "../app";
import {ProductsApi, ProductType} from "vtom-api-typescript-client";

mainModule
    .controller('ProductAdditionAddModalCtrl', function (product, $scope, $modalInstance, $translate, $window, ProductsApi: ProductsApi) {
        $scope.matchingProducts = [];
        $scope.selection = {product: null};


        $scope.refreshMatchingProducts = (text: string) => {
            return ProductsApi.listProducts({
                queryText: text,
                type: ProductType.GLASSES
            }).then(page => $scope.matchingProducts = page.content);
        };


        $scope.save = function () {
            if (!$scope.selection.product) {
                $translate('please.select.element').then($window.alert);
                return;
            }
            ProductsApi.createProductAdditionCompatibility({
                productAdditionCompatibility: {
                    additionProductId: product.id,
                    baseProductId: $scope.selection.product.id
                }
            }).then(() => $modalInstance.close($scope.selection.product));
        };

        $scope.cancel = function () {
            $modalInstance.dismiss(false);
        };

    });
