import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ProcessesCtrl', function($scope, $translate, NavbarService, ProcessesResource, SunGlassResource, DialogService, ProductColorLabDeltaResource){
        NavbarService.setAppTitle($scope, $translate('processes'));

        $scope.processInput = {
            deduplicationDryRun: false,
        };
        $scope.processOuput = {
            inProgress: false,
            logUuid: null
        };

        let onProcessStart = () => {
            $scope.processOuput.inProgress = true;
            $scope.processOuput.logUuid = null;
        }

        let onProcessSuccess = () => {
            $scope.processOuput.inProgress = false;
        }

        $scope.startTrimmerSync = function(){
            onProcessStart();
            ProcessesResource.startTrimmerSync({}).$promise.then(function () {
                onProcessSuccess();
               alert('OK');
            });
        };

        $scope.fillProductGenders = function () {
            onProcessStart();
            ProcessesResource.fillProductGenders({}).$promise.then(function () {
                //success
                onProcessSuccess();
                alert('OK');
            });
        };
        $scope.setSolarUsingUniversalProductForAllProductsNotSet = function(){
            onProcessStart();
            SunGlassResource.setSolarUsingUniversalProductForAllProductsNotSet({})
                .$promise
                .then(function (number) {
                    onProcessSuccess();
                    DialogService.alert(number.data + ' products auto set');
                });
        };

        $scope.resetAllColorDelta = function () {
            onProcessStart();
            ProductColorLabDeltaResource.resetAllColorDelta({})
                .$promise
                .then(function (number) {
                    onProcessSuccess();
                    DialogService.alert('Color deltas for ' + number.data + ' products reset.');
                });
        };


        $scope.deduplicateProducts = async function () {
            onProcessStart();
            let result = await ProcessesResource.deduplicateProducts({dryRun: $scope.processInput.deduplicationDryRun}).$promise;
            $scope.processOuput.logUuid = result.data;
            onProcessSuccess();
        };
    });
