/**Translation service proxy that can be easily mocked
 *
 */
import {mainModule} from "../../app";

export class SimpleTranslationService {

    static ANGULAR_NAME = 'simpleTranslationService';

    static readonly $inject = ['$translate'];

    constructor(private readonly $translate: angular.translate.ITranslateService){

    }

    instantTranslate(key: string): string{
        return this.$translate.instant(key);
    }

}

mainModule.service(SimpleTranslationService.ANGULAR_NAME, SimpleTranslationService);
