import {mainModule} from "../../app";

function CountdownDialogCtrl($scope, $modalInstance, msg, countdown) {
    //-- Variables -----//
    $scope.msg = msg;
    $scope.countdown = countdown;
    //-- Methods -----//

    $scope.close = function () {
        $modalInstance.close(true);
    };
}

CountdownDialogCtrl.$inject = ['$scope', '$modalInstance', 'msg', 'countdown'];

export default CountdownDialogCtrl;
mainModule.controller('CountdownDialogCtrl', CountdownDialogCtrl);
