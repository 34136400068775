import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ProductScenarioModalCtrl', function (product, $scope, $modalInstance, $translate, $window, ScenarioResource,
                                                      JobResource, ProductResource, DialogService) {

        $scope.scenario = {
            product: product,
            status: 'Created'
        };

        $scope.jobs = JobResource.findAll({query: {}});
        $scope.scenarioTypes = ProductResource.getAllShootingModes({});

        $scope.save = async function () {
            if (!$scope.scenario.type) {
                $window.alert(await $translate('scenario.type.required'));
                return;
            }
            if (!$scope.scenario.job) {
                $window.alert(await $translate('scenario.job.required'));
                return;
            }
            const scenario = await ScenarioResource.save({scenario: $scope.scenario}).$promise;
            $modalInstance.close(scenario);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss(false);
        };

    });
