import * as loggerFactory from "loglevel";
import {mainModule} from "../../app";

let EagerLoaderRunner = (AuthenticationManager, CacheEvicter, HttpErrorHandler, LocationChangeService) =>
    loggerFactory.getLogger('EagerLoaderRunner').debug('Designated services correctly eager loaded');

EagerLoaderRunner.$inject = ['AuthenticationManager', 'CacheEvicter', 'HttpErrorHandler', 'LocationChangeService'];

export default EagerLoaderRunner;
mainModule.run(EagerLoaderRunner);
