import {IAttributes, IDirective, IDirectiveFactory, INgModelController, IScope} from "angular";
import {mainModule} from "../../app";

/**
 * Cosium value match directive
 */
export class CosMatchDirective implements IDirective {

    require: string = 'ngModel';
    restrict: string = 'A';
    scope = {
        cosMatch: '='
    };

    link(scope: IScope, element: JQuery, attrs : IAttributes) {
        let ngModel :INgModelController = element.controller('ngModel');
        ngModel.$validators.match = function (modelValue) {
            return modelValue === scope.cosMatch;
        };

        scope.$watch('cosMatch', function () {
            ngModel.$validate();
        });
    }

    static factory() : IDirectiveFactory {
        let cosMatchDirective: IDirectiveFactory = () => new CosMatchDirective();
        cosMatchDirective.$inject = [];
        return cosMatchDirective;
    }

    constructor() {}
}
mainModule.directive('cosMatch', CosMatchDirective.factory());
