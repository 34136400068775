import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('JobValidatorListCtrl', function ($scope, JobValidatorResource) {
        $scope.sortInfo = {fields: ['date'], columns: [], directions: ['desc']};
        $scope.findPage = function () {
            let query = arguments[0].query;
            query.entityGraphName = 'JobValidator.customer';
            return JobValidatorResource.findPage.apply(JobValidatorResource, arguments);
        };

        $scope.columns = [
            {field: 'date', labelCode: 'checked.date', type: 'date', cellFilter: 'date:\'yyyy/MM/dd HH:mm:ss\''},
            {field: 'customer.name', labelCode: 'customer'},
            {field: 'gtinFound', labelCode: 'number.gtin.found'},
            {field: 'gtinNotFound', labelCode: 'number.gtin.not.found'},
            {field: 'productNotFound', labelCode: 'number.product.not.found'}
        ];
    });
