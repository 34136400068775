import * as angular from 'angular';
import * as accessesKeyChainLayoutTemplate from './accesses-key-chain-layout.html';

angular.module('virtual-try-on-manager')
    .directive('accessesKeyChainLayout', function ($routeParams) {
        return {
            restrict: 'E',
            replace: false,
            transclude: true,
            scope: {
                subLocation: '='
            },
            controller: function ($scope) {
                $scope.isNew = $routeParams.id === 'new';
                $scope.basePath = '#/admin/accesses-key-chain/' + $routeParams.id;
            },
            template: accessesKeyChainLayoutTemplate.default
        };
    });
