import "./dialog.scss";
import * as waitTemplate from "./wait.html";
import * as errorTemplate from "./error.html";
import * as alertTemplate from "./alert.html";
import * as confirmTemplate from "./confirm.html";
import * as countdownTemplate from "./countdown.html";
import {mainModule} from "../../app";

function DialogService($modal) {
    var scope = this;

    this._pleaseWait = {
        progress: {value: 0},
        modal: null,
        message: {}
    };
    this._currentModal = null;

    this.hideAll = function () {
        scope.hidePleaseWait();
    };

    /**
     * Shows "Please Wait" message as a div on top of the screen, preventing the user to execute actions while
     * the system is processing requests.
     */
    this.showPleaseWait = function () {
        if (scope._pleaseWait.modal !== null) {
            return scope._pleaseWait.modal.result;
        }

        scope._pleaseWait.modal = $modal.open({
            template: waitTemplate.default,
            controller: 'WaitDialogCtrl',
            resolve: {
                'progress': function () {
                    return scope._pleaseWait.progress;
                },
                'message': function () {
                    return scope._pleaseWait.message;
                }
            }
        });

        return scope._pleaseWait.modal.result.then(function () {
            scope._pleaseWait.modal = null;
            scope._pleaseWait.progress = {value: 0};
            scope._pleaseWait.message = {};
        });
    };

    this.pleaseWaitProgress = function (progress, message) {
        scope._pleaseWait.progress.value = progress % 101;
        if (message) {
            scope._pleaseWait.message.value = message;
        }
    };

    /**
     * Hides PleaseWait message.
     */
    this.hidePleaseWait = function () {
        if (scope._pleaseWait.modal === null) {
            return;
        }
        scope._pleaseWait.modal.close(true);
    };

    /**
     * @return an object with a promise as result attribute
     * @param msg
     * @param header
     * @param okLabel
     * @param nokLabel
     * @param okLink
     */
    this.confirm = function (msg, header, okLabel, nokLabel, okLink) {
        return scope.openModal({
            template: confirmTemplate.default,
            controller: 'ConfirmDialogCtrl',
            resolve: {
                header: function () {
                    return header;
                },
                msg: function () {
                    return msg;
                },
                okLabel: function () {
                    return okLabel;
                },
                nokLabel: function () {
                    return nokLabel;
                },
                okLink: function () {
                    return okLink;
                }
            }
        });
    };

    this.error = function (msg, title) {
        return scope.openModal({
            template: errorTemplate.default,
            controller: 'ErrorDialogCtrl',
            resolve: {
                msg: function () {
                    return msg;
                },
                title: function () {
                    return title;
                }
            }
        });
    };

    this.alert = function (msg, btnLabel, btnClass) {
        return scope.openModal({
            template: alertTemplate.default,
            controller: 'AlertDialogCtrl',
            resolve: {
                msg: function () {
                    return msg;
                },
                btnLabel: function () {
                    return btnLabel;
                },
                btnClass: function () {
                    return btnClass;
                }
            }
        });
    };

    this.countdown = function (msg, countdown) {
        return scope.openModal({
            template: countdownTemplate.default,
            controller: 'CountdownDialogCtrl',
            resolve: {
                msg: function () {
                    return msg;
                },
                countdown: function () {
                    return countdown;
                }
            }
        });
    };

    this.openModal = function (route) {
        if (scope._currentModal !== null) {
            scope._currentModal.close(true);
            scope._currentModal = null;
        }
        scope._currentModal = $modal.open(route);
        return scope._currentModal;
    };
}

DialogService.$inject = ['$modal'];

export default DialogService;
mainModule.service('DialogService', DialogService);
