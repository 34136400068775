import * as angular from 'angular';
import {IComponentOptions, IController} from 'angular';
import * as template from './customer-product-import-modes.html';
import {EnumService} from "../misc/enum-service";
import {ProductResource} from "../../../http/target/classes/openapi/code/api/ProductResource";
import {CustomerProductImportModeResource} from "../../../http/target/classes/openapi/code/api/CustomerProductImportModeResource";

class Controller implements IController {

    private customer;
    private importModes: Array<any>;
    private customerImportModes: Array<any>;

    constructor(
        private readonly $routeParams,
        private readonly ProductResource: ProductResource,
        private readonly EnumService: EnumService,
        private readonly CustomerProductImportModeResource: CustomerProductImportModeResource) {
        this.customer = {id: $routeParams.id};
        this.importModes = ProductResource.getAllProductImportMode({});
        this.customerImportModes = CustomerProductImportModeResource.findByCustomer({customer: this.customer});
    }

    private hasImportMode(importMode) {
        return this.EnumService.hasTranslatableEnum(importMode, this.customerImportModes);
    };

    private toggleImportMode(importMode) {
        this.EnumService.toggleTranslatableEnum(importMode, this.customerImportModes);
    };

    private save() {
        this.CustomerProductImportModeResource.saveForCustomer({customer: this.customer, importModes: this.customerImportModes});
    };



    public $onInit(): void {
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

angular.module('virtual-try-on-manager').component('customerProductImportMode', component);
