import {IComponentOptions, IController} from 'angular';
import * as template from './image-style.html';
import {mainModule} from "../app";
import {ImageEffect, ImageEffectType, ImageOutputFormat, ImageStyle, ImageStylesApi} from "vtom-api-typescript-client";
import {ImageEffectTypes} from "./image-effect-types";
import {ImageOutputFormats} from "./image-output-formats";

class Controller implements IController {

    readonly effetTypes = Object.values(ImageEffectType);
    readonly toEffetTypeLabelCode = ImageEffectTypes.toLabelCode;

    readonly outputFormats = Object.values(ImageOutputFormat);
    readonly toOutputFormatsLabelCode = ImageOutputFormats.toLabelCode;

    imageStyle: ImageStyle;
    selectedEffectType = ImageEffectType.CROP;

    saveInProgress = false;

    private imageStyleId: number;
    private registerSaveListener: (any) => void;

    constructor(
        private readonly $routeParams,
        private readonly ImageStylesApi: ImageStylesApi) {
    }

    $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (!this.imageStyleId) {
            return;
        }
        this.ImageStylesApi
            .retrieveImageStyle({id: this.imageStyleId})
            .then(imageStyle => this.imageStyle = this.init(imageStyle));

        if (!this.registerSaveListener) {
            return;
        }
        this.registerSaveListener({listener: () => this.save()});
    }

    addEffect(): void {
        this.imageStyle.effects.push(<ImageEffectWithUnit> {
            type: this.selectedEffectType,
            width: 800,
            height: 600,
            centered: true,
            offsetX: 0,
            offsetY: 0,
            tolerance: 5,
            unit: MarginUnit.Pixel
        });
    }

    removeEffect(effect: ImageEffect): void {
        this.imageStyle.effects = this.imageStyle.effects.filter(e => e != effect);
    }

    moveEffect(effect: ImageEffect, delta: number): void {
        const index = this.imageStyle.effects.findIndex(e => e == effect);
        const newIndex = index + delta;
        if (newIndex < 0 || newIndex >= this.imageStyle.effects.length) {
            return;
        }
        this.imageStyle.effects[index] = this.imageStyle.effects[newIndex];
        this.imageStyle.effects[newIndex] = effect;
    }

    save(): Promise<void> {
        this.saveInProgress = true;
        return this.ImageStylesApi
            .updateImageStyle({id: this.imageStyleId, imageStyle: this.imageStyle})
            .then(style => {
                this.imageStyle = this.init(style);
                this.saveInProgress = false;
            });
    }
    
    onMarginUnitChange(effect: ImageEffect) {
        effect.bottomMarginPercent = null;
        effect.bottomMarginPixel = null;
        effect.topMarginPercent = null;
        effect.topMarginPixel = null;
        effect.leftMarginPercent = null;
        effect.leftMarginPixel = null;
        effect.rightMarginPercent = null;
        effect.rightMarginPixel = null;
    }

    private init(style: ImageStyle): ImageStyle {
        style.effects.forEach(effect => {
            let unit = MarginUnit.Pixel;
            if (effect.bottomMarginPercent
                || effect.topMarginPercent
                || effect.rightMarginPercent
                || effect.leftMarginPercent) {
                unit = MarginUnit.Percent;
            }
            (<ImageEffectWithUnit> effect).unit = unit;
        });
        return style;
    }

}

interface ImageEffectWithUnit extends ImageEffect {
    unit: MarginUnit;
}

enum MarginUnit {
    Pixel = 'Pixel',
    Percent = 'Percent'
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        imageStyleId: '<',
        registerSaveListener: '&'
    }
};

mainModule.component('imageStyle', component);
