import * as angular from "angular";
import {mainModule} from "../../app";

function InterpolableMessageService($q, $translate) {
    /**
     * @param interpolableMessage
     * @returns {*} A promise of the translated interpolable message
     */
    this.translate = function (interpolableMessage) {
        /** We need to unflat variable values. i.e Turn 'a.b.c' = 'label' into {a: {b: {c: 'label'}}} **/
        var variableValues = {};
        var translatingValues = [$q.when()];
        angular.forEach(interpolableMessage.variableValues, function (interpolableMessageValue, key) {
            if (interpolableMessageValue.messageCode === true) {
                interpolableMessageValue.value = $translate(interpolableMessageValue.value);
                translatingValues.push(interpolableMessageValue.value);
            }

            if (key.indexOf('.') !== -1) {
                var keyParts = key.split('.');
                key = keyParts[0];
                keyParts.splice(0, 1);
                keyParts = keyParts.reverse();
                angular.forEach(keyParts, function (keyPart) {
                    var tmpValue = {};
                    tmpValue[keyPart] = interpolableMessageValue;
                    interpolableMessageValue = tmpValue;
                });
            }

            variableValues[key] = interpolableMessageValue.value;
        });

        return $q.all(translatingValues).then(function () {
            return $translate(interpolableMessage.messageCode, variableValues);
        });
    };
}

InterpolableMessageService.$inject = ['$q', '$translate'];

mainModule.service('InterpolableMessageService', InterpolableMessageService);
