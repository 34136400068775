import * as angular from "angular";
import {mainModule} from "../app";

/**
 * Utility methods for handling enumerations
 */
export class EnumService {
    constructor(private readonly $translate) {}
    /**
     * Add or remove a translatable enum from a provided list
     * @param element translatable enum to add or remove
     * @param values list of translatable enums
     */
    toggleTranslatableEnum(element: any, values: any[]) {
        for (let i = 0; i < values.length; i++) {
            if (values[i].value === element.value) {
                values.splice(i, 1);
                return;
            }
        }
        values.push(element);
    };

    /**
     * Return true only if the enum is present in a list
     * @param element translatable enum to check
     * @param values list of translatable enums
     */
    hasTranslatableEnum(element: any, values: any[]): boolean {
        if (!values || !element) {
            return false;
        }
        return values.some(v => v.value == element.value);
    };

    /**
     * Translate translatable enums.
     * The translation is available in the label field.
     * @param values translatable enums
     */
    async translate<T extends TranslatableEnum>(values: T[]): Promise<T[]> {
        const self = this;
        for (let i = 0; i < values.length; i++) {
            values[i].label = await self.$translate(values[i].labelCode);
        }
        return values;
    };
}
export type TranslatableEnum = {
    value: string,
    labelCode: string,
    label?: string
}
mainModule.service('EnumService', EnumService);

