import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('CustomerListCtrl', function ($scope, CustomerResource, UserContextService) {
        let valueFalse = {value: false, labelCode: 'show.not.deleted'};
        $scope.initialized = false;
        $scope.filterSelect = [{
            name: 'deleted',
            initialValue: valueFalse,
            values: [
                valueFalse,
                {value: null, labelCode: 'show.all'},
                {value: true, labelCode: 'show.only.deleted'}
            ]
        }];
        $scope.sortInfo={fields: ['name'], columns: [], directions: ['asc']};

        $scope.findPage = function () {
            return CustomerResource.findPage.apply(CustomerResource, arguments);
        };

        $scope.columns = [
            {field: 'name', labelCode: 'name', link: function(customer){return '/admin/customer/' + customer.id;}},
            {field: 'customerGroup.name', labelCode: 'customers.group', link: function(customer){return '/admin/customer-group/' + customer.customerGroup.id;}},
            {field: 'address', labelCode: 'address'},
            {field: 'contactName', labelCode: 'contact.name'},
            {field: 'contactEmail', labelCode: 'email'},
            {field: 'contactPhone1', labelCode: 'phone1'},
            {field: 'contactPhone2', labelCode: 'phone2'}
        ];

        UserContextService.getContext().then(userContext => {
            if (userContext.hasRole('ADMIN') || userContext.hasRole('SHOOTER')) {
                $scope.columns.push({field: 'fileId', labelCode: 'customer.fileId'});
            }
            $scope.initialized = true;
        });

    });

