import * as angular from  'angular';

angular.module('virtual-try-on-manager')
    .controller('ProcessMultiResultCtrl', function ($scope, $location, $window, $translate, $log, $sce, $q, $modalInstance,$filter,
                                                InterpolableMessageService, ProcessHistoryUnitResource,
                                                action,showSummary, showSuccessSummary, showFailedSummary,
                                                title, message, summary, successSummary, failedSummary,
                                                successListSummary, failedListSummary) {

        let parseAndTranslateResult = function (item) {
            InterpolableMessageService.translate(JSON.parse(item.message))
                .then(function (translated) {
                    item.parsedMessage = translated;
                });
        };

        $scope.inProgress = false;
        $scope.action = function () {
            $scope.inProgress = true;
            action()
                .$promise
                .then(function (results) {
                    $scope.results = results;
                    let query = {
                        query: {processHistory: {id: results.id}},
                        pageable: {
                            pageNumber: 0,
                            pageSize: results.counter + 10,
                            sort: {orders: [{property: 'value', direction: 'asc'}]}
                        }
                    };
                    ProcessHistoryUnitResource.findPage(query)
                        .$promise
                        .then(function (results) {
                            $scope.units = results.content;
                            $scope.allSuccess = [];
                            $scope.allFailed = [];
                            angular.forEach($scope.units, function (item) {
                                parseAndTranslateResult(item);
                                if (item.success) {
                                    $scope.allSuccess.push(item);
                                } else {
                                    $scope.allFailed.push(item);
                                }
                            });
                        });
                    if (results.duration) {
                        let duration = $filter('date')(results.duration, 'HH:mm:ss.sss', 'UTC');
                        $translate('process.result.duration', {duration: duration}).then(function (translated) {
                            results.durationLabel = translated;
                        });
                    }
                    $translate($scope.summary, {counter: $scope.results.counter}).then(function (translated) {
                        $scope.results.summary = translated;
                    });
                    $translate($scope.successSummary, {counter: $scope.results.success}).then(function (translated) {
                        $scope.results.successSummary = translated;
                    });
                    $translate($scope.failedSummary, {counter: $scope.results.failed}).then(function (translated) {
                        $scope.results.failedSummary = translated;
                    });

                    $translate($scope.successListSummary, {counter: $scope.results.success}).then(function (translated) {
                        $scope.results.successListSummary = translated;
                    });
                    $translate($scope.failedListSummary, {counter: $scope.results.failed}).then(function (translated) {
                        $scope.results.failedListSummary = translated;
                    });
                });
        };
        $scope.close = function () {
            $modalInstance.dismiss(false);
        };

        $scope.title = title;
        $scope.message = message;
        $scope.showSummary = showSummary;
        $scope.showSuccessSummary = showSuccessSummary;
        $scope.showFailedSummary = showFailedSummary;

        $scope.summary = summary;
        $scope.successSummary = successSummary;
        $scope.failedSummary = failedSummary;

        $scope.successListSummary = successListSummary;
        $scope.failedListSummary = failedListSummary;
    });
