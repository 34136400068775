import * as angular from 'angular';
import {IController} from 'angular';
import * as template from "./customer-stats-block.component.html";
import {mainModule} from "../app";
import {UserContextService} from "../user/user-context-service";
import {CosGridSort} from "../layout/cos-grid-sort";
import {CustomerStatFilter} from "./customer-stats-top-filter.component";

/**
 * Display a customer statistic in an accordion block.
 * May show two results side by side for two different dates.
 * In which case a regular (primary) request is executed,
 * then a secondary request is triggered to retrieve secondary results matching the primary page.
 */
class Controller implements IController {
    filter: CustomerStatFilter;
    configuration: CustomerStatsBlockConfiguration;

    sortInfo = {fields: [], directions: [], columns: []};
    query;

    constructor() {}

    $onInit(): void {}

    isReady(): boolean {
        return this.isConfigured() && this.query;
    }

    isConfigured(): boolean {
        return this.filter != null && this.configuration != null;
    }

    postProcessPage(page) {
        if (!this.configuration.entityPostProcess) {
            return;
        }
        page.content.forEach(this.configuration.entityPostProcess);
    };

    findPage = request => {
        let primaryPage;
        return this.configuration.resource.findPage(request).$promise.then(page => {
            primaryPage = page;
            this.postProcessPage(page);
            if (!request.query.secondaryDate || !page.content) {
                return null;
            }
            let newQuery = angular.copy(request.query);
            newQuery.date = newQuery.secondaryDate;
            if (this.configuration.prepareSecondaryRequestFilter) {
                this.configuration.prepareSecondaryRequestFilter(newQuery, page);
            }
            return this.configuration.resource.findPage({query: newQuery, pageable: {pageSize: page.pageSize}}).$promise;
        }).then(secondaryPage => {
            if (!secondaryPage) {
                return primaryPage;
            }
            this.postProcessPage(secondaryPage);
            primaryPage.content.forEach(entity => {
                entity.secondary = this.findSecondaryEntity(entity, secondaryPage);
            });
            return primaryPage;
        });
    };

    private findSecondaryEntity(primaryEntity, secondaryPage) {
        return secondaryPage.content.find(secondaryEntity => {
            const secondaryMatchAttribute = this.configuration.secondaryMatchAttribute;
            if (secondaryMatchAttribute) {
                if (primaryEntity[secondaryMatchAttribute] == secondaryEntity[secondaryMatchAttribute]) {
                    return true;
                }
                return primaryEntity[secondaryMatchAttribute]
                    && secondaryEntity[secondaryMatchAttribute]
                    && primaryEntity[secondaryMatchAttribute].id == secondaryEntity[secondaryMatchAttribute].id
            }

            const secondaryMatchFunction = this.configuration.secondaryMatchFunction;
            return secondaryMatchFunction(primaryEntity, secondaryEntity);
        });
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.isConfigured()) {
            return;
        }

        if (!this.query) {
            this.query = angular.extend({}, this.configuration.initialQuery || {});
            this.configuration.columns.forEach(column => {
                if (column.hideSecondary) {
                    return;
                }
                column.cellTemplate = '<div class="ngCellText"><span class="text-primary" ng-if="row.entity.secondary">'
                    + '{{row.entity.secondary.' + column.field + '}} <span class="text-muted">/</span> </span>'
                    + '{{row.entity.' + column.field + '}}</div>';
            });
        }

        this.query.secondaryDate = this.filter.start;
        this.query.date = this.filter.end;
        this.query.customerId = this.filter.customerId;
        this.query.withCustomer = this.filter.customerId != null;
    }
}

export type CustomerStatsBlockConfiguration = {
    titleLabelCode: string,
    displayTextFilter: boolean,
    initiallyExpanded: boolean,
    initialQuery?,
    columns,
    prepareSecondaryRequestFilter?: (secondaryQuery, primaryPage) => void,
    entityPostProcess?: (entity) => void,
    secondaryMatchAttribute?: string,
    secondaryMatchFunction?: (a, b) => boolean,
    enablePaging: boolean,
    resource
}

mainModule.component('customerStatsBlock', {
    controller: Controller,
    template: template.default,
    bindings: {
        filter: '<',
        configuration: '<'
    }
});
