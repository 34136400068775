import {mainModule} from "../../app";

function escapeHtmlFilter() {
    return function (text) {
        if (text) {
            return text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#39;').replace(/"/g, '&quot;');
        }
        return '';
    }
}

mainModule.filter('escapeHtml', escapeHtmlFilter);
