import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('JobCtrl', function ($scope, $routeParams, $location, $window, $filter, $timeout, $translate, $q, $sce, $httpParamSerializer,
                                     NavbarService, UserContextService, InterpolableMessageService, DialogService,
                                     JobFileResource, JobResource, CustomerResource, ProductResource,
                                     JobDefaultShootingModeResource, UniversalProductResource, UserResource) {
        NavbarService.setAppTitleFromObjectName('job', $scope, 'job.name');
        UserContextService.getContext().then(userContext => {
            $scope.isAdmin = userContext.hasRole('ADMIN');
            $scope.isShooter = userContext.hasRole('SHOOTER');
        });
        $scope.isNew = $routeParams.id === 'new';
        $scope.jobId = $routeParams.id;

        let convertJobDefaultShootingMode = function (array) {
            let productShootingModes = [];
            angular.forEach(array, function (item) {
                productShootingModes.push(item.value);
            });
            return productShootingModes;
        };
        $scope.addProductLabel = '';
        $translate('up.job.add.product.header').then(function (result) {
            $scope.addProductLabel = result;
        });

        let loadJob = function () {
            JobResource.findById({identifier: $scope.jobId, entityGraphName: 'job.with.customer'})
                .$promise.then(function (result) {
                $scope.job = result;
                if (result.customer.urlSpecificCrm && result.customer.urlSpecificCrm.length > 0) {
                    $translate('up.job.add.product.header.specific', {url: result.customer.urlSpecificCrm}).then(function (result) {
                        $scope.addProductLabel = result;
                    });
                }
                $scope.openDate = $filter('date')(result.openDate, 'yyyy/MM/dd HH:mm:ss');
                if (result.returnToCustomerDate) {
                    $scope.returnToCustomerDate = $filter('date')(result.returnToCustomerDate, 'yyyy/MM/dd HH:mm:ss');
                }
                $scope.closeDate = $filter('date')(result.closeDate, 'yyyy/MM/dd HH:mm:ss');

                if (result.returnToCustomer && result.returnToCustomerModifier) {
                    // $scope.job.returnToCustomerModifier =
                    UserResource.get({id: $scope.job.returnToCustomerModifier.id})
                        .$promise
                        .then(function (result) {
                            $translate('return.to.customer.modifier.value', {
                                login: result.login,
                                firstname: result.firstname,
                                lastname: result.lastname
                            }).then(function (result) {
                                $scope.modifier = result;
                            });
                        });
                }

                ProductResource.count({
                    query: {
                        job: result,
                        includedStatuses: ['Production']
                    }
                }).$promise.then(function (value) {
                    $scope.numberProductsInProduction = value.data;
                });
                JobDefaultShootingModeResource.findByJob({job: $scope.job}).$promise.then(function (result) {
                    $scope.newJobDefaultShootingModes.values = [];
                    angular.forEach(result, function (item) {
                        $scope.newJobDefaultShootingModes.values.push(item.productShootingMode);
                    });
                });

                initFilesManagement();
            });
        };

        $scope.newJobDefaultShootingModes = {value: []};
        $scope.addProduct = {
            productGtin: '',
            newProductShootingModes: [],
            showSuccessMessage: false,
            successMessage: ''
        };

        const gtinsTextToArray = gtinsText => {
            if (!gtinsText) {
                return [];
            }
            // Split string, trim, and remove blank lines
            return gtinsText
                .split(/\r?\n/)
                .map(gtin => gtin.trim())
                .filter(gtin => gtin != '');
        };

        if (!$scope.isNew) {
            UniversalProductResource.getConfiguration({}).$promise.then(function (result) {
                $scope.createProductEnabled = result.createProductEnabled && result.enabled;
                if ($scope.createProductEnabled) {
                    $scope.addProductByUp = async function () {
                        if (!$scope.addProduct.newProductShootingModes || !$scope.addProduct.newProductShootingModes.length) {
                            const message = await $translate('job.scenario.type.required');
                            DialogService.alert(message);
                            return;
                        }
                        let productShootingModes = convertJobDefaultShootingMode($scope.addProduct.newProductShootingModes);

                        UniversalProductResource.addProductsFromUniversalData({
                            job: $scope.job,
                            gtins: gtinsTextToArray($scope.addProduct.productGtins),
                            productShootingModes: productShootingModes
                        }).$promise
                            .then(function (result) {
                                $scope.showSuccessMessage = true;
                                $scope.addProduct.successMessage += '<br>' + result.map(product => product.gtin).join('<br>');
                                loadJob();
                                $timeout(function () {
                                    let element = $window.document.getElementById('productGtin');
                                    if (element) {
                                        element.value = '';
                                        element.focus();
                                    }
                                }, function () {
                                    //do nothing on error
                                });
                            });
                    };
                }
            });
        }

        ProductResource.getAllShootingModes({}).$promise.then(function (results) {
            $scope.shootingModes = results;
        });

        if ($scope.isNew) {
            $scope.job = JobResource._default();
            CustomerResource.findAll({query: {}}).$promise.then(function (results) {
                $scope.customers = results;
            });
        } else {
            loadJob();
        }

        $scope.saveJob = function () {
            JobResource.save($scope.job)
                .$promise
                .then(function (job) {
                    if ($scope.isNew) {
                        $location.path('/admin/job/' + job.id);
                    } else {
                        let productShootingModes = convertJobDefaultShootingMode($scope.newJobDefaultShootingModes.values);
                        JobDefaultShootingModeResource.saveForJob({
                            job: $scope.job,
                            productShootingModes: productShootingModes
                        }).$promise
                            .then(function () {
                                loadJob();
                            });
                    }
                });
        };

        $scope.deleteJob = function () {
            DialogService.confirm($translate('general.confirmDeletion')).result.then(function () {
                JobResource._delete($scope.job.id)
                    .$promise.then(function () {
                    $location.path('/admin/job');
                });
            });
        };


        let initFilesManagement = function () {
            $scope.uploadedFiles = [];
            $scope.fileHandler = {files: $scope.uploadedFiles};

            JobFileResource.findByJob({job: $scope.job})
                .$promise
                .then(function (jobFiles) {
                    $scope.jobFiles = $filter('orderBy')(jobFiles, 'date');
                    checkFileExists();
                });

            $scope.hasFiles = function () {
                return $scope.jobFiles && $scope.jobFiles.length;
            };

            $scope.fillQuery = function () {
                return {jobId: $scope.jobId};
            };

            $scope.downloadFile = function (jobFile) {
                if (!jobFile.exists) {
                    return;
                }
                let params = {
                    'COS-AUTH-TOKEN': UserContextService.getAuthToken() || '',
                    'jobFileId': jobFile.id
                };
                $translate('confirm.download.job.file').then(function (message) {
                    DialogService.confirm(message).result.then(function () {
                        $window.open('api/job/downloadFile?' + $httpParamSerializer(params));
                    });
                });
            };

            $scope.deleteFile = function (jobFile) {
                $translate('general.confirmDeletion').then(function (message) {
                    DialogService.confirm(message).result.then(function () {
                        JobFileResource._delete(jobFile.id)
                            .$promise
                            .then(function () {
                                jobFile.deleted = true;
                                $scope.jobFiles = $scope.jobFiles.filter(function (item) {
                                    return item.id !== jobFile.id;
                                });
                            });
                    });
                });
            };

            $scope.messageOnSuccess = function (file, message, flow) {
                $scope.jobFiles = $filter('orderBy')(JSON.parse(message), 'date');
                checkFileExists();

                $scope.jobFiles[$scope.jobFiles.length - 1].hot = true;
                file.cancel();
                $scope.uploadedFiles.length = 0;
            };

            $scope.messageOnError = function (file, message, flow) {
                $scope.jobFiles = $filter('orderBy')(JSON.parse(message), 'date');
                checkFileExists();

                $scope.jobFiles[$scope.jobFiles.length - 1].hot = true;
                file.cancel();
                $scope.uploadedFiles.length = 0;
            };

        };
        let checkFileExists = function () {
            angular.forEach($scope.jobFiles, function (item) {
                JobFileResource.exists({jobFile: item})
                    .$promise
                    .then(function (result) {
                        item.exists = result.data;
                    });
            });
        };
    });
