import {IComponentOptions, IController, ILocationService, IScope} from 'angular';
import {UserContext, UserContextService} from "../user/user-context-service";
import * as template from "./primary-navbar.component.html";
import {mainModule} from "../app";
import * as angular from "angular";


class Controller implements IController {
    showAvailableProducts = false;
    userContext: UserContext;

    constructor(
        private readonly $scope: IScope,
        private readonly $location: ILocationService,
        private readonly UserContextService: UserContextService,
        private readonly BoothSettingResource
    ) {}

    logout(): void {
        this.UserContextService.logout();
    };

    getPath(): string {
        return this.$location.path();
    };

    $onInit(): void {
        this.UserContextService.getContext().then(userContext=> {
            this.userContext = userContext;
            if (!angular.isUndefined(userContext.user.id)) {
                this.showAvailableProducts = this.BoothSettingResource.hasBooth({}).$promise
                    .then(result => this.showAvailableProducts = result.data);
            }
        });
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('primaryNavbar', component);
