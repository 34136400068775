import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .service('ApplicationContextService', function ($log, ApplicationResource) {

        let application = null;

        this.getApplication = function () {
            if (application == null) {
                application = {
                    $promise: ApplicationResource.getVersion({})
                        .$promise
                        .then(function (result) {
                            application.appVersion = result.appVersion;
                            application.apiVersion = result.apiVersion;
                            return application;
                        })
                };
            }
            return application;
        };

    });
