import {mainModule} from "../app";
import {ProductsApi, ProductType} from "vtom-api-typescript-client";

mainModule
    .controller('CustomerSelectModalCtrl', function ($scope, $modalInstance, CustomerResource) {
        $scope.selection = {customer: null};
        $scope.customers = CustomerResource.findAll({query: {}});

        $scope.select = function () {
            $modalInstance.close($scope.selection.customer);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss(false);
        };

    });
