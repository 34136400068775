import {IComponentOptions, IController} from 'angular';
import {UserContext, UserContextService} from "../user/user-context-service";
import {ProductShootingCommentResource} from "../../../http/target/classes/openapi/code/api/ProductShootingCommentResource";
import * as template from "./product-shooting-comment.component.html";
import {mainModule} from "../app";
import {UserRole} from "vtom-api-typescript-client";


class Controller implements IController {
    // Input
    product;

    userContext: UserContext;
    productShootingComment;

    constructor(
        private readonly UserContextService: UserContextService,
        private readonly ProductShootingCommentResource: ProductShootingCommentResource
    ) {}


    save(): void {
        this.ProductShootingCommentResource.save(this.productShootingComment)
            .$promise
            .then(productShootingComment => this.productShootingComment = productShootingComment);
    };

    isDisplayed(): boolean {
        return this.product?.id
            && (this.userContext?.hasRole(UserRole.ADMIN) || this.userContext?.hasRole(UserRole.SHOOTER));
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.product?.id) {
            return;
        }
        if (onChangesObj.product.previousValue
            && onChangesObj.product.previousValue.id == this.product.id) {
            return;
        }
        this.UserContextService.getContext().then(userContext => {
            this.userContext = userContext;

            if (!this.isDisplayed()) {
                return;
            }
            this.ProductShootingCommentResource.findByProduct({product: this.product})
                .$promise.then(productShootingComment => {
                this.productShootingComment = productShootingComment || {
                    product: this.product,
                    comments: ''
                };
            });
        });
    }

}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        product: '<'
    }
};

mainModule.component('productShootingComment', component);
