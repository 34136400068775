import * as angular from 'angular';
import * as jobLayoutTemplate from './job-layout.html';

angular.module('virtual-try-on-manager')
    .directive('jobLayout', function ($routeParams) {
        return {
            restrict: 'E',
            replace: false,
            transclude: true,
            scope: {
                subLocation: '='
            },
            controller: function ($scope) {
                $scope.isNew = $routeParams.id === 'new';
                $scope.basePath = '#/admin/job/' + $routeParams.id;
            },
            template: jobLayoutTemplate.default
        };
    });
