import {compositeTranslationFilesLoaderName} from "./composite-translation-files-loader.provider";
import {IRootScopeService} from "angular";
import {mainModule} from "../../app";
export interface TranslationFilesLoader {
    load: ((options: LoaderOptions) => Promise<any>)
}

export interface LoaderOptions {
    key?: string,
    loaderNames: string[];
}

/** Event key emitted when translation language have been changed*/
export const TRANSLATION_CHANGED_EVENT = 'TRANSLATION_CHANGED_EVENT';

function TranslationServiceProvider($translateProvider) {
    const storedLangKey = 'ls.TranslationService.storedLanguage';
    const localStorageSupported = typeof(Storage) !== 'undefined' && localStorage;

    let storedLanguage;
    if (localStorageSupported && localStorage.getItem(storedLangKey)) {
        storedLanguage = localStorage.getItem(storedLangKey);
    }

    const loaderOptions: LoaderOptions = {
        loaderNames: []
    };

    /**
     * Add a loader by name to the chain of loaders
     * @param name The name of the loader
     */
    this.addLoader = name => {
        loaderOptions.loaderNames.push(name);
    };

    this.init = () => {
        $translateProvider.useLoader(compositeTranslationFilesLoaderName, loaderOptions);
        $translateProvider.useMissingTranslationHandler('MissingTranslationHandler');
        $translateProvider.useSanitizeValueStrategy('escapeParameters'); //@See  https://angular-translate.github.io/docs/#/guide/19_security

        $translateProvider.registerAvailableLanguageKeys(['de', 'en', 'es', 'fr', 'iw', 'nl', 'pl', 'pt', 'zh', 'it', 'fc']);
        $translateProvider.fallbackLanguage('fr');
        if (storedLanguage) {
            $translateProvider.preferredLanguage(storedLanguage);
        } else {
            $translateProvider.determinePreferredLanguage(() => (window.navigator.language || '').split('-').join('_'));
        }
    };

    this.$get = ($translate, $rootScope:IRootScopeService) => {
        return {
            setLanguage: language => {
                // Handle both language as string and as object (= java enum representation)
                language = language.value || language;
                if ($translate.use() === language) {
                    return;
                }
                if (localStorageSupported) {
                    localStorage.setItem(storedLangKey, language);
                }
                $translate.use(language);
                // Language changed: emit related  event
                $rootScope.$emit(TRANSLATION_CHANGED_EVENT, language);
            },
            getLanguage: () => $translate.use(),
            /**
             * Evict and reload translations
             */
            reset: () => {
                $translate.refresh();
            }
        };
    };

    return this;

}

TranslationServiceProvider.$inject = ['$translateProvider', '$rootScopeProvider'];

mainModule.provider('TranslationService', TranslationServiceProvider);
