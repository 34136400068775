import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ExportScenarioCtrl', function ($scope, $q, $routeParams, $location, $window, $translate, NavbarService, ExportScenarioResource) {
        NavbarService.setAppTitle($scope, $translate('export.scenario'));

        $scope.displayHelp = false;
        $scope.exportScenarioTypesUsableWithProducts = [];
        $scope.exportScenarioTypesUsableEverywhere = [];
        $scope.isNew = $routeParams.id === 'new';
        $scope.exportScenarioId = $routeParams.id;

        if ($scope.isNew) {
            $scope.exportScenario = ExportScenarioResource._default();
        } else {
            $scope.exportScenario = ExportScenarioResource.get(parseInt($routeParams.id));
        }

        const saveListeners = [];
        $scope.registerSaveListener = function(listener) {
            saveListeners.push(listener);
        };

        $scope.saveExportScenario = function () {
            const promises = [];
            promises.push(ExportScenarioResource.save($scope.exportScenario).$promise)
            saveListeners.forEach(listener => promises.push(listener()));
            $q.all(promises).then(function (values) {
                    if ($scope.isNew) {
                        $location.path('/admin/export-scenario/' + values[0].id);
                    } else {
                        $scope.exportScenario = values[0];
                    }
                });
        };

        ExportScenarioResource.getTypes({}).$promise.then(function (result) {
            angular.forEach(result, function (item) {
               if(item.usableOnlyWithProduct){
                   $scope.exportScenarioTypesUsableWithProducts.push(item);
               } else{
                   $scope.exportScenarioTypesUsableEverywhere.push(item);
               }
            });
        })
    });
