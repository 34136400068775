/**
 * http
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as angular from 'angular';
import {Container} from '../container';
import {ApiResource} from '../resource';

/* tslint:disable:no-unused-variable member-ordering */

export class ProductColorLabDeltaResource {
    private readonly basePath = 'api';

    static $inject: string[] = ['$http', 'transformResponseAsFile', '$httpParamSerializer'];

    constructor(private $http: ng.IHttpService, private transformResponseAsFile:any, private $httpParamSerializer?: (d: any) => any) {

    }

    /**
     * 
     */
    public _default (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productColorLabDeltaService/default';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: '_default'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param id 
     * @param permission 
     */
    public checkPermission (id: number, permission: string, extraHttpRequestParams?: any ) : ApiResource<Container<boolean>> & Container<boolean> {
        const localVarPath = this.basePath + '/std/productColorLabDeltaService/permission/{permission}/{id}'
            .replace('{' + 'id' + '}', String(id))
            .replace('{' + 'permission' + '}', String(permission));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling checkPermission.');
        }
        // verify required parameter 'permission' is not null or undefined
        if (permission === null || permission === undefined) {
            throw new Error('Required parameter permission was null or undefined when calling checkPermission.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'checkPermission'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        apiResult.$promise = apiResult.$promise.then(data => {return {data: data};});
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public resetAllColorDelta (payload: any, extraHttpRequestParams?: any ) : ApiResource<Container<number>> & Container<number> {
        const localVarPath = this.basePath + '/std/productColorLabDeltaService/resetAllColorDelta';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling resetAllColorDelta.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'resetAllColorDelta'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        apiResult.$promise = apiResult.$promise.then(data => {return {data: data};});
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public schema (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/productColorLabDeltaService/schema';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'schema'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
}
