import {IController, IScope} from 'angular';
import * as template from "./customer-stats-vto.component.html";
import {mainModule} from "../app";
import {TryOnSessionStatResource} from "../../../http/target/classes/openapi/code/api/TryOnSessionStatResource";
import {TryOnLibVersionStatResource} from "../../../http/target/classes/openapi/code/api/TryOnLibVersionStatResource";
import {TryOnDeviceStatResource} from "../../../http/target/classes/openapi/code/api/TryOnDeviceStatResource";
import {TryOnBrowserStatResource} from "../../../http/target/classes/openapi/code/api/TryOnBrowserStatResource";
import {UserContextService} from "../user/user-context-service";
import {CustomerStatFilter} from "./customer-stats-top-filter.component";
import {CustomerStatsBlockConfiguration} from "./customer-stats-block.component";


class Controller implements IController {
    minDate: Date;
    maxDate: Date;

    topFilter: CustomerStatFilter;

    isInternalUser: boolean = false;

    tryOnSessionStats: CustomerStatsBlockConfiguration;
    tryOnLibVersionStats: CustomerStatsBlockConfiguration;
    tryOnDeviceStats: CustomerStatsBlockConfiguration;
    tryOnBrowserStats: CustomerStatsBlockConfiguration;

    private unknownLabel: string;

    constructor(
        private readonly $scope: IScope,
        private readonly $translate,
        private readonly UserContextService: UserContextService,
        private readonly NavbarService,
        private readonly TryOnSessionStatResource: TryOnSessionStatResource,
        private readonly TryOnLibVersionStatResource: TryOnLibVersionStatResource,
        private readonly TryOnDeviceStatResource: TryOnDeviceStatResource,
        private readonly TryOnBrowserStatResource: TryOnBrowserStatResource) {
    }

    onTopFilterChange(filter: CustomerStatFilter) {
        this.topFilter = filter;
    }

    $onInit(): void {
        this.NavbarService.setAppTitle(this.$scope, this.$translate('analytics'));
        this.$translate('unknown').then(message => this.unknownLabel = message);

        this.UserContextService.getContext().then(context => {
            this.isInternalUser = context.user.customer == null;

            this.TryOnSessionStatResource.findValidRange({}).$promise.then(validRange => {
                this.minDate = new Date(validRange.min);
                this.maxDate = new Date(validRange.max);
            });

            this.tryOnSessionStats = {
                titleLabelCode: 'statistics.vto',
                displayTextFilter: false,
                initiallyExpanded: true,
                secondaryMatchAttribute: 'registeredDomain',
                enablePaging: true,
                columns: [
                    {field: 'registeredDomain.domain', labelCode: 'domain', hideSecondary: true},
                    {field: 'sessionCount', labelCode: 'statistics.vto.sessions'},
                    {field: 'tryOnCount', labelCode: 'statistics.vto.unique.tryon'}
                ],
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.registeredDomainIds = primaryPage.content.map(entity => entity.registeredDomain.id);
                },
                resource: this.TryOnSessionStatResource
            };

            this.tryOnDeviceStats = {
                titleLabelCode: 'statistics.vto.device',
                displayTextFilter: false,
                initiallyExpanded: false,
                secondaryMatchAttribute: 'registeredDomain',
                enablePaging: true,
                columns: [
                    {field: 'registeredDomain.domain', labelCode: 'domain', hideSecondary: true},
                    {field: 'desktopCount', labelCode: 'statistics.vto.device.desktop'},
                    {field: 'tabletCount', labelCode: 'statistics.vto.device.tablet'},
                    {field: 'mobileCount', labelCode: 'statistics.vto.device.phone'},
                    {field: 'unknownCount', labelCode: 'unknown'}
                ],
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.registeredDomainIds = primaryPage.content.map(entity => entity.registeredDomain.id);
                },
                resource: this.TryOnDeviceStatResource
            };

            this.tryOnLibVersionStats = {
                titleLabelCode: 'statistics.vto.api',
                displayTextFilter: false,
                initiallyExpanded: false,
                secondaryMatchFunction: (a, b) => {
                    return a.registeredDomain.id == b.registeredDomain.id
                        && a.version == b.version;
                },
                enablePaging: true,
                initialQuery: {withVersion: true},
                columns: [
                    {field: 'registeredDomain.domain', labelCode: 'domain', hideSecondary: true},
                    {field: 'version', labelCode: 'statistics.vto.api.version', hideSecondary: true},
                    {field: 'versionCount', labelCode: 'statistics.vto.api.count'}
                ],
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.registeredDomainIds = primaryPage.content.map(entity => entity.registeredDomain.id);
                    secondaryQuery.versions = primaryPage.content.map(entity => entity.version);
                },
                resource: this.TryOnLibVersionStatResource
            };

            this.tryOnBrowserStats = {
                titleLabelCode: 'statistics.vto.browser',
                displayTextFilter: false,
                initiallyExpanded: false,
                secondaryMatchFunction: (a, b) => {
                    return a.registeredDomain.id == b.registeredDomain.id
                        && a.browser == b.browser;
                },
                enablePaging: true,
                initialQuery: {withCount: true},
                columns: [
                    {field: 'registeredDomain.domain', labelCode: 'domain', hideSecondary: true},
                    {field: 'browser', labelCode: 'browser', hideSecondary: true},
                    {field: 'browserCount', labelCode: 'statistics.vto.unique.tryon'}
                ],
                entityPostProcess: entity => entity.browser = entity.browser || '-',
                prepareSecondaryRequestFilter: (secondaryQuery, primaryPage) => {
                    secondaryQuery.registeredDomainIds = primaryPage.content.map(entity => entity.registeredDomain.id);
                    secondaryQuery.browsers = primaryPage.content.map(entity => entity.browser == '-' ? null : entity.browser);
                },
                resource: this.TryOnBrowserStatResource
            };
        });

    }
}

mainModule.component('customerStatsVto', {
    controller: Controller,
    template: template.default
});
