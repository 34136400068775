import {IComponentOptions, IController, ILocationService, IPromise, IQService, IScope} from 'angular';
import * as template from './shooting-release-edit.component.html';
import {mainModule} from "../app";
import {ManagedFilesApi, Platform, SoftwareRelease, SoftwareReleaseArtifact, SoftwareReleasesApi, SoftwareType} from "vtom-api-typescript-client";
import {ArtifactPlatformUtil} from "./artifact-platform";
import {ManagedFileUploadControl, ManagedFileUploadControlFactory} from "../fileupload/managed-file-upload-control";

class Controller implements IController {

    readonly toPlatformLabelCode = ArtifactPlatformUtil.toLabelCode;

    release: SoftwareRelease;
    artifactUploader: ArtifactUploaders;
    saveInProgress: boolean = false;

    constructor(
        private readonly NavbarService,
        private readonly $scope: IScope,
        private readonly $location: ILocationService,
        private readonly $translate,
        private readonly DialogService,
        private readonly ManagedFileUploadControlFactory: ManagedFileUploadControlFactory,
        private readonly SoftwareReleasesApi: SoftwareReleasesApi,
        private readonly ManagedFilesApi: ManagedFilesApi,
        private readonly $routeParams,
        private readonly $q: IQService) {
    }

    save(): void {
        if (!this.artifactUploader.hasOneOrMoreFiles()) {
            this.DialogService.error(this.$translate('shooting.release.no.artifact'));
            return;
        }
        this.saveInProgress = true;
        this.artifactUploader.upload().then(() => {
            if (this.release.id) {
                return this.SoftwareReleasesApi.updateSoftwareRelease({
                    id: this.release.id,
                    softwareRelease: this.release
                });
            } else {
                return this.SoftwareReleasesApi.createSoftwareReleases({softwareRelease: this.release});
            }
        }).then(() => this.$location.path('/admin/shooting-release'))
            .finally(() => this.saveInProgress = false);
    }


    delete(): void {
        this.DialogService.confirm(this.$translate('general.confirmDeletion')).result.then(() =>
            this.$q.when(this.SoftwareReleasesApi.deleteSoftwareRelease({id: this.release.id}))
        ).then(() =>
            this.$location.path('/admin/shooting-release')
        );
    }

    $onInit(): void {
        this.NavbarService.setAppTitle(this.$scope, this.$translate('shooting.release'));
        const id = this.$routeParams.id;
        if (id == 'new') {
            this.initRelease({
                type: SoftwareType.SHOOTING,
                artifacts: []
            });
        } else {
            this.SoftwareReleasesApi.retrieveSoftwareRelease({id: id}).then(release => this.initRelease(release));
        }
    }

    private initRelease(release: SoftwareRelease): void {
        this.release = release;
        this.artifactUploader = new ArtifactUploaders(release, this.ManagedFilesApi, this.ManagedFileUploadControlFactory, this.$q);
    }
}

class ArtifactUploaders {
    uploaders: ArtifactUploader[] = [];

    constructor(private readonly release: SoftwareRelease,
                private readonly managedFilesApi: ManagedFilesApi,
                private readonly managedFileUploadControlFactory: ManagedFileUploadControlFactory,
                private readonly $q: IQService) {
        Object.keys(Platform).forEach(key => {
            const platform = Platform[key];
            let artifact = release.artifacts.find(artifact => artifact.platform == platform);
            if (!artifact) {
                artifact = {
                    platform: platform
                };
            }
            this.uploaders.push(new ArtifactUploader(artifact, this.managedFilesApi, this.managedFileUploadControlFactory.build(), this.$q));
        });
    }

    hasOneOrMoreFiles(): boolean {
        return this.uploaders.some(uploader => uploader.hasFile());
    }

    upload(): IPromise<SoftwareReleaseArtifact[]> {
        let promise = this.$q.when();
        for (let i = 0; i < this.uploaders.length; i++) {
            promise = promise.then(() => this.uploaders[i].upload());
        }
        return promise.then(() =>
            this.release.artifacts = this.uploaders
                .map(uploader => uploader.artifact)
                .filter(artifact => artifact.managedFileId != null)
        );
    }
}

class ArtifactUploader {
    filename;

    constructor(
        readonly artifact: SoftwareReleaseArtifact,
        readonly managedFilesApi: ManagedFilesApi,
        readonly control: ManagedFileUploadControl,
        private readonly $q: IQService) {
        if (artifact.managedFileId) {
            this.managedFilesApi.retrieveManagedFileMetadata({fileId: this.artifact.managedFileId})
                .then(metadata => this.filename = metadata.filename)
        }
    }

    hasFile(): boolean {
        return this.control.isReady() || this.artifact.managedFileId != null;
    }

    deleteFile(): void {
        this.artifact.managedFileId = null;
        this.filename = null;
    }

    upload(): IPromise<void> {
        if (!this.control.isReady()) {
            return this.$q.when();
        }
        return this.control.uploadAndGetUuid().then(
            uuid => {this.artifact.managedFileId = uuid;}
        );
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('shootingReleaseEdit', component);
