import {IAttributes, IDirective, IDirectiveFactory, INgModelController, IScope} from "angular";
import {mainModule} from "../../app";

/**
 * Allow use of ngModel for file inputs.
 */
export class NgModelFileInputDirective implements IDirective {

    require: string = 'ngModel';
    restrict: string = 'A';

    link(scope: IScope, element: JQuery, attrs : IAttributes, ngModel: INgModelController) {
        element.on('change', e => {
            var files = element[0].files;
            ngModel.$setViewValue(files);
        })
    }

    static factory() : IDirectiveFactory {
        let directiveFactory: IDirectiveFactory = () => new NgModelFileInputDirective();
        directiveFactory.$inject = [];
        return directiveFactory;
    }

    constructor() {}
}
mainModule.directive('ngModelFileInput', NgModelFileInputDirective.factory());
