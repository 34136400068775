import {LoaderOptions, TranslationFilesLoader} from "./translation-service";
import * as loggerFactory from "loglevel";
import {mainModule} from "../../app";

class FrameworkTranslationFilesLoader implements TranslationFilesLoader {
    private readonly log: Log;
    static readonly ANGULAR_NAME = 'FrameworkTranslationFilesLoader';

    constructor() {
        this.log = loggerFactory.getLogger(FrameworkTranslationFilesLoader.ANGULAR_NAME);
    }

    load(options: LoaderOptions): Promise<any> {
        this.log.debug('Loading translation for language ' + options.key);
        return import('./translation/' + options.key + '.json');
    };
}

FrameworkTranslationFilesLoader.$inject = [];

export default FrameworkTranslationFilesLoader;

mainModule.service(FrameworkTranslationFilesLoader.ANGULAR_NAME, FrameworkTranslationFilesLoader);
