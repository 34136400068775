import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ProcessesHistoryCtrl', function ($scope, $routeParams, $location, $window,
                                                  NavbarService, InterpolableMessageService,
                                                  ProcessHistoryResource, ProcessHistoryUnitResource, UserResource) {
        let processHistoryId = $routeParams.id;

        ProcessHistoryResource.get(processHistoryId)
            .$promise
            .then(function (result) {
                $scope.processHistory = result;
                $scope.processHistory.creator = UserResource.get(parseInt(result.creator.id));
            });

        NavbarService.setAppTitleFromObjectName('process', $scope, 'processHistory.processType.value');

        $scope.columns = [
            {field: 'value', labelCode: 'value', width: '10%'},
            {
                field: 'message',
                labelCode: 'message',
                cellFilter: 'interpolableMessage',
                width: '69%'
            },
            {
                field: 'startDate',
                labelCode: 'start.date',
                type: 'date',
                cellFilter: 'date : \'HH:mm:ss.sss\'',
                width: '7%'
            },
            {
                field: 'duration',
                labelCode: 'duration',
                type: 'date',
                cellFilter: ' date : \'HH:mm:ss.sss\':\'UTC\'',
                width: '7%'
            },
            {
                field: 'success',
                labelCode: 'status',
                cellFilter: 'booleanTranslate : \'success\':\'failed\'',
                width: '5%'
            }
        ];

        $scope.query = {processHistory: {id: processHistoryId}};
        $scope.findPage = function () {
            return ProcessHistoryUnitResource.findPage.apply(ProcessHistoryUnitResource, arguments);
        };
        $scope.sortInfo = {fields: ['id'], columns: [], directions: ['desc']};
        $scope.filterSelect = [{
            name: 'success',
            initialValue: null,
            values: [
                null,
                {value: true, labelCode: 'success'},
                {value: false, labelCode: 'failed'}
            ]
        }];

    });
