import * as merge from "deepmerge";
import {LoaderOptions, TranslationFilesLoader} from "./translation-service";
import * as loggerFactory from "loglevel";
import {mainModule} from "../../app";

function CompositeTranslationFilesLoaderFactory($q, $injector) {
    const log = loggerFactory.getLogger('CompositeTranslationFilesLoader');
    return (options: LoaderOptions) => {
        let loaderNames = options.loaderNames;
        log.debug('Loading language ' + options.key + ' using loaders "' + loaderNames.join(', ') + '"');
        let loaders: TranslationFilesLoader[] = options.loaderNames
            .map(loaderName => <TranslationFilesLoader> $injector.get(loaderName));

        return $q.allSettled(loaders.map(loader => loader.load(options)))
            .then(translations => {
                let merged = {};
                translations
                    .filter(translationResult => translationResult.state === 'fulfilled')
                    .map(translationResult => translationResult.value)
                    .forEach(translation => merged = merge(translation, merged));
                return merged;
            });
    }
}

CompositeTranslationFilesLoaderFactory.$inject = ['$q', '$injector'];

export const compositeTranslationFilesLoaderName = 'CompositeTranslationFilesLoader';

mainModule.factory(compositeTranslationFilesLoaderName, CompositeTranslationFilesLoaderFactory);
