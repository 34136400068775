import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('CustomerGroupListCtrl', function ($scope, CustomerGroupResource) {
        $scope.customerGroups = CustomerGroupResource.query();
        $scope.columns = [
            {
                name: 'name', labelCode: 'name', link: function (customerGroup) {
                    return '/admin/customer-group/' + customerGroup.id;
                }
            },
            {
                name: 'accessesKeyChain.name',
                labelCode: 'accesses.keychain',
                link: function (customerGroup) {
                    return customerGroup.accessesKeyChain ? '/admin/accesses-key-chain/' + customerGroup.accessesKeyChain.id : '';
                }
            }
        ];
    });
