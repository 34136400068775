import * as angular from "angular";
import {IPromise} from "angular";

/**
 * AccessesKeyChain Resource
 * accesses-key-chain
 */
export class AccessesKeyChainsResource {

    private readonly BASE_PATH = 'api/accesses-key-chains/';

    constructor(private readonly $http: ng.IHttpService) {
    }

    public addAllProducts(id: number): IPromise<any> {
        const localUrl = this.BASE_PATH + id + '/products/all';
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'PUT',
            url: localUrl,
        };

        return this.$http(httpRequestParams).then(config => config.data);
    }
}
angular.module('virtual-try-on-manager')
    .service('AccessesKeyChainsResource', AccessesKeyChainsResource);

