import * as angular from "angular";
import * as $ from "jquery/dist/jquery";
import * as template from "./cos-grid-template.html";
import * as footerTemplate from "./footer-template.html";
import * as rowTemplate from "./row-template.html";
import * as actionsCellTemplate from "./actions-cell-template.html";
import * as aggregateTemplate from "./aggregate-template.html";
import * as footerTotalTemplate from "./footer-total-template.html";
import * as linkCellTemplate from "./link-cell-template.html";
import {NO_SAVE_NOTIFICATION_OPTION} from "../notification/save-notification.configurator";
import {mainModule} from "../../app";

/**
 * Cosium grid directive
 * See https://angular-ui.github.io/ui-grid/ for usage
 */
function cosGridDirective() {
	return {
		restrict: 'A',
		replace: false,
		scope: true,
		controller: controller,
		template: template.default
	};
}

function controller($scope, $translate, $attrs, $q, CosGridService, GridConfigurationResource, TranslationService, $location) {
	$scope.ready = false;

	$scope.gridOptions = $scope.$eval($attrs.cosGridOptions);
	$scope.queryAction = $scope.$eval($attrs.cosGridQueryAction);
	$scope.gridName = $attrs.cosGridName;
	$scope.entityBaseUrl = $attrs.cosGridEntityBaseUrl;
	$scope.entityUrlEvaluator = $scope.$eval($attrs.cosGridEntityUrlEvaluator);
	$scope.aggregateFunction = $scope.$eval($attrs.cosGridAggregateFunction);
	$scope.totalFunction = $scope.$eval($attrs.cosGridTotalFunction);
	$scope.totalFunctionAndPagination = $scope.$eval($attrs.cosGridTotalFunctionAndPagination);
	$scope.gridConfigurationStorage = angular.isDefined($attrs.cosGridConfigurationStorage) ? JSON.parse($attrs.cosGridConfigurationStorage) : true;
	/** Disable adaptative height if you are having scroll performance issue **/
	var adaptativeHeight = angular.isDefined($attrs.cosGridAdaptativeHeight) ? JSON.parse($attrs.cosGridAdaptativeHeight) : true;

	if (!$scope.gridOptions) {
		throw 'CosGridDirective: The following mandatory attributes is missing: cosGridOptions';
	}

	if (!$scope.gridName && $scope.gridConfigurationStorage) {
		throw 'CosGridDirective: The following mandatory attributes is missing: gridName (or disable gridConfigurationStorage which is disabled by default)';
	}

	var storedGridConfigurationPromise = $q.when(null);
	var applyStoredGridConfiguration = function (storedGridConf) {
		if (!storedGridConf) {
			return;
		}

		if (storedGridConf.id) {
			$scope.storedGridConf = storedGridConf;
			CosGridService.applyGridConfiguration($scope.gridOptions, $scope.storedGridConf.configuration);
		}
	};
	if ($scope.gridConfigurationStorage) {
		// Get the current grid configuration
		$scope.storedGridConf = null;

		storedGridConfigurationPromise = GridConfigurationResource.findByViewNameAndGridName({
			viewName: $location.url(),
			gridName: $scope.gridName
		}).$promise;
	}

	// Default options
	var defaultOptions = {
		enableHighlighting: true,
		enablePaging: true,
		showFooter: true,
		filterOptions: {
			useExternalFilter: true
		},
		useExternalSorting: true,
		enableColumnResize: true,
		enableColumnReordering: true,
		enableRowSelection: true,
		multiSelect: false,
		jqueryUITheme: true,
		i18n: TranslationService.getLanguage(),
		showColumnMenu: true,
		selectedItems: [],
		primaryKey: 'id',
		footerTemplate: footerTemplate.default,
		rowTemplate: rowTemplate.default
	};

	$scope.gridOptions.plugins = $scope.gridOptions.plugins || [];
	if (adaptativeHeight) {
		$scope.gridOptions.plugins.push(new ngGridFlexibleHeightPlugin());
	}
	if ($scope.gridOptions.remoteMultiSelect) {
		$scope.gridOptions.plugins.push(new ngGridRemoteSelectionPlugin($scope));
	}
	if ($scope.gridOptions.actions) {
		angular.forEach($scope.gridOptions.actions, function (action) {
			$scope.gridOptions.columnDefs.unshift({
				cellTemplate: actionsCellTemplate.default,
				action: action,
				width: '50px'
			});
		});
	}

	if ($scope.aggregateFunction) {
		$scope.gridOptions.aggregateTemplate = aggregateTemplate.default;
	}

	if ($scope.totalFunction) {
		$scope.gridOptions.footerTemplate = footerTotalTemplate.default;
	}
	if ($scope.totalFunctionAndPagination) {
		$scope.gridOptions.footerTemplate = footerTotalTemplate.default + footerTemplate.default;
		$scope.totalFunction = $scope.totalFunctionAndPagination;
	}


	// Copy default options to custom options without property override on custom options
	angular.extend(defaultOptions, $scope.gridOptions);
	angular.extend($scope.gridOptions, defaultOptions);

	if ($scope.gridOptions.pagingOptions && angular.isNumber($scope.gridOptions.pagingOptions.pageSize)) {
		$scope.gridOptions.pagingOptions.pageSize = $scope.gridOptions.pagingOptions.pageSize.toString();
	}

	if (!$scope.queryAction && ($scope.gridOptions.enablePaging || $scope.gridOptions.useExternalSorting)) {
		throw 'CosGridDirective: cosGridQueryAction must be provided when enabling paging or external sorting';
	}

	// Translate labels
	var translatePromises = [];
	angular.forEach($scope.gridOptions.columnDefs, function (columnDef) {
		/** Groupable is false by default **/
		if (typeof columnDef.groupable === 'undefined') {
			columnDef.groupable = false;
		}

		/** Process link base href **/
		if (columnDef.linkBaseHref) {
			if (!columnDef.linkBaseHref.endsWith('/')) {
				columnDef.linkBaseHref = columnDef.linkBaseHref + '/';
			}
			if (!angular.isDefined(columnDef.linkBaseHrefCondition)) {
				columnDef.linkBaseHrefCondition = true;
			}
			columnDef.cellTemplate = linkCellTemplate.default;
		}

		/** Process column header translation**/
		if (columnDef.labelCode) {
			var translatePromise = $translate(columnDef.labelCode);
			translatePromises.push(translatePromise);
			translatePromise.then(function (translatedString) {
				columnDef.displayName = translatedString;
			});
		}
	});

	// Keep the system grid options before potential customization
	var systemGridOptions;
	$q.all(translatePromises).then(function () {
		systemGridOptions = angular.copy($scope.gridOptions);

		storedGridConfigurationPromise
			.then(applyStoredGridConfiguration)
			.then(function () {
				$scope.ready = true;
			});

	});

	/**
	 * Query the server
	 */
	var queryServer = function (reset?) {
		if (reset === true) {
			$scope.gridOptions.pagingOptions.currentPage = 1;
		}
		var pageable = CosGridService.buildServerPageable($scope.gridOptions);
		$scope.queryAction(pageable);
	};

	/**
	 * On grid configuration change, upload the new config to the server
	 */
	var saveStoredGridConfPromise = $q.when();
	var doOnGridConfigurationChange = function () {
		var newGridConfig = CosGridService.buildGridConfiguration($scope.gridOptions);
		if ($scope.storedGridConf !== null) {
			$scope.storedGridConf.configuration = newGridConfig;
		} else {
			$scope.storedGridConf = {
				viewName: $location.url(),
				gridName: $scope.gridName,
				configuration: newGridConfig
			};
		}

		/* Avoid concurrent save */
		saveStoredGridConfPromise.then(() => {
			$scope.storedGridConf = GridConfigurationResource.save($scope.storedGridConf, NO_SAVE_NOTIFICATION_OPTION);
			saveStoredGridConfPromise = $scope.storedGridConf.$promise;
		});
	};
	var onGridConfigurationChange = function () {
		storedGridConfigurationPromise.then(doOnGridConfigurationChange);
	};

	/** Manage row double click **/
	$scope.onRowDblClick = function (row) {
		if ((!$scope.entityBaseUrl || $scope.entityBaseUrl === '') && !$scope.entityUrlEvaluator) {
			return;
		}

		if ($scope.entityUrlEvaluator) {
			$location.path($scope.entityUrlEvaluator(row.entity));
		} else {
			if (!$scope.entityBaseUrl.endsWith('/')) {
				$scope.entityBaseUrl = $scope.entityBaseUrl + '/';
			}
			if ($scope.entityBaseUrl.startsWith('#')) {
				$scope.entityBaseUrl = $scope.entityBaseUrl.substring(1);
			}
			if (row.entity && typeof row.entity.id !== 'undefined') {
				$location.path($scope.entityBaseUrl + row.entity.id);
			}
		}
	};

	$scope.resetConfiguration = function () {
		if ($scope.storedGridConf === null) {
			return;
		}

		$scope.ready = false;
		GridConfigurationResource._delete($scope.storedGridConf.id).$promise.then(() => {
			fakeEvent = true;
			$scope.storedGridConf = null;
			$scope.gridOptions = systemGridOptions;
			$scope.ready = true;
		});
	};

	$scope.getPropertyValue = function (object, property) {
		if (!object || object === null || !property) {
			return;
		}
		property = property.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		property = property.replace(/^\./, '');           // strip a leading dot
		var propertyArray = property.split('.');
		while (propertyArray.length) {
			var n = propertyArray.shift();
			if (object && object !== null && n in object) {
				object = object[n];
			} else {
				return;
			}
		}
		return object;
	};

	$scope.evaluateScopeValue = function (property) {
		if (typeof property === 'string') {
			var scopeValue = $scope[property];
			if (angular.isFunction(scopeValue)) {
				var args = [];
				angular.forEach(arguments, function (arg, index) {
					if (index !== 0) {
						args.push(arg);
					}
				});
				return scopeValue.apply(this, args);
			} else {
				return scopeValue;
			}
		} else {
			return property;
		}
	};

	$scope.getGroup = function (col) {
		//console.log('toto'); WTF?
	};

	// Watch paging options
	if ($scope.gridOptions.enablePaging) {
		$scope.$watch('gridOptions.pagingOptions', function (newPaging, oldPaging) {
			if (angular.equals(newPaging, oldPaging)) {
				return;
			}
			if ($scope.gridOptions) {
				$scope.gridOptions.selectAll(false);
			}
			queryServer(newPaging.pageSize !== oldPaging.pageSize);
		}, true);
	}


	// Watch sort info
	if ($scope.gridOptions.useExternalSorting) {
		$scope.$watch('gridOptions.sortInfo', function (newVal, oldVal) {
			if (angular.equals(newVal.fields, oldVal.fields) && angular.equals(newVal.directions, oldVal.directions)) {
				return;
			}
			queryServer();
		}, true);
	}

	if ($scope.gridConfigurationStorage) {
		// Watch for grid configuration changes
		$scope.$watch('gridOptions.ngGrid.$topPanel', function (topPanel, oldVal) {
			if (!topPanel || oldVal) {
				return;
			}
			topPanel.on('drop', '.ngHeaderScroller', onGridConfigurationChange);
		});

		var fakeEvent = true;
		$scope.$on('ngGridEventColumns', function () {
			/* Only react to column reordering */
			if ($scope.gridOptions.$gridScope.isColumnResizing === true) {
				return;
			}
			if (fakeEvent === true) {
				fakeEvent = false;
				return;
			}

			onGridConfigurationChange();
		});
	}

}

export default cosGridDirective;

/** Plugin that extend the ngGrid selection to 'remember' items across page change/sort/etc.. when using a remote source */
var ngGridRemoteSelectionPlugin = function (intialScope) {
	var self = this;
	self.$scope = null;
	self.myGrid = null;
	self.selectAllClicked = false;
	self.previousSelectionChangeListener = intialScope.gridOptions.beforeSelectionChange;


	// Tweak the grid options
	intialScope.gridOptions.multiSelect = true;
	intialScope.gridOptions.showSelectionCheckbox = true;
	intialScope.gridOptions.checkboxHeaderTemplate = '<input class="ngSelectionHeader" type="checkbox" ng-show="multiSelect" ng-model="gridOptions.allSelected" ng-change="toggleRemoteSelectAll(gridOptions.allSelected)"/>';
	intialScope.gridOptions.beforeSelectionChange = function (rowItem, event) {
		if (rowItem instanceof Array && typeof event === 'boolean' && !self.selectAllClicked) {
			return false;
		}
		self.selectAllClicked = false;
		if (self.previousSelectionChangeListener) {
			return self.previousSelectionChangeListener(rowItem, event);
		}
		return true;
	};

	intialScope.toggleRemoteSelectAll = function (allSelected) {
		if (!allSelected) {
			var foundNonSelectedElement = false;
			angular.forEach(self.myGrid.rowCache, function (rowItem) {
				if (!self.$scope.selectionProvider.getSelection(rowItem.entity)) {
					self.$scope.selectionProvider.setSelection(rowItem, true);
					foundNonSelectedElement = true;
				}
			});

			if (foundNonSelectedElement) {
				intialScope.gridOptions.allSelected = true;
				return;
			}
		}
		self.selectAllClicked = true;
		self.$scope.toggleSelectAll(allSelected);
	};


	// The init method gets called during the ng-grid directive execution.
	self.init = function (scope, grid, services) {
		// We need access to the grid object and scope
		self.$scope = scope;
		self.myGrid = grid;
	};
};

var ngGridFlexibleHeightPlugin = function (opts?) {
	var self = this;
	self.grid = null;
	self.scope = null;
	self.init = function (scope, grid, services) {
		self.domUtilityService = services.DomUtilityService;
		self.grid = grid;
		self.scope = scope;
		var recalcHeightForData = function () {
			setTimeout(innerRecalcForData, 1);
		};
		var innerRecalcForData = function () {
			var gridId = self.grid.gridId;
			var footerPanelSel = '.' + gridId + ' .ngFooterPanel';
			var extraHeight = self.grid.$topPanel.height() + $(footerPanelSel).height();
			var naturalHeight = self.grid.$canvas.height() + 1;
			if (opts != null) {
				if (opts.minHeight != null && (naturalHeight + extraHeight) < opts.minHeight) {
					naturalHeight = opts.minHeight - extraHeight - 2;
				}
				if (opts.maxHeight != null && (naturalHeight + extraHeight) > opts.maxHeight) {
					naturalHeight = opts.maxHeight;
				}
			}

			var newViewportHeight = naturalHeight + 3;
			if ((self.grid.$root.width() - self.grid.$canvas.width()) <= 1) {
				/** Take horizontal scrollbar height in account **/
				newViewportHeight += 17;
			}
			if (!self.scope.baseViewportHeight || self.scope.baseViewportHeight !== newViewportHeight) {
				// CTR-9762 : delay rendering to let the browser redraw the table and hide scrollbars
				setTimeout(function () {
					self.grid.$viewport.css('height', newViewportHeight + 'px');
					self.grid.$root.css('height', (newViewportHeight + extraHeight) + 'px');
					self.scope.baseViewportHeight = newViewportHeight;
					self.domUtilityService.RebuildGrid(self.scope, self.grid);
				}, 1);
			}
		};
		self.scope.catHashKeys = function () {
			var hash = '',
				idx;
			for (idx in self.scope.renderedRows) {
				hash += self.scope.renderedRows[idx].$$hashKey;
			}
			return hash;
		};
		self.scope.$watch('catHashKeys()', innerRecalcForData);
		self.scope.$watch(self.grid.config.data, recalcHeightForData);
	};
};

mainModule.directive('cosGrid', cosGridDirective);
