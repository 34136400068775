import * as loggerFactory from "loglevel";
import {mainModule} from "../../app";

function LocationChangeService($rootScope, $translate, $location, $filter, DialogService) {
    const log = loggerFactory.getLogger('LocationChangeService');

    const prepareScope = function ($scope) {
        $scope.locationChangeServiceScope = {};
    };

    this.alertUserAboutUnsavedChangesBeforeLeaving = function ($scope, modifiedFlagPropertyName) {
        prepareScope($scope);

        $scope.$on('$locationChangeStart', function (event, newUrl) {
            if (!$scope[modifiedFlagPropertyName] || $scope.locationChangeServiceScope.locationChangeConfirmed) {
                return;
            }

            log.debug('Modified flag is set to true. Asking the user a leave confirmation.');
            event.preventDefault();
            DialogService.confirm($translate('general.unsavedmodification.confirmleave')).result.then(function () {
                $scope.locationChangeServiceScope.locationChangeConfirmed = true;
                const redirectPath = newUrl.substring($location.absUrl().length - $location.url().length);
                $location.url(redirectPath);
            });
        });

        let leaveConfirmationMessage = null;
        $translate('general.unsavedmodification.confirmleave.text').then(function (result) {
            leaveConfirmationMessage = result;
        });
        window.addEventListener('beforeunload', function (e) {
            if (!$scope[modifiedFlagPropertyName] || $scope.locationChangeServiceScope.locationChangeConfirmed) {
                return;
            }

            log.debug('Modified flag is set to true. Asking the user a leave confirmation.');
            (e || window.event).returnValue = leaveConfirmationMessage;
            return leaveConfirmationMessage;
        });
    };

    /* History management */
    const history = [];
    $rootScope.$on('$locationChangeSuccess', function (evt, absNewUrl, absOldUrl) {
        const urlComponents = $filter('urlParser')(absOldUrl);
        urlComponents.url = absOldUrl;
        urlComponents.locationPath = urlComponents.hash ? urlComponents.hash.substring(1) : '';
        history.unshift(urlComponents);
    });

    /**
     * @returns {*} The previous location or undefined.
     * {
              protocol: 'http:',
              username: 'john',
              password: 's3cure',
              hostname: 'api.site.com',
              port: '3000',
              host: 'api.site.com:3000',
              pathname: '/users',
              search: '?page=2',
              hash: '#user-4711',
              url: '...',
              locationPath: 'user-4711'
            }
     */
    this.getPreviousLocation = function () {
        if (history.length > 0) {
            return history[0];
        }
    };
}

LocationChangeService.$inject = ['$rootScope', '$translate', '$location', '$filter', 'DialogService'];

export default LocationChangeService;
mainModule.service('LocationChangeService', LocationChangeService);
