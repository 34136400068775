import {IComponentOptions, IController, ILocationService, IPromise, IQService, IScope} from 'angular';
import * as template from './visage-license-domain.component.html';
import {mainModule} from '../../app';
import {VisageLicenseDomain, VisageLicenseDomainsApi} from 'vtom-api-typescript-client';
import DialogService from "../../framework/dialogs/dialog-service";

class Controller implements IController {

    domain: VisageLicenseDomain;
    id: string;
    files: {
        trackingLicense: null,
        analysisLicense: null,
    };

    constructor(
        private readonly NavbarService,
        private readonly $scope: IScope,
        private readonly $location: ILocationService,
        private readonly DialogService: DialogService,
        private readonly $translate,
        private readonly VisageLicenseDomainsApi: VisageLicenseDomainsApi,
        private readonly $q: IQService,
        private readonly toastr) {
    }

    save(): void {
        let savePromise;
        if (this.domain.id) {
            savePromise = this.VisageLicenseDomainsApi.updateVisageDomain({
                visageLicenseDomain: this.domain,
                id: this.domain.id
            });
        } else {
            savePromise = this.VisageLicenseDomainsApi.createVisageDomain({visageLicenseDomain: this.domain});
        }
        savePromise.then(() => this.$location.path('/admin/visage-license-domain'));
    }


    delete(): void {
        this.DialogService.confirm(this.$translate('general.confirmDeletion')).result.then(() =>
            this.$q.when(this.VisageLicenseDomainsApi.deleteDomain({id: this.domain.id}))
        ).then(() =>
            this.$location.path('/admin/visage-license-domain')
        );
    }

    $onInit(): void {
        this.NavbarService.setAppTitle(this.$scope, this.$translate('visage-license-domain'));
        if (this.id === 'new') {
            this.domain = {domainName: ''};
            return;
        }
        this.VisageLicenseDomainsApi
            .retrieveVisageDomain({id: parseInt(this.id)})
            .then(domain => this.domain = domain);
    }

    onLicenseChange(field: string) {
        this.readLicense(this.files[field]).then(license => {
            this.domain[field] = license;
            this.files[field] = null;
            const parsedDomain = this.parseDomainFromLicense(license);
            if (!parsedDomain) {
                this.toastr.error(this.$translate.instant('visage-license-domain.unparsable.license.error'));
                return;
            }
            if (!this.domain.domainName) {
                this.domain.domainName = parsedDomain;
            }
            if (!this.domain.domainName || !parsedDomain) {
                return;
            }
            if (!this.domain.domainName.endsWith(parsedDomain)) {
                this.toastr.error(this.$translate.instant('visage-license-domain.other.domain.error'));
            }
        });
    }

    private readLicense(files: FileList): IPromise<string | null> {
        if (!files || !files.length) {
            return null;
        }
        const file = files[0];
        if (file.size > 100000) {
            // Too large to be a license file
            return null;
        }
        return this.$q.when(file.text());
    }

    private parseDomainFromLicense(license: string | null) {
        if (!license) {
            return null;
        }
        const domainLine = license
            .split('\n')
            .find(line => line.startsWith('# Licensed URL'));
        if (!domainLine) {
            return null;
        }
        const splitDomain = domainLine.split(' ');
        return splitDomain[splitDomain.length - 1];
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        id: '@'
    }
};

mainModule.component('visageLicenseDomain', component);
