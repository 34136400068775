import {LoaderOptions, TranslationFilesLoader} from "../framework/i18n/translation-service";
import {mainModule} from "../app";

class VtomTranslationLoader implements TranslationFilesLoader {
    static readonly ANGULAR_NAME = 'VtomTranslationLoader';

    load(options: LoaderOptions): Promise<any> {
        return import('../i18n/' + options.key + '.json');
    };
}

VtomTranslationLoader.$inject = ['$log', '$q'];

mainModule
    .config(TranslationServiceProvider => TranslationServiceProvider.addLoader(VtomTranslationLoader.ANGULAR_NAME))
    .service(VtomTranslationLoader.ANGULAR_NAME, VtomTranslationLoader);
