import * as angular from 'angular';
import * as remotePagedGridLayoutTemplate from './remote-paged-grid-layout.html';
import {UrlContextService} from "../navigation/url-context-service";
import {CosGridServerPageable} from "./cos-grid-server-pageable";
import {CosGridSortBuilder} from "./cos-grid-sort";

angular.module('virtual-try-on-manager')
    .directive('remotePagedGridLayout', function ($location, $q, $http, $translate,
                                                  CosGridService, NavbarService, UrlContextService: UrlContextService) {
        return {
            restrict: 'E',
            replace: false,
            transclude: {
                /**
                 * Raw actions
                 */
                'actions': '?gridActions',
                /**
                 * Bulk actions are performed over 1 or more selected elements
                 */
                'bulkActions': '?gridBulkActions'
            },
            scope: {
                basePath: '=?',
                titleLabelCode: '=',
                newEntityLabelCode: '=',
                columns: '=',
                // Search function, will receive a query as 1st argument, and CosGridPageable as 2nd
                findPage: '=',
                newEntityEnabled: '=',
                query: '=?',
                enableRowDblClick: '=',
                exportToCsv: '=',
                multiSelect: '=',
                // A CosGridSort
                sortInfo: '=?',
                /** filterSelect must be an array of objects containing:
                 * - 'name' (ex: 'hidden', 'deleted')
                 * - 'initialValue' to init default value
                 * - 'values' : an array of value, can be an object containing 'value' and 'labelCode
                 * - 'isMultiple' describe if must be a multiple select ou not
                 * - 'placeholder' only for multiple style.
                 * - 'style' CSS object to custom div.
                 **/
                filterSelect: '=?',
                // Disable the search input in the NavBar. Default to false
                disableNavbarFilter: '=',
                // starting page size, 25 if unspecified
                startingPageSize: '=',
                // starting page number, 1 if unspecified
                startingPageNumber: '=',
                /**
                 * Optional function to build an entity url, called on row double click.
                 * Take a row entity as parameter, and should return the evaluated path as string.
                 */
                evaluateEntityUrl: '<',
                // Enable paging, default to true
                enablePaging: '=',
                disableUrlContext: '='
            },
            controller: function ($scope) {
                if ($scope.titleLabelCode) {
                    NavbarService.setAppTitle($scope, $translate($scope.titleLabelCode));
                }

                $scope.basePath = $scope.basePath || $location.path();

                if (angular.isUndefined($scope.sortInfo)) {
                    $scope.sortInfo = {fields: [], columns: [], directions: []};
                }

                $scope.criteria = {
                    pagingOptions: {
                        pageSizes: [15, 25, 35, 45, 55],
                        pageSize: $scope.startingPageSize || 25,
                        currentPage: $scope.startingPageNumber || 1
                    },
                    sortInfo: $scope.sortInfo,
                    query: $scope.query || {}
                };

                $scope.gridOptions = {
                    data: 'results',
                    totalServerItems: 'pageResult.totalElements',
                    pagingOptions: $scope.criteria.pagingOptions,
                    sortInfo: $scope.criteria.sortInfo,
                    columnDefs: $scope.columns,
                    remoteMultiSelect: $scope.multiSelect,
                    enablePaging: $scope.enablePaging !== false
                };

                let urlContext : RemoteGridUrlContext;
                if (!$scope.disableUrlContext) {
                    const defaultUrlContext : RemoteGridUrlContext = {query: {}, pageable: null};
                    urlContext = UrlContextService.bind($scope, defaultUrlContext, 'grid');
                    urlContext.query = angular.extend($scope.criteria.query, urlContext.query);
                    if (urlContext.pageable) {
                        // Restore previous pageable if found
                        $scope.gridOptions.sortInfo = CosGridSortBuilder.fromPageable(urlContext.pageable);
                        $scope.gridOptions.pagingOptions.currentPage = urlContext.pageable.pageNumber + 1;
                        $scope.gridOptions.pagingOptions.pageSize = urlContext.pageable.pageSize;
                    }
                }

                $scope.search = function (pageable: CosGridServerPageable) {
                    if (!pageable) {
                        // Called by the search button
                        $scope.gridOptions.pagingOptions.currentPage = 1;
                        pageable = CosGridService.buildServerPageable($scope.gridOptions);
                    }
                    if (urlContext) {
                        urlContext.pageable = pageable;
                    }
                    let pagePromise = $scope.findPage({
                        'query': $scope.criteria.query,
                        'pageable': pageable
                    });
                    $q.when(pagePromise.$promise || pagePromise)
                        .then(function (page) {
                            $scope.pageResult = page;
                            $scope.results = page.content;
                        });
                };

                if ($scope.enableRowDblClick !== false) {
                    $scope.evaluateEntityUrl = $scope.evaluateEntityUrl || function (entity) {
                        return $scope.basePath + '/' + entity.id;
                    };
                }
                if ($scope.filterSelect) {
                    let isDefinedAndNotNull = function (val) {
                        return angular.isDefined(val) && val !== null;
                    };

                    angular.forEach($scope.filterSelect, function (filter) {
                        $scope.criteria.query[filter.name] =
                            (isDefinedAndNotNull(filter.initialValue) && isDefinedAndNotNull(filter.initialValue.value)) ?
                                filter.initialValue.value : filter.initialValue;
                    });
                }
                $scope.search(CosGridService.buildServerPageable($scope.gridOptions));

                if ($scope.criteria.query.queryText) {
                    NavbarService.setAppFilter($scope.criteria.query.queryText);
                }
                if (!$scope.disableNavbarFilter) {
                    NavbarService.watchAppFilter($scope, function (appFilter) {
                        $scope.criteria.query.queryText = appFilter;
                    });
                }

                $scope.$watch('criteria.query', function (newVal, oldVal) {
                    if (angular.equals(newVal, oldVal) || !oldVal) {
                        return;
                    }
                    $scope.search();
                }, true);


                $scope.toCsv = function () {
                    let csvConfiguration = CosGridService.buildCsvConfiguration($scope.gridOptions);
                    $scope.exportToCsv({
                        csvConfiguration: csvConfiguration,
                        query: $scope.criteria.query
                    });
                };

                this.watchSelectedItems = function (callback) {
                    return $scope.$watch('gridOptions.selectedItems', callback, true);
                };
            },
            template: remotePagedGridLayoutTemplate.default
        };
    });

type RemoteGridUrlContext = {
    query,
    pageable: CosGridServerPageable
}
