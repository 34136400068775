/**
 * Translate a boolean value into another printable value
 */
import {mainModule} from "../../app";

function booleanTranslateFilter($translate) {
    /**
     * @param value {boolean}
     * @param okLabelCode {String}
     * @param nokLabelCode {String}
     */
    return function (value, okLabelCode, nokLabelCode) {
        return $translate.instant(value === true ? okLabelCode : nokLabelCode);
    };
}

booleanTranslateFilter.$inject = ['$translate'];

mainModule.filter('booleanTranslate', booleanTranslateFilter);
