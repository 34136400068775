function buildGridActionComponent(controller) {
    return {
        transclude: true,
        require: {
            remotePagedGridLayout: '^^remotePagedGridLayout'
        },
        bindings: {
            'disabled': '<ngDisabled',
            'action': '<ngClick'
        },
        controller: controller,
        template: '<button type="button" class="btn btn-default" ng-click="$ctrl.wrappedAction()" ng-disabled="$ctrl.disabled" ng-transclude></button>'
    };
}

import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .component('gridBulkAction', buildGridActionComponent(function () {
        let self = this;
        let selectedItems = [];

        let onSelectedItemsChange = function (newSelectedItems) {
            selectedItems = newSelectedItems;
            self.disabled = !newSelectedItems || newSelectedItems.length === 0;
        };

        this.wrappedAction = function () {
            if (self.action) {
                self.action(selectedItems);
            }
        };

        let watcher;
        this.$onInit = function () {
            watcher = this.remotePagedGridLayout.watchSelectedItems(onSelectedItemsChange);
        };
        this.$onDestroy = function () {
            watcher();
        };
    }));

angular.module('virtual-try-on-manager')
    .component('gridAction', buildGridActionComponent(function () {
        let  self = this;
        this.wrappedAction = function () {
            if (self.action) {
                self.action();
            }
        };
    }));
