import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('BoothCtrl', function ($scope, $routeParams, $location, $window, $q,
                                       BoothResource, BoothSettingResource) {

        $scope.isNew = $routeParams.id === 'new';

        if ($scope.isNew) {
            $scope.booth = BoothResource._default();
            $scope.boothSettings = BoothSettingResource.query();
            $q.all([$scope.booth.$promise, $scope.boothSettings.$promise])
                .then(function () {
                    if($scope.boothSettings.length > 0){
                        $scope.booth.setting = $scope.boothSettings[0];
                    }
                });
        } else {
            $scope.booth = BoothResource.get(parseInt($routeParams.id));
            $scope.booth
                .$promise
                .then(function (booth) {
                    $scope.boothSettings = BoothSettingResource.findAll({query: {customer: booth.setting.customer}});
                });
        }


        $scope.save = function () {
            BoothResource.save($scope.booth)
                .$promise
                .then(function (booth) {
                    if ($scope.isNew) {
                        $location.path('/booth/booth/' + booth.id);
                    }
                });
        };

    });
