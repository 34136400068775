/**
 * http
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as angular from 'angular';
import {Container} from '../container';
import {ApiResource} from '../resource';

/* tslint:disable:no-unused-variable member-ordering */

export class DefaultResource {
    private readonly basePath = 'api';

    static $inject: string[] = ['$http', 'transformResponseAsFile', '$httpParamSerializer'];

    constructor(private $http: ng.IHttpService, private transformResponseAsFile:any, private $httpParamSerializer?: (d: any) => any) {

    }

    /**
     * 
     * @param id 
     */
    public addAllProducts (id: number, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/accesses-key-chains/{id}/products/all'
            .replace('{' + 'id' + '}', String(id));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling addAllProducts.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'PUT',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'addAllProducts'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public addFile (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/job/addFile';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'addFile'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public commonFile (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/common/**';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'commonFile'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public commonFile_1 (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/common/**';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'HEAD',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'commonFile_1'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     * @param index 
     */
    public get360Picture (criterionKey: string, criterionValue: string, index: number, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/360/{criterionKey}/{criterionValue}/{index}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue))
            .replace('{' + 'index' + '}', String(index));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling get360Picture.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling get360Picture.');
        }
        // verify required parameter 'index' is not null or undefined
        if (index === null || index === undefined) {
            throw new Error('Required parameter index was null or undefined when calling get360Picture.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'get360Picture'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param externalId 
     */
    public getBooth (externalId: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/booth/{externalId}'
            .replace('{' + 'externalId' + '}', String(externalId));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'externalId' is not null or undefined
        if (externalId === null || externalId === undefined) {
            throw new Error('Required parameter externalId was null or undefined when calling getBooth.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getBooth'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public getBrands (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/brands';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getBrands'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public getFrameShapes (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/frame-shapes';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getFrameShapes'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public getGenders (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/genders';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getGenders'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     */
    public getProduct (criterionKey: string, criterionValue: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/product/{criterionKey}/{criterionValue}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProduct.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProduct.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProduct'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     * @param colorModeFile 
     */
    public getProductColorMode (criterionKey: string, criterionValue: string, colorModeFile: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/color-mode/{criterionKey}/{criterionValue}/{colorModeFile}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue))
            .replace('{' + 'colorModeFile' + '}', String(colorModeFile));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProductColorMode.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProductColorMode.');
        }
        // verify required parameter 'colorModeFile' is not null or undefined
        if (colorModeFile === null || colorModeFile === undefined) {
            throw new Error('Required parameter colorModeFile was null or undefined when calling getProductColorMode.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductColorMode'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     */
    public getProductColors (criterionKey: string, criterionValue: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/product-colors/{criterionKey}/{criterionValue}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProductColors.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProductColors.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductColors'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     * @param faceProfileThreeQuarterFile 
     */
    public getProductFaceProfileThreeQuarter (criterionKey: string, criterionValue: string, faceProfileThreeQuarterFile: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/face-profile-three-quarter/{criterionKey}/{criterionValue}/{faceProfileThreeQuarterFile}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue))
            .replace('{' + 'faceProfileThreeQuarterFile' + '}', String(faceProfileThreeQuarterFile));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProductFaceProfileThreeQuarter.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProductFaceProfileThreeQuarter.');
        }
        // verify required parameter 'faceProfileThreeQuarterFile' is not null or undefined
        if (faceProfileThreeQuarterFile === null || faceProfileThreeQuarterFile === undefined) {
            throw new Error('Required parameter faceProfileThreeQuarterFile was null or undefined when calling getProductFaceProfileThreeQuarter.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductFaceProfileThreeQuarter'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     * @param faceQuarterQuarterFile 
     */
    public getProductFaceQuarterQuarter (criterionKey: string, criterionValue: string, faceQuarterQuarterFile: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/face-quarter-quarter/{criterionKey}/{criterionValue}/{faceQuarterQuarterFile}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue))
            .replace('{' + 'faceQuarterQuarterFile' + '}', String(faceQuarterQuarterFile));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProductFaceQuarterQuarter.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProductFaceQuarterQuarter.');
        }
        // verify required parameter 'faceQuarterQuarterFile' is not null or undefined
        if (faceQuarterQuarterFile === null || faceQuarterQuarterFile === undefined) {
            throw new Error('Required parameter faceQuarterQuarterFile was null or undefined when calling getProductFaceQuarterQuarter.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductFaceQuarterQuarter'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     */
    public getProductFrameShapes (criterionKey: string, criterionValue: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/product-frame-shapes/{criterionKey}/{criterionValue}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProductFrameShapes.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProductFrameShapes.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductFrameShapes'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     * @param printType 
     */
    public getProductPrint (criterionKey: string, criterionValue: string, printType: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/print/{criterionKey}/{criterionValue}/{printType}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue))
            .replace('{' + 'printType' + '}', String(printType));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProductPrint.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProductPrint.');
        }
        // verify required parameter 'printType' is not null or undefined
        if (printType === null || printType === undefined) {
            throw new Error('Required parameter printType was null or undefined when calling getProductPrint.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductPrint'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     * @param thumbType 
     */
    public getProductThumb (criterionKey: string, criterionValue: string, thumbType: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/thumb/{criterionKey}/{criterionValue}/{thumbType}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue))
            .replace('{' + 'thumbType' + '}', String(thumbType));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProductThumb.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProductThumb.');
        }
        // verify required parameter 'thumbType' is not null or undefined
        if (thumbType === null || thumbType === undefined) {
            throw new Error('Required parameter thumbType was null or undefined when calling getProductThumb.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductThumb'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     */
    public getProductTop (criterionKey: string, criterionValue: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/top/{criterionKey}/{criterionValue}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getProductTop.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getProductTop.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductTop'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public getProductionPicturesArchive (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/product/archive';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductionPicturesArchive'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public getProductionPicturesArchive_2 (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/job/downloadFile';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProductionPicturesArchive_2'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public getProducts (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/product';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProducts'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param id 
     */
    public getProducts_3 (id: number, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/products/{id}'
            .replace('{' + 'id' + '}', String(id));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getProducts_3.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getProducts_3'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public getSolarTypes (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/solar-types';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getSolarTypes'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param criterionKey 
     * @param criterionValue 
     * @param index 
     */
    public getThreeSixtyPicture (criterionKey: string, criterionValue: string, index: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/three-sixty-picture/{criterionKey}/{criterionValue}/{index}'
            .replace('{' + 'criterionKey' + '}', String(criterionKey))
            .replace('{' + 'criterionValue' + '}', String(criterionValue))
            .replace('{' + 'index' + '}', String(index));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'criterionKey' is not null or undefined
        if (criterionKey === null || criterionKey === undefined) {
            throw new Error('Required parameter criterionKey was null or undefined when calling getThreeSixtyPicture.');
        }
        // verify required parameter 'criterionValue' is not null or undefined
        if (criterionValue === null || criterionValue === undefined) {
            throw new Error('Required parameter criterionValue was null or undefined when calling getThreeSixtyPicture.');
        }
        // verify required parameter 'index' is not null or undefined
        if (index === null || index === undefined) {
            throw new Error('Required parameter index was null or undefined when calling getThreeSixtyPicture.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getThreeSixtyPicture'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param productFileId 
     */
    public getTryOnProduct (productFileId: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/product/{productFileId}'
            .replace('{' + 'productFileId' + '}', String(productFileId));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'productFileId' is not null or undefined
        if (productFileId === null || productFileId === undefined) {
            throw new Error('Required parameter productFileId was null or undefined when calling getTryOnProduct.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'getTryOnProduct'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param externalId 
     */
    public retrieveTryOnShot (externalId: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/public/end-user/shot/{externalId}'
            .replace('{' + 'externalId' + '}', String(externalId));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'externalId' is not null or undefined
        if (externalId === null || externalId === undefined) {
            throw new Error('Required parameter externalId was null or undefined when calling retrieveTryOnShot.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'retrieveTryOnShot'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param command 
     */
    public sendTryOnShotByEmail (command: any, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/public/end-user/shot/send-by-email';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'command' is not null or undefined
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling sendTryOnShotByEmail.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: command,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'sendTryOnShotByEmail'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param productFileId 
     */
    public specificFile (productFileId: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/specific/{productFileId}/**'
            .replace('{' + 'productFileId' + '}', String(productFileId));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'productFileId' is not null or undefined
        if (productFileId === null || productFileId === undefined) {
            throw new Error('Required parameter productFileId was null or undefined when calling specificFile.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'GET',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'specificFile'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param productFileId 
     */
    public specificFile_4 (productFileId: string, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/try-on/specific/{productFileId}/**'
            .replace('{' + 'productFileId' + '}', String(productFileId));

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'productFileId' is not null or undefined
        if (productFileId === null || productFileId === undefined) {
            throw new Error('Required parameter productFileId was null or undefined when calling specificFile_4.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'HEAD',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'specificFile_4'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     */
    public storeTryOnShot (extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/end-user/shot/store';

        let queryParameters: any = {};
        let headerParams: any = {};
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
                                                params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'storeTryOnShot'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
}
