import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('CustomerCtrl', function($scope, $routeParams, $location, $window, NavbarService,
                                         CustomerResource, CustomerGroupResource, ExportScenarioResource, UserContextService){
        $scope.isNew = $routeParams.id === 'new';

        $scope.customerGroups = CustomerGroupResource.query();
        $scope.exportScenarios = ExportScenarioResource.query();

        if($scope.isNew){
            $scope.customer = CustomerResource._default();
        } else{
            $scope.customer = CustomerResource.get(parseInt($routeParams.id));
        }

        UserContextService.getContext().then(userContext => $scope.isAdmin = userContext.hasRole('ADMIN'));

        NavbarService.setAppTitleFromObjectName('customer', $scope, 'customer.name');

        $scope.save = function(){
            CustomerResource.save($scope.customer);
        };


    });
