import * as angular from 'angular';
import {IComponentOptions, IController, IPromise, IQService} from 'angular';
import * as template from './registered-domain-list.component.html';
import {mainModule} from '../app';
import {CosGridSort} from '../layout/cos-grid-sort';
import {VtoDomainsApi, VisageLicenseDomainsApi, VisageLicenseDomainPage, DomainPage} from 'vtom-api-typescript-client';
import {ApiPageableHelper} from '../api/api-pageable-helper';

class Controller implements IController {
    filter = {
        queryText: ''
    };
    columns = [
        {field: 'name', labelCode: 'domain', linkBaseHref: '#/admin/registered-domain'},
        {
            field: 'customerGroupName',
            labelCode: 'customers.group',
            linkBaseHrefId: 'customerGroupId',
            linkBaseHref: '#/admin/customer-group',
        },
        {
            field: 'visageDomainLicense.domainName',
            labelCode: 'visage-license-domain',
            linkBaseHrefId: 'visageDomainLicense.id',
            linkBaseHref: '#/admin/visage-license-domain',
            sortable: false,
        },
    ];
    sortInfo: CosGridSort = {fields: ['name'], directions: ['asc']};
    filterSelect = [];

    customerGroupsById;
    initPromise: IPromise<any>;

    constructor(
        private readonly $routeParams,
        private readonly VtoDomainsApi: VtoDomainsApi,
        private readonly CustomerGroupResource,
        private readonly VisageLicenseDomainsApi: VisageLicenseDomainsApi,
        private readonly $q: IQService) {
    }

    findPage = (params) => {
        const query = angular.extend({}, params.query);
        ApiPageableHelper.addApiPageable(params.pageable, query);
        query.sortProperties = query.sortProperties.map(property => property == 'customerGroupName' ? 'customerGroup.name' : property);

        return this.VtoDomainsApi.list(query).then(page => {
            return this.$q.all([
                page,
                this.VisageLicenseDomainsApi.listVisageDomains({
                    matchingDomains: page.content.map(domain => domain.name),
                    pageSize: params.pageable.pageSize
                }),
                this.initPromise]);
        }).then(results => {
            const registeredDomainPage = results[0];
            registeredDomainPage.content.forEach(domain => {
                domain.customerGroupName = this.customerGroupsById[domain.customerGroupId]?.name;
            });
            this.fillVisageLicenseDomains(registeredDomainPage, results[1]);
            return registeredDomainPage;
        });
    };

    private fillVisageLicenseDomains(registeredDomainPage: DomainPage, visageLicenseDomainPage: VisageLicenseDomainPage) {
        registeredDomainPage.content.forEach(domain => {
            domain.visageDomainLicense = visageLicenseDomainPage
                .content
                .find(license => domain.name.endsWith(license.domainName));
        });
    }

    $onInit(): void {
        this.initPromise = this.CustomerGroupResource.query().$promise.then(groups =>
            this.customerGroupsById = groups.reduce((map, group) => {
                map[group.id] = group;
                return map;
            }, {})
        );
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('registeredDomainList', component);
