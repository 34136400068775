import * as angular from 'angular';
import {EnumService} from "../misc/enum-service";
import {RegisteredDomainType, RegisteredDomainTypeUtil} from "./registered-domain-type";
import {RegisteredDomainResource} from "../../../http/target/classes/openapi/code/api/RegisteredDomainResource";

angular.module('virtual-try-on-manager')
    .controller('RegisteredDomainCtrl', function ($scope, $routeParams, $location, $window, $translate,
                                                  NavbarService, DialogService, RegisteredDomainResource: RegisteredDomainResource, CustomerGroupResource,
                                                  UserRoleResource, EnumService: EnumService) {
        $scope.isNew = $routeParams.id === 'new';
        $scope.customerGroups = CustomerGroupResource.query();

        if ($scope.isNew) {
            $scope.registeredDomain = {
                roles: []
            };
        } else {
            $scope.registeredDomain = RegisteredDomainResource.findOne({identifier: parseInt($routeParams.id)});
        }

        NavbarService.setAppTitleFromObjectName('registered.domain', $scope, 'registeredDomain.domain');

        $scope.save = function () {
            $scope.registeredDomain = RegisteredDomainResource.save({registeredDomain: $scope.registeredDomain});
        };

        $scope.delete = function () {
            DialogService.confirm($translate('general.confirmDeletion')).result.then(function () {
                RegisteredDomainResource._delete({identifier: $scope.registeredDomain.id})
                    .$promise.then(function () {
                        $window.history.back();
                    });
            });
        };

        UserRoleResource.getDomainRoles({}).$promise.then(async roles => {
            $scope.roles = await EnumService.translate(roles);

        });

        $scope.hasRole = role => {
            return EnumService.hasTranslatableEnum(role, $scope.registeredDomain.roles);
        };

        $scope.toggleRole = role => {
            EnumService.toggleTranslatableEnum(role, $scope.registeredDomain.roles);
        };

        $scope.registeredDomainTypes = [];
        for (const type in RegisteredDomainType) {
            $scope.registeredDomainTypes.push(type);
        }

        $scope.getTypeLabelCode = RegisteredDomainTypeUtil.toLabelCode;

    });
