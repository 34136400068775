import {IHttpInterceptor, IHttpPromiseCallbackArg, IHttpProvider, IPromise} from "angular";
import {HttpNotificationOptions} from "./notification.options";
import {mainModule} from "../../app";

/**
 * Standard option to use in resource save methods calls in order to avoid the save notification.
 * Sample usage:
 * import {NO_SAVE_NOTIFICATION_OPTION} from "path/to/save-notification.configurator";
 * MyResource.save(myEntity, NO_SAVE_NOTIFICATION_OPTION);
 */
export const NO_SAVE_NOTIFICATION_OPTION: {readonly options: {readonly notification: {readonly onSave: boolean}}} =
	{options: {notification: {onSave: false}}};

class SaveNotificationHttpInterceptor implements IHttpInterceptor {

	private static $injector;
	private static toastr;

	public static Factory($injector, toastr): IHttpInterceptor {
		SaveNotificationHttpInterceptor.$injector = $injector;
		SaveNotificationHttpInterceptor.toastr = toastr;
		return new SaveNotificationHttpInterceptor();
	}

	response<T>(response: IHttpPromiseCallbackArg<T> & { config: { options?: any } }): IPromise<IHttpPromiseCallbackArg<T>> | IHttpPromiseCallbackArg<T> {
		const options: { operationName?: string, notification: HttpNotificationOptions } = response.config.options;
		if (!options) {
			return response;
		}
		const notificationOptions: HttpNotificationOptions = options.notification;
		const onSave = !notificationOptions || notificationOptions.onSave !== false;
		const operationName: string = options.operationName;
		if (onSave && operationName && operationName.indexOf('save') !== -1) {
			SaveNotificationHttpInterceptor.$injector.get<(code: string) => IPromise<string>>('$translate')
			('general.save.success').then(message => SaveNotificationHttpInterceptor.toastr.success(message));
		}
		return response;
	}
}

export function saveNotificationConfigurator($httpProvider: IHttpProvider) {
	$httpProvider.interceptors.unshift(SaveNotificationHttpInterceptor.Factory);
}

mainModule.config(saveNotificationConfigurator);
