import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ScenarioRejectionDialogCtrl', function($log, $scope, $modalInstance, scenarioValidation){
        $log = $log.getInstance('ScenarioRejectionDialogCtrl');
        $log.debug('Current scenarioValidation');
        $log.debug(scenarioValidation);
        $scope.scenarioValidation = scenarioValidation;

        $scope.no = function(){
            $modalInstance.dismiss(false);
        };

        $scope.yes = function () {
            $modalInstance.close(true);
            return true;
        };
    });
