import * as angular from 'angular';
import {IComponentOptions, IController} from 'angular';
import * as template from './visage-licenses-list.component.html';
import {mainModule} from "../../app";
import {CosGridSort} from "../../layout/cos-grid-sort";
import {VisageLicenseDomainsApi} from "vtom-api-typescript-client";
import {ApiPageableHelper} from "../../api/api-pageable-helper";

class Controller implements IController {
    filter = {
        queryText: ''
    };
    columns = [
        {field: 'domainName', labelCode: 'domain', linkBaseHref: '#/admin/visage-license-domain'},
        {
            field: 'analysisLicense',
            labelCode: 'visage-license-domain.analysis',
            cellTemplate: '<div class="text-center" style="margin: 1px 2px 1px 1px;">' +
                '<i ng-if="row.entity.analysisLicense" class="fa fa-check text-success"></i>' +
                '</div>',
        },
        {
            field: 'trackingLicense',
            labelCode: 'visage-license-domain.tracking',
            cellTemplate: '<div class="text-center" style="margin: 1px 2px 1px 1px;">' +
                '<i ng-if="row.entity.trackingLicense" class="fa fa-check text-success"></i>' +
                '</div>',
        },
    ];
    sortInfo: CosGridSort = {fields: ['domainName'], directions: ['asc']};
    filterSelect = [];

    constructor(
        private readonly $routeParams,
        private readonly VisageLicenseDomainsApi: VisageLicenseDomainsApi) {
    }

    findPage = (params) => {
        const query = angular.extend({}, params.query);
        ApiPageableHelper.addApiPageable(params.pageable, query);
        return this.VisageLicenseDomainsApi.listVisageDomains(query);
    };

    $onInit(): void {}
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('visageLicenseDomainList', component);
