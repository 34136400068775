import * as loggerFactory from "loglevel";
import {mainModule} from "../../app";

function CacheEvicter($rootScope, $cacheFactory, AuthenticationConfig) {
    const log = loggerFactory.getLogger('CacheEvicter');

    $rootScope.$on(AuthenticationConfig.DEAUTHENTICATED, function () {
        log.debug('Evicting http cache');
        $cacheFactory.get('$http').removeAll();
    });
}

CacheEvicter.$inject = ['$rootScope', '$cacheFactory', 'AuthenticationConfig'];

export default CacheEvicter;
mainModule.service('CacheEvicter', CacheEvicter);
