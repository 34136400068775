import * as angular from 'angular';
import {SoftwareReleasesApi, SoftwareType} from "vtom-api-typescript-client";
import {SoftwareReleaseService} from "../shooting/software-release.service";
import {IScope} from "angular";
import {UserContextService} from "../user/user-context-service";

angular.module('virtual-try-on-manager')
    .controller('WelcomeCtrl', function ($scope: IScope, UserContextService: UserContextService, ApplicationContextService, SoftwareReleaseService: SoftwareReleaseService) {
        const init = async function() {
            UserContextService.getContext().then(userContext => {
                $scope.userContext = userContext;
                if (userContext.hasRole('ADMIN') || userContext.hasRole('INTEGRATOR')) {
                    ApplicationContextService.getApplication().$promise.then(
                        function (application) {
                            $scope.appVersion = application.appVersion;
                            $scope.apiVersion = application.apiVersion;
                        }
                    );
                }
            });

            SoftwareReleaseService.getLatestRelease(SoftwareType.SHOOTING).then(release => {
                if (release) {
                    $scope.shootingVersion = release.version;
                }
            });
        }
        init();



    });
