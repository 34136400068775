import * as angular from "angular";
import {mainModule} from "../../app";

function AngularI18NLoader($q, tmhDynamicLocaleCache, tmhDynamicLocale, TranslationService) {

    let inject = (lang) => tmhDynamicLocaleCache.put(lang, angular.injector(['ngLocale']).get('$locale'));

    $q.all([
        import(/* webpackChunkName: "angular-locale_de" */ 'angular-i18n/angular-locale_de')
            .then(() => inject('de')),
        import(/* webpackChunkName: "angular-locale_en" */ 'angular-i18n/angular-locale_en')
            .then(() => inject('en')),
        import(/* webpackChunkName: "angular-locale_es" */ 'angular-i18n/angular-locale_es')
            .then(() => inject('es')),
        import(/* webpackChunkName: "angular-locale_fr" */ 'angular-i18n/angular-locale_fr')
            .then(() => inject('fr')),
        import(/* webpackChunkName: "angular-locale_pl" */ 'angular-i18n/angular-locale_pl')
            .then(() => inject('pl')),
        import(/* webpackChunkName: "angular-locale_pt" */ 'angular-i18n/angular-locale_pt')
            .then(() => inject('pt'))
    ]).then(() => tmhDynamicLocale.set(TranslationService.getLanguage()));
}

AngularI18NLoader.$inject = ['$q', 'tmhDynamicLocaleCache', 'tmhDynamicLocale', 'TranslationService'];

export default AngularI18NLoader;
mainModule.run(AngularI18NLoader);
