import * as angular from 'angular';
import * as boothSettingLayoutTemplate from './booth-setting-layout.html'

angular.module('virtual-try-on-manager')
    .directive('boothSettingLayout', function ($routeParams) {
        return {
            restrict: 'E',
            replace: false,
            transclude: true,
            scope: {
                subLocation: '='
            },
            controller: function ($scope) {
                $scope.isNew = $routeParams.id === 'new';
                $scope.basePath = '#/' + $routeParams.context + '/booth-setting/' + $routeParams.id;
            },
            template: boothSettingLayoutTemplate.default
        };
    });
