import * as angular from 'angular';
import * as jsSHA from 'jssha';
import * as scenarioRejectionDialogTemplate from './scenario-rejection-dialog.html';

angular.module('virtual-try-on-manager')
    .controller('JobValidationCtrl', function ($scope, $routeParams, $q, $translate, $log, $location, $window, $httpParamSerializer,
                                               NavbarService, DialogService, UserContextService,
                                               JobResource, CustomerResource, ScenarioResource, ExportScenarioResource, ScenarioValidationResource) {
        let isAdmin = false;
        UserContextService.getContext().then(userContext => isAdmin = userContext.hasRole('ADMIN'));
        $log = $log.getInstance('JobValidationCtrl');

        $scope.job = JobResource.get(parseInt($routeParams.id));

        NavbarService.setAppTitle(
            $scope,
            $q.all({
                job: $scope.job.$promise,
                numberOfProductsShot: JobResource.countProductsToShoot({job: {id: parseInt($routeParams.id)}}).$promise
            }).then(function(res) {
                    return $translate('product.waiting.validation.forjob',
                        {
                            jobName: res.job.name,
                            totalProducts: res.job.requestedRefNb,
                            shootedProducts: res.job.requestedRefNb - res.numberOfProductsShot.data
                        }).then(initDownloadAllImages);
                }
            )
        );

        let shaObj = new jsSHA('SHA-512', 'TEXT');
        shaObj.update(location.hostname);
        $scope.ctx = {
            /* The focused product */
            product: null,
            scenarios: [],
            tryOnToken: shaObj.getHash('HEX')
        };



        $scope.selectScenario = scenario => {
            $scope.ctx.product = scenario.product;
            $scope.ctx.scenarios = [scenario];
        };

        /**
         *  Triggered on product action modification
         */
        $scope.onScenarioActionChange = function (scenarioValidation) {
            $log.debug('onScenarioActionChange');
            $log.debug(scenarioValidation);
            if (scenarioValidation.action !== 'REJECT') {
                return;
            }

            DialogService.openModal({
                template: scenarioRejectionDialogTemplate.default,
                controller: 'ScenarioRejectionDialogCtrl',
                resolve: {
                    scenarioValidation: function () {
                        return scenarioValidation;
                    }
                }
            }).result
                .catch(function () {
                    scenarioValidation.action = null;
                });
        };

        /**
         * @param actions
         * @returns {*} Products matching the given action
         */
        $scope.getScenariosForActions = function (actions) {
            return $scope.scenarioValidations.filter(scenarioValidation => actions.indexOf(scenarioValidation.action) !== -1);
        };

        let doSaveAll = function (productsToValidate) {
            ScenarioValidationResource.validateAll({productValidations: productsToValidate}).$promise.then(loadScenarios);
        };


        let downloadAllImages = () => {
            let params = {
                'jobId': $scope.job.id,
                'productStatus': 'Setup'
            };
            $translate('confirm.download.all.images').then(
                message => DialogService.confirm(message).result.then(
                    () => $window.open('api/product/archive?' + $httpParamSerializer(params)))
            );
        };

        let initDownloadAllImages = appTitle => {
            if (!isAdmin) {
                return appTitle;
            }
            $scope.exportScenario = ExportScenarioResource.findByCustomer({customer: $scope.job.customer})
                .$promise.then(res => {
                    if (angular.isUndefined(res.id)) {
                        return;
                    }
                    $scope.downloadAllImages = downloadAllImages;
                });
            return appTitle;
        };

        $scope.save = function () {
            let productsToValidate = $scope.getScenariosForActions(['VALIDATE']);
            let productsToReject = $scope.getScenariosForActions(['REJECT']);
            DialogService.confirm($translate('product.production.validation.save.confirm', {
                toValidateCount: productsToValidate.length,
                toRejectCount: productsToReject.length
            })).result.then(function () {
                doSaveAll($scope.getScenariosForActions(['VALIDATE', 'REJECT']));
            });
        };

        let loadScenarios = () => {
            $scope.scenarioValidations = [];

            ScenarioResource.findAll({
                filter: {
                    jobIds: [$routeParams.id],
                    status: ['Setup'],
                    entityGraphName: 'scenario.with.customer.and.product'
                }
            }).$promise.then(scenarios => {
                if (scenarios.length == 0) {
                    $location.path('/validation');
                    return;
                }
                if ($scope.ctx.scenarios.length == 0
                    || scenarios.map(scenario => scenario.id).indexOf($scope.ctx.scenarios[0].id) == -1) {
                    $scope.selectScenario(scenarios[0]);
                }

                scenarios.forEach(scenario => {
                    let scenarioValidation = {
                        scenario: scenario,
                        action: null
                    };
                    $scope.scenarioValidations.push(scenarioValidation);
                });
            });
        };

        loadScenarios();
    });
