import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('ProcessesHistoryUnitCtrl', function ($scope, $routeParams, $location, $window,
                                                  NavbarService, InterpolableMessageService,
                                                  ProcessHistoryResource, ProcessHistoryUnitResource, UserResource) {
        let processHistoryId = $routeParams.processHistoryId;
        let unitId = $routeParams.id;


        ProcessHistoryResource.get(processHistoryId)
            .$promise
            .then(function (result) {
                $scope.processHistory = result;
                $scope.processHistory.creator = UserResource.get(result.creator.id);
            });
        ProcessHistoryUnitResource.get(unitId)
            .$promise
            .then(function (result) {
                $scope.unit = result;
            });

        NavbarService.setAppTitleFromObjectName('process', $scope, 'unit.value');
    });
