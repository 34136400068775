import {IHttpService, ILocationService, IPromise, IScope} from "angular";
import * as loggerFactory from "loglevel";
import AuthenticationConfig from "./authentication-config";
import HttpConfig from "../http/http-config";
import {mainModule} from "../../app";

class AuthenticationManager {

    public static readonly ANGULAR_NAME = 'AuthenticationManager';
    public static readonly $inject: string[] = ['$rootScope', '$location', '$http'];

    private readonly log: Log = loggerFactory.getLogger(AuthenticationManager.ANGULAR_NAME);

    private isAuthenticated: boolean = false;

    constructor(private readonly $rootScope: IScope,
                private readonly $location: ILocationService,
                private readonly $http: IHttpService) {
        $rootScope.$on(AuthenticationConfig.AUTHENTICATION_SUCCESS, () => this.isAuthenticated = true);
        /**
         * HTTP 401 received
         */
        $rootScope.$on(HttpConfig.HTTP_UNAUTHORIZED_EVENT, () => this.deauthenticate());
    }

    /**
     * Destroy the current authentication if there is any
     */
    public deauthenticate(): IPromise<void> {
        return this.$http
            .delete(AuthenticationConfig.AUTHENTICATION_URL)
            .catch(() => {
            })
            .then(() => {
                this.afterDeauthentication();
            });
    }

    private afterDeauthentication(): void {
        if (this.isAuthenticated) {
            this.log.debug('Firing deauthenticated event');
            this.isAuthenticated = false;
            this.$rootScope.$emit(AuthenticationConfig.DEAUTHENTICATED);
        }
        if (this.$location.path() !== '/login') {
            this.log.info('Deauthenticated. Redirecting from ' + this.$location.path() + ' to login page.');
            this.$location.path('/login');
        }
    }

}

export default AuthenticationManager;
mainModule.service(AuthenticationManager.ANGULAR_NAME, AuthenticationManager);
