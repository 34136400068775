import {IPromise, IQService} from "angular";
import {mainModule} from "../../app";

export default class GridConfigurationResource {
	constructor(private readonly $q: IQService) {
	}

	public findByViewNameAndGridName(): { $promise: IPromise<any> } {
		return {$promise: this.$q.when()};
	}

	public save(): { $promise: IPromise<any> } {
		return {$promise: this.$q.when()};
	}

	public _delete(): { $promise: IPromise<any> } {
		return {$promise: this.$q.when()};
	}
}

mainModule.service('GridConfigurationResource', GridConfigurationResource);
