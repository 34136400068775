import * as angular from 'angular';
import * as tryOnBoothTemplate from './try-on-booth-template.html';
import {ProductsApi, ProductType, Product} from "vtom-api-typescript-client";
import {IPromise} from "angular";

angular.module('virtual-try-on-manager')
    .directive('tryOnBooth', function ($q, ScenarioResource, DialogService, $translate, ProductsApi: ProductsApi, $log) {
        const toApiScenarios = function(scenarios:Array<any>) {
            if (!scenarios) {
                return scenarios;
            }
            return scenarios.map(internalScenario => {
                let apiScenario = angular.copy(internalScenario);
                apiScenario.type = internalScenario.type.value;
                apiScenario.productId = internalScenario.product.id;
                apiScenario.solar = internalScenario.solar == true;
                apiScenario.jobId = internalScenario.job.id;
                return apiScenario;
            })
        }

        const toApiProduct = function(product:any) {
            if (!product) {
                return product;
            }
            let apiProduct = angular.copy(product);
            apiProduct.brandId = product.brand.id;
            return apiProduct;
        }

        const fetchAdditionBaseProduct = function(addition): IPromise<Product> {
            if (addition.type != ProductType.GLASSESADDITION) {
                return $q.resolve();
            }
            return ProductsApi
                .listProducts({compatibleAdditionIds: [addition.id]})
                .then(page => {
                    const content = page.content;
                    if (content.length > 0) {
                        return content[0];
                    }
                    DialogService.error($translate('tryon.addition.without.base.product'));
                    throw 'Addition without base base product.'
                });
        }

        const contextEquals = function(ctx1: Context, ctx2: Context) {
            if (ctx1.product?.id !== ctx2.product?.id) {
                return false;
            }
            if (ctx1.scenarios?.length != ctx2.scenarios.length) {
                return false;
            }
            return (ctx1.scenarios ?? []).every((scenario, index) => scenario.id == ctx2.scenarios[index].id);
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                ctx: '='
            },
            controller: function ($scope) {
                require('../tryon/try-on-booth.html');
                let tryOnFrame = $('iframe').get()[0];
                let deferred = $q.defer();

                let tryOnFrameWindow = (<HTMLIFrameElement>tryOnFrame).contentWindow || tryOnFrame;
                tryOnFrameWindow.onload = function () {
                    tryOnFrameWindow['launch'](deferred.resolve);
                };
                let tryOnPromise = deferred.promise;

                $scope.$watch('ctx', function (ctx, oldCtx) {
                    if (!$scope.ctx.forceNextRefresh && contextEquals(ctx, oldCtx)) {
                        return;
                    }
                    $scope.ctx.forceNextRefresh = false;
                    let product = ctx.product;
                    if (!$scope.ctx.scenarios) {
                        $scope.ctx.scenarios = ScenarioResource.findAll({
                            filter: {
                                productIds: [product.id],
                                status: ['Production', 'Setup', 'Rejected', 'SetupRejected']
                            }
                        });
                    }


                    let additionBaseProduct;
                    let additionBaseProductPromise = fetchAdditionBaseProduct(product).then(a => additionBaseProduct = a);

                    $q.all([$scope.ctx.scenarios.$promise, tryOnPromise, additionBaseProductPromise]).then((results) => {
                        return tryOnFrameWindow['ShowCurrentGlasses'](toApiProduct($scope.ctx.product), toApiScenarios($scope.ctx.scenarios), additionBaseProduct);
                    }).catch(error => $log.error('TryOn failed: ', error));
                }, true);

            },
            template: tryOnBoothTemplate.default
        };
    });

interface Context {
    product?: any;
    scenarios?: any[];
}
