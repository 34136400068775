import * as angular from "angular";
import * as fileSaver from "file-saver";
import {mainModule} from "../../app";

/**
 * Transform an http response into a file
 */
function transformResponseAsFile($q) {
    /**
     * @httpPayload The http payload, containing data and headers:
     * - data response data
     * - headers response headers getter as provided by Angular
     * @param options {Object} to override headers and behaviors
     * - fileName {String} or {Promise} The file name
     * - fileExtension {String} The file extension
     * - mimeType {String} The file mime type
     */
    return function (httpPayload, options) {
        let inOpts = angular.copy(options);
        if (!(inOpts instanceof Object)) {
            inOpts = {};
        }
        if (inOpts.mimeType === undefined) {
            inOpts.mimeType = httpPayload.headers("Content-Type");
        }
        if (inOpts.fileName === undefined) {
            let fileNameRe = /filename *= *"?([^;"]+).*$/;
            let reMatch = fileNameRe.exec(httpPayload.headers("Content-Disposition"));
            if (reMatch !== null) {
                inOpts.fileName = reMatch[1];
            } else {
                inOpts.fileName = "undefined.dat";
            }
        }
        let file = new Blob([httpPayload.data], {type: inOpts.mimeType});
        let fileNamePromise = $q.when(inOpts.fileName);
        fileNamePromise.then(theFileName => {
            if (inOpts.fileExtension instanceof String) {
                theFileName += '.' + inOpts.fileExtension;
            }
            fileSaver.saveAs(file, theFileName);
        });
    };
}

transformResponseAsFile.$inject = ['$q'];

export default transformResponseAsFile;
mainModule.factory('transformResponseAsFile', transformResponseAsFile);
