import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('JobListCtrl', function ($scope, JobResource) {
        $scope.sortInfo = {fields: ['name'], columns: [], directions: ['asc']};
        $scope.findPage = function () {
            let query = arguments[0].query;
            query.entityGraphName = 'job.with.customer';
            return JobResource.findPage.apply(JobResource, arguments);
        };

        $scope.columns = [
            {field: 'name', labelCode: 'name', linkBaseHref: '#/admin/job'},
            {field: 'customer.name', labelCode: 'customer'},
            {
                field: 'openDate',
                labelCode: 'openDate',
                type: 'date',
                cellFilter: 'date:\'yyyy/MM/dd HH:mm:ss\''
            },
            {
                field: 'closeDate',
                labelCode: 'closeDate',
                type: 'date',
                cellFilter: 'date:\'yyyy/MM/dd HH:mm:ss\''
            },
            {field: 'fileId', labelCode: 'fileId'}
        ];
    });
