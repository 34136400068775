import {mainModule} from "../../app";

function ConfirmDialogCtrl($scope, $modalInstance, header, msg, okLabel, nokLabel, okLink) {
    //-- Variables -----//
    $scope.header = header;
    $scope.msg = msg;
    $scope.okLabel = okLabel;
    $scope.nokLabel = nokLabel;
    $scope.okLink = okLink;

    //-- Methods -----//

    $scope.no = function () {
        $modalInstance.dismiss(false);
    }; // end close

    $scope.yes = function () {
        $modalInstance.close(true);
        return true;
    }; // end yes
}


ConfirmDialogCtrl.$inject = ['$scope', '$modalInstance', 'header', 'msg', 'okLabel', 'nokLabel', 'okLink'];

export default ConfirmDialogCtrl;
mainModule.controller('ConfirmDialogCtrl', ConfirmDialogCtrl);
