import * as angular from 'angular';
import * as jobListTemplate from './job-list-template.html';

/**
 * Loads the job list and displays it.
 * Directive attributes:
 * - onlyToValidate: bool - If true retrieve only jobs waiting for validation
 */
angular.module('virtual-try-on-manager')
    .directive('jobList', function (JobResource, UserContextService) {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                onlyToValidate: '=',
                onSelect: '='
            },
            controller: function ($scope) {
                if (angular.isUndefined($scope.onlyToValidate)) {
                    $scope.onlyToValidate = false;
                }
                if (!angular.isFunction($scope.onSelect)) {
                    $scope.onSelect = angular.noop;
                }

                let jobQuery = {
                    deleted: undefined,
                    waitingForValidation: undefined,
                    entityGraphName: 'job.with.customer'
                };
                if ($scope.onlyToValidate) {
                    jobQuery.deleted = false;
                    jobQuery.waitingForValidation = true;
                }

                $scope.jobs = JobResource.findAll({query: jobQuery});

                UserContextService.getContext().then(userContext => {
                    $scope.isAdmin = userContext.hasRole('ADMIN');
                });
            },
            template: jobListTemplate.default
        };
    });
