import {IComponentOptions, IController} from 'angular';
import * as template from "./secondary-navbar.component.html";
import {mainModule} from "../app";


class Controller implements IController {
    appFilter;

    constructor(
        private readonly NavbarService
    ) {}

    getAppTitle(): string {
        return this.NavbarService.getAppTitle();
    };

    isAppFilterWatched(): boolean {
        return this.NavbarService.isAppFilterWatched();
    }

    getAppFilter() {
        return this.NavbarService.getAppFilter();
    };


    $onInit(): void {
        this.appFilter = function (newAppFilter) {
            return arguments.length ? (this.NavbarService.setAppFilter(newAppFilter)) : this.NavbarService.getAppFilter();
        };
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('secondaryNavbar', component);
