import * as angular from 'angular';
import {IComponentOptions, IController, IPromise} from 'angular';
import {UserContext, UserContextService} from "../user/user-context-service";
import * as template from "./product-addition-compatibility.component.html";
import {mainModule} from "../app";
import {ListProductAdditionCompatibilitiesRequest, ListProductsRequest, ProductsApi, ProductType, UserRole} from "vtom-api-typescript-client";
import {CosGridSort} from "../layout/cos-grid-sort";
import {ApiPageableHelper} from "../api/api-pageable-helper";
import * as productAdditionAddModalTemplate from "./product-addition-add-modal.html";


class Controller implements IController {
    // Input
    product;

    userContext: UserContext;

    filter: ListProductsRequest = {};
    columns = [
        {field: 'gtin', labelCode: 'gtin.code'},
        {field: 'model', labelCode: 'model'},
        {field: 'colorCode', labelCode: 'colorCode'}
    ];
    sortInfo: CosGridSort = {fields: ['gtin'], directions: ['desc']};

    constructor(
        private readonly UserContextService: UserContextService,
        private readonly ProductsApi: ProductsApi,
        private readonly DialogService,
        private readonly $translate
    ) {}

    findPage = (params) => {
        const query: ListProductsRequest = angular.extend({}, params?.query);
        ApiPageableHelper.addApiPageable(params.pageable, query);
        return this.ProductsApi.listProducts(query);
    };

    addCompatility = () => {
        this.DialogService.openModal({
            template: productAdditionAddModalTemplate.default,
            controller: 'ProductAdditionAddModalCtrl',
            resolve: {
                product: () => this.product
            }
        }).result.then(product => {
            this.filter.compatibleAdditionIds.push(product.id);
        });
    }

    delete = (selectedElements) => {
        const selectedProduct = selectedElements[0];
        const promise: IPromise<void> = this.DialogService.confirm(this.$translate('general.confirmDeletion')).result;
        promise.then(() => {
            return this.ProductsApi.listProductAdditionCompatibilities({
                additionProductIds: [this.product.id],
                baseProductIds: [selectedProduct.id]
            });
        }).then(page => {
            return this.ProductsApi.deleteProductAdditionCompatibility({id: page.content[0].id});
        }).then(() => {
            this.filter.compatibleAdditionIds.push(selectedProduct.id);
        });
    }

    isDisplayed(): boolean {
        return this.product?.id
            && this.product?.type == ProductType.GLASSESADDITION
            && this.userContext?.hasRole(UserRole.ADMIN);
    }

    $onChanges(onChangesObj: angular.IOnChangesObject) {
        if (!this.product?.id) {
            return;
        }
        if (onChangesObj.product?.previousValue?.id == this.product.id
            && onChangesObj.product?.previousValue?.type == this.product.type) {
            return;
        }
        this.filter.compatibleAdditionIds = [this.product.id];
        this.UserContextService.getContext().then(userContext => {
            this.userContext = userContext;
            if (!this.isDisplayed()) {
                return;
            }
        });
    }

}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default,
    bindings: {
        product: '<'
    }
};

mainModule.component('productAdditionCompatibility', component);
