import * as angular from "angular";
import {mainModule} from "../../app";

function ToastrConfigurator(toastrConfig) {
    angular.extend(toastrConfig, {
        timeOut: 1500,
        preventOpenDuplicates: true
    });
}

ToastrConfigurator.$inject = ['toastrConfig'];

export default ToastrConfigurator;
mainModule.config(ToastrConfigurator);
