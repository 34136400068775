import * as angular from "angular";
import {mainModule} from "../../app";
/**
 * Cosium Select directive
 * Simple wrapper around ui-select with default values.
 * Possible attributes :
 * - options : list holding options object (mandatory)
 * - ng-model : scoped variable that will hold the selected value(s)
 * - ng-label : property or expression used to generate the label. Use $item to represent an option element. (mandatory)
 * - ng-id :  property or expression used to generate the id. Use $item to represent an option element.
 * - ng-disabled : set this select as disabled
 * - on-remove : listener function on remove event
 * - on-select : listener function on select event
 * - multiple : if present this select will allow multiple values (tag mode)
 * - class : css classes
 * - id : regular html id
 * - placeholder : hint to describe the expected value of the input field
 * - refresh : async function to call to refresh data. See Async example https://github.com/angular-ui/ui-select/wiki/ui-select
 * - refresh-delay : delay before calling refresh function, set to 1000ms by default
 *
 * See https://github.com/angular-ui/ui-select for more details
 */
function cosSelectDirective($compile) {
    return {
        restrict: 'E',
        terminal: true,
        link: function link(scope, element) {

            var getAttribute = function (attributeName, defaultValue?) {
                if (element.attr(attributeName) !== null) {
                    return element.attr(attributeName);
                }
                return defaultValue;
            };

            // construct ui-select elements
            var uiSelect = angular.element('<ui-select/>');
            uiSelect.attr('ng-model', getAttribute('ng-model'));
            uiSelect.attr('ng-disabled', getAttribute('ng-disabled'));
            uiSelect.attr('on-remove', getAttribute('on-remove'));
            uiSelect.attr('on-select', getAttribute('on-select'));
            uiSelect.attr('multiple', getAttribute('multiple'));
            uiSelect.attr('class', getAttribute('class'));
            uiSelect.attr('id', getAttribute('id'));
            uiSelect.attr('theme', 'bootstrap');

            var labelKey = getAttribute('ng-label');

            var uiSelectMatch = angular.element('<ui-select-match/>');
            var placeholder = getAttribute('placeholder') || '{{\'general.pleaseselectelement\' | translate}}';
            uiSelectMatch.attr('placeholder', placeholder);
            var labelMatch = labelKey;
            if (!getAttribute('multiple')) {
                labelMatch = labelKey.replace(/\$item/g, '$select.selected');
            }
            uiSelectMatch.text('{{' + labelMatch + '}}');
            uiSelect.append(uiSelectMatch);

            var idKey = getAttribute('ng-id');
            var uiSelectChoices = angular.element('<ui-select-choices/>');
            var repeatAttr = '$item in ' + getAttribute('options');
            if (getAttribute('refresh')) {
                uiSelectChoices.attr('refresh', getAttribute('refresh'));
                if (getAttribute('refresh-delay')) {
                    uiSelectChoices.attr('refresh-delay', getAttribute('refresh-delay'));
                }
            } else {
                repeatAttr += ' | filter: $select.search' + (idKey ? ' track by $item.' + idKey : '');
            }
            uiSelectChoices.attr('repeat', repeatAttr);
            uiSelectChoices.append('<div ng-bind-html="' + labelKey + ' | escapeHtml | highlight: $select.search"></div>');
            uiSelect.append(uiSelectChoices);

            // compile the result
            var e = $compile(uiSelect)(scope);
            element.replaceWith(e);
        }
    };
}

cosSelectDirective.$inject = ['$compile'];

export default cosSelectDirective;
mainModule.directive('cosSelect', cosSelectDirective);
