import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .service('NavbarService', function ($q, $translate) {
        let defaultTitle = 'Virtual Try On Manager';

        let appTitle = defaultTitle;

        let appFilter = '';
        let appFilterWatchers = [];

        let self = this;
        /**
         * @return The current app title
         */
        this.getAppTitle = function () {
            return appTitle;
        };

        /**
         * Set the new app title
         * @param ownerScope - angular controller scope
         * @param title
         * @return {*}
         */
        this.setAppTitle = function (ownerScope, title) {
            return $q.when(title).then(function (newTitle) {
                appTitle = newTitle;
                ownerScope.$on('$destroy', function () {
                    if (appTitle === newTitle) {
                        appTitle = defaultTitle;
                    }
                });
            });
        };

        /**
         * @param title
         * @return {*} A handler to call to reset the title
         */
        this.setModernAppTitle = function(title){
            return $q.when(title).then(function (newTitle) {
                appTitle = newTitle;
                return function () {
                    if (appTitle === newTitle) {
                        appTitle = defaultTitle;
                    }
                };
            });
        };

        /**
         *
         * @param labelCodePrefix
         * @param objectScope
         * @param objectNameProperty
         */
        this.setAppTitleFromObjectName = function (labelCodePrefix, objectScope, objectNameProperty) {
            $translate(labelCodePrefix).then(function (title) {
                objectScope.$watch(objectNameProperty, function (name) {
                    let fullTitle: string;
                    if (name) {
                        fullTitle = title + ' <b>' + name + '</b>';
                    } else {
                        fullTitle = title;
                    }
                    self.setAppTitle(objectScope, fullTitle);
                });
            });
        };

        /**
         * @return {string} - The app filter
         */
        this.getAppFilter = function () {
            return appFilter;
        };

        /**
         * Set the app filter
         * @param filter
         */
        this.setAppFilter = function (filter) {
            appFilter = filter;
            appFilterWatchers.forEach(function (watcher) {
                watcher(appFilter);
            });
        };

        this.isAppFilterWatched = function () {
            return appFilterWatchers.length > 0;
        };

        /**
         *
         * @param watcherScope - angular controller scope
         * @param watcher - function - function to call on change with the new filter
         */
        this.watchAppFilter = function (watcherScope, watcher) {
            watcher(appFilter);
            appFilterWatchers.push(watcher);
            watcherScope.$on('$destroy', function () {
                appFilterWatchers.splice(appFilterWatchers.indexOf(watcher), 1);
                if(!self.isAppFilterWatched()){
                    appFilter = '';
                }
            });
        }

    });
