import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .service('UserService', function (UserContextService) {
        let self = this;

        let userContext;
        UserContextService.getContext().then(ctx => userContext = ctx);

        this.hasRole = function (user, role) {
            if (angular.isString(role)) {
                role = {value: role};
            }
            return user.roles.some(function (userRole) {
                return userRole.value.value === role.value;
            });
        };

        this.canToggleRole = function(user, role){
            if (!userContext) {
                return false;
            }
            if(role.value === 'SUPERADMIN'){
                return false;
            }

            if(!userContext.hasRole('ADMIN')){
                return false;
            }
            if(self.hasRole(user, 'SUPERADMIN')){
                return false;
            }
            if(self.hasRole(user, 'ADMIN')
                && !(userContext.hasRole('SUPERADMIN')
                    || userContext.user.id == user.id)){
                return false;
            }
            if (role.restrictToInternalUser && user.type.value !== 'INTERNAL') {
                return false;
            }
            return true;
        };

        this.canUpdateUser = function(user){
            if (!userContext) {
                return false;
            }
            if(!userContext.hasRole('ADMIN')){
                return false;
            }

            if(self.hasRole(user, 'SUPERADMIN') && userContext.user.id !== user.id){
                return false;
            }

            if(userContext.hasRole('SUPERADMIN')){
                return true;
            }

            if(self.hasRole(user, 'ADMIN') && userContext.user.id !== user.id){
                return false;
            }

            return true;
        };
    });
