/**
 * http
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as angular from 'angular';
import {Container} from '../container';
import {ApiResource} from '../resource';

/* tslint:disable:no-unused-variable member-ordering */

export class TryOnSessionStatResource {
    private readonly basePath = 'api';

    static $inject: string[] = ['$http', 'transformResponseAsFile', '$httpParamSerializer'];

    constructor(private $http: ng.IHttpService, private transformResponseAsFile:any, private $httpParamSerializer?: (d: any) => any) {

    }

    /**
     * 
     * @param payload 
     */
    public findPage (payload: any, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/tryOnSessionStatService/findPage';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling findPage.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'findPage'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
    /**
     * 
     * @param payload 
     */
    public findValidRange (payload: any, extraHttpRequestParams?: any ) : ApiResource<any> & any {
        const localVarPath = this.basePath + '/std/tryOnSessionStatService/findValidRange';

        let queryParameters: any = {};
        let headerParams: any = {};
        // verify required parameter 'payload' is not null or undefined
        if (payload === null || payload === undefined) {
            throw new Error('Required parameter payload was null or undefined when calling findValidRange.');
        }
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: localVarPath,
            data: payload,
                                    params: queryParameters,
            headers: headerParams,
            options: {
                operationName: 'findValidRange'
            }
        };

        if (extraHttpRequestParams) {
            httpRequestParams = (<any>Object).assign(httpRequestParams, extraHttpRequestParams);
        }

        let $promise = this.$http(httpRequestParams).then(config => config.data);
        let apiResult:any = {};
        apiResult.$promise = $promise;
        
        $promise.then(res => angular.forEach(res, (value, key) => apiResult[key] = value));
        return apiResult;
    }
}
