import * as angular from "angular";
import {IPromise} from "angular";

export class ProductImportResource {

    private readonly BASE_PATH = 'api/product/import';

    constructor(private readonly $http: ng.IHttpService) {}

    public importProducts(payload: any): IPromise<any> {
        let httpRequestParams: ng.IRequestConfig & {options?: any} = {
            method: 'POST',
            url: this.BASE_PATH,
            data: payload
        };

        return this.$http(httpRequestParams).then(config => config.data);
    }
}
angular.module('virtual-try-on-manager')
    .service('ProductImportResource', ProductImportResource);

