import {mainModule} from "../app";
import {IDeferred, ILocationService, IPromise, IQService} from "angular";

export class ManagedFileUploadControlFactory {
    static NAME = 'ManagedFileUploadControlFactory';

    constructor(private readonly DialogService,
                private readonly $translate,
                private readonly $location: ILocationService,
                private readonly $q: IQService) {}

    public build(): ManagedFileUploadControl {
        return new ManagedFileUploadControl(this.DialogService, this.$translate, this.$location, this.$q);
    }
}

/**
 * Wrapper for easier managed file upload through the flowjs upload directive.
 */
export class ManagedFileUploadControl {
    constructor(private readonly dialogService,
                private readonly $translate,
                private readonly $location: ILocationService,
                private readonly $q: IQService) {}

    public fileHandler: any = {files: []};
    private flow: any;
    private deferred: IDeferred<string>;

    public onFileAdd(file, evt, flow): void {
        this.flow = flow;
        this.deferred = null;
    };

    public onFileRemoved(file): void {
        this.flow = null;
        this.deferred = null;
    };

    public onUploadSuccess(file, message, flow): void {
        let uploadResponse = JSON.parse(message);
        this.deferred.resolve(uploadResponse.uuid);
        this.flow = null;
        this.deferred = null;
    };

    public onUploadError(file, message, flow): void {
        if (message && message.startsWith('Unauthorized')) {
            this.$location.path('/login');
            return;
        }
        this.dialogService.error(this.$translate('errorpage.erreurcontacteradmin'));
        this.deferred.reject(message);
    }

    public isReady(): boolean {
        return this.deferred || this.flow;
    }

    public uploadAndGetUuid(): IPromise<string> {
        if (!this.flow) {
            throw 'You can only launch the upload if a file has been selected.';
        }
        if (!this.deferred) {
            this.deferred = this.$q.defer();
            this.flow.upload();
        }
        return this.deferred.promise;
    }
}

mainModule.service(ManagedFileUploadControlFactory.NAME, ManagedFileUploadControlFactory);
