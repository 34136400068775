import * as angular from "angular";
import {mainModule} from "../../app";

function ResourceConfigurator($resourceProvider: angular.resource.IResourceServiceProvider, $provide) {
    $resourceProvider.defaults.stripTrailingSlashes = false;
}

ResourceConfigurator.$inject = ['$resourceProvider', '$provide'];

export default ResourceConfigurator;
mainModule.config(ResourceConfigurator);
