import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('AppCtrl', function ($scope, $route, $http, $location,
                                     NavbarService, TranslationService, tmhDynamicLocale) {
        tmhDynamicLocale.set(TranslationService.getLanguage());
        $scope.isDisplayMenu = function () {
            return $route.current && $route.current.navbar === true;
        };
    });
