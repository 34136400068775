import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('BoothSettingBoothCtrl', function ($scope, $routeParams, BoothSettingResource, BoothResource, UserContextService, NavbarService) {
         UserContextService.getContext().then(userContext => $scope.isAdmin = userContext.hasRole('ADMIN'));
        $scope.boothSetting = BoothSettingResource.get(parseInt($routeParams.id));

        NavbarService.setAppTitleFromObjectName('boothsetting.booths', $scope, 'boothSetting.name');

        $scope.columns = [
            {field: 'externalId.value', labelCode: 'externalId'},
            {field: 'storeName', labelCode: 'store.name'},
            {field: 'address', labelCode: 'address'},
            {field: 'businessRegistrationNumber', labelCode: 'store.businessRegistrationNumber'},
            {field: 'storePhoneNumber', labelCode: 'store.phonenumber'}
        ];

        $scope.findPage = function(){
            arguments[0].query.boothSetting = {id: $routeParams.id};

            return BoothResource
                .findPage
                .apply(BoothResource, arguments);
        };
        $scope.exportToCsv = function () {
            return BoothResource.toCsv.apply(BoothResource, arguments);
        };
    });

