import * as angular from "angular";
import FrameworkTranslationFilesLoader from "./framework-translation-files-loader.service";
import {mainModule} from "../../app";

function TranslationConfigurator(TranslationServiceProvider, datepickerPopupConfig, $provide) {
    TranslationServiceProvider.init();
    TranslationServiceProvider.addLoader(FrameworkTranslationFilesLoader.ANGULAR_NAME);

    datepickerPopupConfig.currentText = 'Aujourd\'hui';
    datepickerPopupConfig.clearText = 'Effacer';
    datepickerPopupConfig.toggleWeeksText = 'Semaines';
    datepickerPopupConfig.closeText = 'Fermer';

    $provide.decorator('datepickerDirective', ['$delegate', function ($delegate) {
        angular.forEach($delegate, function (directive) {
            let originalCompile = directive.compile;
            let originalLink = directive.link;
            if (originalCompile) {
                directive.compile = function () {
                    return function (scope) {
                        scope.$on('$localeChangeSuccess', function () {
                            scope.move(0);
                        });
                        originalLink.apply(this, arguments);
                    };
                };
            }
        });
        return $delegate;
    }]);
}

TranslationConfigurator.$inject = ['TranslationServiceProvider', 'datepickerPopupConfig', '$provide'];

mainModule.config(TranslationConfigurator);
