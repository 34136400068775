import {SimpleTranslationService} from "./simple-translation-service";
import {mainModule} from "../../app";

/**
 * Created by mapping an array of objects and translating each element using the SimpleTranslationService class
 * @param {SimpleTranslationService} translationService
 */
function mapTranslateFilter(translationService: SimpleTranslationService){
    /**@returns {(array: any[], sourceProperty: string, targetProperty: string) => any[]}**/
    return (array: any[], sourceProperty: string, targetProperty: string): any[] => {
        array.forEach((item) => {
            item[targetProperty] = translationService.instantTranslate(item[sourceProperty]);
        });
        return array;
    };
}

mapTranslateFilter.$inject = [SimpleTranslationService.ANGULAR_NAME];

export default mapTranslateFilter;
mainModule.filter('mapTranslate', mapTranslateFilter);
