import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('LoginCtrl', function ($scope, $http, $location,
                                       UserContextService, LocationChangeService, UserResource) {
        $scope.loginFailed = false;

        $scope.authenticate = function (credentials) {
            $http.post('api/spring/security_check', credentials).
                catch(
                function () {
                    $scope.loginFailed = true;
                })
                .then(function () {
                    UserContextService.clearCache();
                    let previousPath = LocationChangeService.getPreviousLocation().locationPath;
                    if(previousPath && previousPath.indexOf('/login') !== 0){
                        $location.path(previousPath);
                    } else{
                        $location.path('/welcome');
                    }
                });
        };
    });
