import * as angular from 'angular';
import {IComponentOptions, IController, IPromise} from 'angular';
import * as template from './shooting-release-list.component.html';
import {mainModule} from "../app";
import {CosGridSort} from "../layout/cos-grid-sort";
import {Platform, SoftwareRelease, SoftwareReleasePage, SoftwareReleasesApi, SoftwareType} from "vtom-api-typescript-client";
import {ApiPageableHelper} from "../api/api-pageable-helper";
import {ArtifactPlatformUtil} from "./artifact-platform";

class Controller implements IController {
    filter = {
        types: [SoftwareType.SHOOTING]
    };
    columns = [
        {field: 'version', labelCode: 'version', linkBaseHref: '#/admin/shooting-release'},
        {field: 'creationDate', labelCode: 'date', cellFilter: 'date:\'yyyy/MM/dd HH:mm:ss\''},
        {field: 'platforms', labelCode: 'platform', sortable: false}
    ];
    sortInfo: CosGridSort = {fields: ['version'], directions: ['desc']};
    filterSelect = [{
        name: 'platform',
        initialValue: '',
        values: [{value: '', labelCode: 'platform.all'}]
    }];

    constructor(
        private readonly $routeParams,
        private readonly SoftwareReleasesApi: SoftwareReleasesApi,
        private readonly $translate) {
        for (let platform in Platform) {
            this.filterSelect[0].values.push({value: Platform[platform], labelCode: ArtifactPlatformUtil.toLabelCode(<Platform>Platform[platform])});
        }
    }

    findPage = (params) => {
        const query = angular.extend({}, params.query);
        query.version = params.query.queryText;
        ApiPageableHelper.addApiPageable(params.pageable, query);
        return this.SoftwareReleasesApi.listSoftwareReleases(query).then(this.toSoftwareReleaseWithPlatforms);
    };

    toSoftwareReleaseWithPlatforms = (page: SoftwareReleasePage): SoftwareReleasePage => {
        for (const release of <SoftwareReleaseWithPlatforms[]> page.content) {
            for (const artifact of release.artifacts) {
                this.$translate(ArtifactPlatformUtil.toLabelCode(artifact.platform)).then(translatedPlatform => {
                    if (release.platforms) {
                        release.platforms += ', ';
                    } else {
                        release.platforms = '';
                    }
                    release.platforms += translatedPlatform;
                });
            }
        }
        return page;
    }

    $onInit(): void {}
}

interface SoftwareReleaseWithPlatforms extends SoftwareRelease {
    platforms: string;
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('shootingReleaseList', component);
