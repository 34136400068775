export enum RegisteredDomainType {
    PRODUCTION = 'PRODUCTION',
    PREPRODUCTION = 'PREPRODUCTION',
    STAGING = 'STAGING',
    TEST = 'TEST',
    DEMO = 'DEMO'
}

export class RegisteredDomainTypeUtil {
    static toLabelCode(value: RegisteredDomainType): string {
        return 'registered.domain.type.' + value.toLowerCase();
    }
}
