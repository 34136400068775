import {mainModule} from "../../app";

function AlertDialogCtrl($scope, $modalInstance, msg, btnLabel, btnClass) {
    //-- Variables -----//
    $scope.msg = msg;
    $scope.btnLabel = btnLabel;
    $scope.btnClass = btnClass || 'btn-primary';
    //-- Methods -----//

    $scope.close = function () {
        $modalInstance.close(true);
    };
}

AlertDialogCtrl.$inject = ['$scope', '$modalInstance', 'msg', 'btnLabel', 'btnClass'];

export default AlertDialogCtrl;
mainModule.controller('AlertDialogCtrl', AlertDialogCtrl);
