import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('UserListCtrl', function ($scope, $translate, UserService, UserResource, UserContextService, UserRoleResource, NavbarService) {
        NavbarService.setAppTitle($scope, $translate('users'));
        NavbarService.watchAppFilter($scope, function(appFilter){
           $scope.searchText = appFilter;
        });

        const init = async () => {
            const userContext = await UserContextService.getContext();
            $scope.currentUser = userContext.user;

            $scope.users = UserResource.query();
            UserRoleResource.getRoles({}).$promise
                .then(function (result) {
                    if (userContext.hasRole('SUPERADMIN')) {
                        $scope.roles = result
                    } else {
                        $scope.roles = [];
                        angular.forEach(result, function (item, index) {
                            if (item.value !== 'SUPERADMIN') {
                                $scope.roles.push(item);
                            }
                        });
                    }
                });
        }
        init();



        $scope.types = UserResource.getTypes({});

        $scope.canToggleRole = UserService.canToggleRole;
        $scope.hasRole = UserService.hasRole;

        $scope.toggleRole = function(user, role){
            let savedRoles = angular.copy(user.roles);
            let roleIndex = -1;
            user.roles.some(function (userRole, index) {
                let ok = userRole.value.value === role.value;
                if(ok){
                    roleIndex = index;
                }
                return ok;
            });

            if(roleIndex === -1){
                user.roles.push({
                    value: role
                });
            } else{
                user.roles.splice(roleIndex, 1);
            }
            UserRoleResource.saveAll({userRoles: user.roles, user: {id: user.id}}).$promise.then(function (result) {
                user.roles = result;
            }, function (result) {
                user.roles = savedRoles;
            });
        };
    });
