import {mainModule} from "../../app";

const AuthenticationConfig = {
    /**
     * Fired on authentication success
     */
    AUTHENTICATION_SUCCESS: 'Authentication.success',
    /**
     * Fired when a current valid authentication is destroyed
     */
    DEAUTHENTICATED: 'Authentication.deauthenticated',
    /**
     * The authentication url
     */
    AUTHENTICATION_URL: 'api/authentication/'
};

export default AuthenticationConfig
mainModule.constant('AuthenticationConfig', AuthenticationConfig);
