import {IComponentOptions, IController, IQService, IScope, IWindowService} from 'angular';
import {UserContextService} from "../user/user-context-service";
import {ProductResource} from "../../../http/target/classes/openapi/code/api/ProductResource";
import * as template from "./product.component.html";
import {mainModule} from "../app";
import {ProductAdditionType, ProductsApi, ProductType, ScenarioStatus, UserRole} from "vtom-api-typescript-client";
import {ProductAdditionTypeUtil, ProductTypeUtil} from "./product-enum-utils";


class Controller implements IController {
    readonly types = Object.values(ProductType);
    readonly toTypeLabelCode = ProductTypeUtil.toLabelCode;

    readonly additionTypes = Object.values(ProductAdditionType);
    readonly toAdditionTypeLabelCode = ProductAdditionTypeUtil.toLabelCode;

    isAdmin: boolean;
    isShooter: boolean;
    showTryOnBooth: boolean;
    ctx = {product: null, scenarios: [], forceNextRefresh: false};
    product;
    genders: any[];
    threeSixtyViewTranslationData;

    constructor(
        private readonly $scope: IScope,
        private readonly $routeParams,
        private readonly $translate,
        private readonly $q: IQService,
        private readonly $window: IWindowService,
        private readonly UserContextService: UserContextService,
        private readonly NavbarService,
        private readonly ProductResource: ProductResource,
        private readonly DialogService,
        private readonly ProductsApi: ProductsApi
    ) {}


    save(): void {
        this.ProductResource.save(this.product)
            .$promise
            .then((product) => {
                this.product = product;
            });
    };

    onScenariosChange(scenarios): void {
        this.showTryOnBooth = scenarios
            .some(scenario => scenario.status == ScenarioStatus.Setup
                || scenario.status == ScenarioStatus.Production
                || scenario.status == ScenarioStatus.Rejected);
        if (this.showTryOnBooth) {
            this.ctx.forceNextRefresh = true;
            this.ctx.product = this.product;
            this.ctx.scenarios = scenarios;
        }
    }

    delete(): void {
        this.DialogService.confirm(this.$translate('general.confirmDeletion')).result
            .then(() => this.ProductsApi.deleteProduct({productId: this.product.id}))
            .then(() => this.$window.history.back());
    };

    $onInit(): void {
        this.NavbarService.setAppTitleFromObjectName('product', this.$scope, 'product.model');

        const id = this.$routeParams.id;
        let productPromise;
        if (id === 'new') {
            productPromise = this.$q.when({});
        } else {
            productPromise = this.ProductResource.get(id).$promise;
        }

        productPromise.then(product => {
            this.product = product;
            return this.UserContextService.getContext();
        }).then(userContext => {
            this.isAdmin = userContext.hasRole(UserRole.ADMIN);
            this.isShooter = this.isAdmin ||  userContext.hasRole(UserRole.SHOOTER);

            if (this.isShooter) {
                this.genders = this.ProductResource.getAllGenders({});
                this.threeSixtyViewTranslationData = {
                    number: this.product.nbThreeSixtyView
                };

            } else {
                this.$translate(this.product.gender.labelCode)
                    .then((translated) => {
                        this.product.genderLabel = translated;
                    });
            }
        });

    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('product', component);
