import * as angular from 'angular';

angular.module('virtual-try-on-manager')
    .controller('UserCtrl', function($scope, $routeParams, $location, CustomerResource, NavbarService,
                                     UserResource, UserPasswordResource, UserService){
        $scope.customers = CustomerResource.findAll({query:{}});
        $scope.types = UserResource.getTypes({});
        $scope.view = {
            id: 'identity'
        };
        $scope.isNew = $routeParams.id === 'new';
        $scope.canUpdate = false;
        $scope.userPassword = {};

        if($scope.isNew){
            $scope.user = UserResource._default();
            $scope.canUpdate = true;
        } else{
            $scope.user = UserResource.get(parseInt($routeParams.id));
            $scope.user.$promise.then(function(user){
                 $scope.canUpdate = UserService.canUpdateUser(user);
            });
        }

        NavbarService.setAppTitleFromObjectName('user', $scope, 'user.login');

        $scope.saveIdentity = function(){
            UserResource.save($scope.user)
                .$promise.then(function(user){
                    if($scope.isNew){
                        $location.path('/admin/user/' + user.id);
                    } else{
                        $scope.user = user;
                    }
                });
        };

        $scope.saveAuthentication = function(){
            UserPasswordResource.save({user: {id: $routeParams.id}, value: $scope.userPassword.value})
                .$promise.then(function(){
                    $scope.userPassword = {};
                    $scope.authenticationForm.$setPristine();
                });
        };
    });
