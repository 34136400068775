import {IComponentOptions, IController} from 'angular';
import * as template from './customer-billing.html';
import {CustomerBillingResource} from "../../../http/target/classes/openapi/code/api/CustomerBillingResource";
import {mainModule} from "../app";

class Controller implements IController {

    private billingMetrics;
    private billingMetricsThisMonth;
    private selectedMonth: Date;

    constructor(
        private readonly $routeParams,
        private readonly CustomerBillingResource: CustomerBillingResource) {
    }

    $onInit(): void {
        this.billingMetrics = this.CustomerBillingResource.calculateBillingMetrics({
            customerId: this.$routeParams.id,
            from: new Date(2000, 1),
            to: new Date()
        });
        this.selectedMonth = new Date();
        this.selectedMonth.setDate(1);
        this.selectedMonth.setHours(0,0,0, 0);
        this.refreshMonthlyMetrics();
    }

    refreshMonthlyMetrics() {
        let endOfMonth = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth() + 1, 0);
        endOfMonth.setHours(23,59,59,999);
        this.billingMetricsThisMonth = this.CustomerBillingResource.calculateBillingMetrics({
            customerId: this.$routeParams.id,
            from: this.selectedMonth,
            to: endOfMonth
        });
    }

    nextMonth() {
        let newDate = new Date(this.selectedMonth);
        newDate.setMonth(this.selectedMonth.getMonth() + 1);
        this.selectedMonth = newDate;
        this.refreshMonthlyMetrics();
    }

    previousMonth() {
        let newDate = new Date(this.selectedMonth);
        newDate.setMonth(this.selectedMonth.getMonth() - 1);
        this.selectedMonth = newDate;
        this.refreshMonthlyMetrics();
    }
}

const component: IComponentOptions = {
    controller: Controller,
    template: template.default
};

mainModule.component('customerBilling', component);
