import * as angular from 'angular';
import {FilterFunction} from './filter.function';

angular.module('virtual-try-on-manager')
    .filter('interpolableMessage', function ($q, $filter, $translate, InterpolableMessageService) {
        let cached = {};
        let filterFn: FilterFunction = function (input) {

            if (input) {
                if (input in cached) {
                    return typeof cached[input] === 'string' ? cached[input] : undefined;
                } else {
                    InterpolableMessageService.translate(JSON.parse(input))
                        .then(function (translated) {
                            if (translated) {
                                cached[input] = translated;
                            }
                        });
                }
            }
        };
        filterFn.$stateful = true;
        return filterFn;
    });
